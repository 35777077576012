import { CloseRounded } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  IconButton,
  Modal,
  Paper,
  Typography,
  PaperProps as MuiPaperProps,
  useTheme,
  Grow,
  Backdrop,
  Button,
} from '@mui/material';
import { noop } from 'lodash-es';
import { ComponentProps, ReactNode, useCallback } from 'react';
import { isMobileDevice } from 'shared/utils/isMobileDevice';
import SuiButton from '../SuiButton';

export interface SuiModalProps
  extends Omit<ComponentProps<typeof Modal>, 'title'> {
  title: ReactNode | string;
  loading?: boolean;
  cancelText?: string;
  confirmText?: string;
  backText?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  onBack?: () => void;
  PaperProps?: Partial<MuiPaperProps>;
  confirmDisabled?: boolean;
  showCloseIcon?: boolean;
  withDivider?: boolean;
  desktopWidth?: number | string;
  desktopHeight?: number | string;
  modalActions?: ReactNode;
  hideFooter?: boolean;
}

export default function SuiModal({
  open,
  title,
  children,
  sx,
  onCancel,
  onClose,
  onBack,
  modalActions,
  desktopHeight = 'fit-content',
  desktopWidth,
  confirmText = 'Xác nhận',
  cancelText = 'ĐÓNG',
  backText = 'Quay lại',
  onConfirm = noop,
  loading = false,
  PaperProps = {},
  confirmDisabled = false,
  showCloseIcon = true,
  withDivider = false,
  hideFooter = false,
  ...modalProps
}: SuiModalProps) {
  const theme = useTheme();
  const { sx: paperSx, ...paperProps } = PaperProps;

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    } else {
      onClose?.({}, 'backdropClick');
    }
  }, [onCancel, onClose]);

  const isMobile = isMobileDevice();

  return (
    <Modal
      {...modalProps}
      open={open}
      onClose={onClose || onCancel}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 300,
        },
      }}
      className="Sui-Modal"
    >
      <Grow in={open} timeout={300}>
        <Paper
          sx={{
            py: 2.5,
            zIndex: 1000,
            outline: 'none',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: {
              xs: 0,
              md: theme.borders.borderRadius.lg,
            },
            width: '100%',
            maxWidth: {
              md: isMobile ? '100dvw' : desktopWidth || theme.spacing(75),
              sm: '100dvw',
            },
            maxHeight: {
              xs: '100dvh',
              md: isMobile ? '100dvh' : desktopHeight,
            },
            height: {
              xs: '100%',
              md: isMobile ? '100dvh' : 'fit-content',
            },
            ...paperSx,
          }}
          {...paperProps}
        >
          {onBack && (
            <Box
              px={2.5}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Button
                startIcon={<ArrowBackIcon color="info" />}
                sx={{ pl: 0 }}
                onClick={onBack}
              >
                {backText}
              </Button>
            </Box>
          )}
          <Box mb={2} px={2.5}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              sx={{ borderBottom: withDivider ? '1px dashed #BDBDBD' : 'none' }}
            >
              {typeof title === 'string' ? (
                <Typography
                  component="div"
                  fontWeight="bold"
                  variant="h4"
                  flex={2}
                >
                  {title}
                </Typography>
              ) : (
                title
              )}
              <Box
                flexGrow={2}
                display="flex"
                justifyContent="flex-end"
                gap={1}
              >
                {modalActions}
                {showCloseIcon && (
                  <IconButton onClick={handleCancel} edge="end" size="medium">
                    <CloseRounded />
                  </IconButton>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            py={0}
            px={2.5}
            sx={{ overflowY: 'auto', flex: 2, scrollPaddingRight: 'auto' }}
          >
            {children}
          </Box>
          {!hideFooter && (
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              mt={2}
              px={2.5}
            >
              <SuiButton
                disabled={loading}
                variant="outlined"
                buttonColor="secondary"
                sx={{ mr: 2 }}
                onClick={handleCancel}
              >
                {cancelText}
              </SuiButton>
              <SuiButton
                variant="gradient"
                buttonColor="info"
                onClick={onConfirm}
                loading={loading}
                disabled={loading || confirmDisabled}
              >
                {confirmText}
              </SuiButton>
            </Box>
          )}
        </Paper>
      </Grow>
    </Modal>
  );
}
