import { ApiResponseList } from 'interfaces/api-response.interface';
import { PageOptions } from 'interfaces/page-options.interface';
import mylaClient from 'shared/api/myla';
import { API_PATHS } from 'shared/constants';
import {
  OrderBooking,
  UpdateOrderBooking,
} from 'shared/models/orderBooking.model';
import { replaceParams } from 'utils/helpers';

export async function getOrderBooking(id: string, option: PageOptions) {
  const res = await mylaClient.get<OrderBooking>(
    `${replaceParams(API_PATHS.ORDER.GET, { id })}`,
    {
      params: option,
    }
  );
  return res.data;
}

export async function updateOrderBooking(order: UpdateOrderBooking) {
  const res = await mylaClient.patch<OrderBooking>(
    `${replaceParams(API_PATHS.ORDER.UPDATE_INFO, { id: order._id || '' })}`,
    order
  );
  return res.data;
}

export async function getListOrders(option: PageOptions) {
  const res = await mylaClient.get<ApiResponseList<OrderBooking>>(
    API_PATHS.ORDER.GET_LIST,
    {
      params: option,
    }
  );
  return res.data;
}
