import { Box, Stack, Typography } from '@mui/material';
import { AxiosError, AxiosResponse } from 'axios';
import FormikInput from 'components/Formik/FormikInput';
import SuiButton from 'components/SuiButton';
import SuiTypography from 'components/SuiTypography';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import mylaClient from 'shared/api/myla';
import { useMutation } from 'shared/packages/myla-query';
import * as yup from 'yup';

import curve from 'assets/images/curved-images/curved12.jpg';
import { useMemo } from 'react';
import { useCustomBreadcrumb } from 'shared/atoms/breadcrumbs';
import FormErrors from 'pages/schedules/sales/components/FormErrors';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { ApiError } from 'interfaces/api-response.interface';

const INITIAL_VALUES = {
  username: '',
  password: '',
  grant_type: 'password',
};

const formValidationSchema = yup.object().shape({
  username: yup.string().required('Tên người dùng không được để trống'),
  password: yup.string().required('Mật khẩu không được để trống'),
});

interface AuthenticationFormValues {
  username: string;
  password: string;
  grant_type: string;
}

function Authentication() {
  const navigate = useNavigate();
  const {
    mutate: loginTTLock,
    isLoading,
    error,
  } = useMutation<AxiosResponse, ApiError, AuthenticationFormValues>({
    mutationFn: (params) =>
      mylaClient.post('/user-devices/login-ttlock', params),
    onSuccess: () => navigate('/lock-management'),
    notify: {
      loading: 'Đang liên kết...',
      success: 'Liên kết thành công!',
      error:
        'Liên kết không thành công. Vui lòng thử lại sau hoặc liên hệ admin',
    },
  });

  useCustomBreadcrumb(
    useMemo(
      () => ({
        'lock-management': 'Quản lý nhóm bán hàng',
        authenticate: 'Liên kết tài khoản TTLock',
      }),
      []
    )
  );

  return (
    <Box
      sx={{
        gap: 4,
        minHeight: '80vh',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          height: { xs: '30vh', md: '30vh', borderRadius: 8 },
          width: '100%',
          backgroundImage: `url("${curve}")`,
          backgroundSize: 'cover',
        }}
      />
      <Formik<AuthenticationFormValues>
        initialValues={INITIAL_VALUES}
        onSubmit={(values) => loginTTLock(values)}
        validationSchema={formValidationSchema}
      >
        {({ handleSubmit }) => (
          <Stack
            spacing={2}
            sx={{
              mt: 2,
              width: '100%',
              gap: 1,
              maxWidth: {
                xs: '100%',
                lg: '50%',
                xl: '40%',
              },
            }}
          >
            <Typography variant="h4" sx={{ textAlign: 'center' }}>
              Liên kết tài khoản TTLock để
              quản&nbsp;lý&nbsp;khoá&nbsp;của&nbsp;bạn
            </Typography>
            <FormikInput
              placeholder="Tên người dùng"
              size="medium"
              name="username"
            />
            <FormikInput
              type="password"
              placeholder="Mật khẩu"
              size="medium"
              name="password"
            />
            <Grid2 xs={12}>
              <FormErrors title="Xác thực thất bại" error={error} />
            </Grid2>
            <SuiButton
              disabled={isLoading}
              onClick={() => handleSubmit()}
              variant="gradient"
              buttonColor="info"
              size="large"
              fullWidth
            >
              {isLoading ? 'Đang liên kết...' : 'Liên kết'}
            </SuiButton>
          </Stack>
        )}
      </Formik>
    </Box>
  );
}

export default Authentication;
