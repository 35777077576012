// Soft UI Dashboard PRO React base styles

import colors from 'shared/theme/base/colors';

import typography from 'shared/theme/base/typography';

// Soft UI Dashboard PRO React helper functions

import pxToRem from 'shared/theme/functions/pxToRem';

const { dark } = colors;
const { size, fontWeightBold } = typography;

export default {
  styleOverrides: {
    root: {
      minHeight: pxToRem(24),
      gap: pxToRem(16),
      marginLeft: 0,
      marginRight: 0,
    },

    label: {
      display: 'inline-block',
      fontSize: size.sm,
      fontWeight: fontWeightBold,
      color: dark.main,
      lineHeight: 1,
      transform: `translateY(${pxToRem(1)})`,

      '&.Mui-disabled': {
        color: dark.main,
      },
    },

    labelPlacementStart: {
      justifyContent: 'flex-end',
    },
  },
};
