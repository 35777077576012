import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemText,
  Chip,
} from '@mui/material';
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import { useState } from 'react';
import { BookingBill } from 'shared/models/booking.model';
import { OrderBooking } from 'shared/models/orderBooking.model';
import { currencyFormat } from 'utils/currencyFormat';

import { getEllipsisStyles } from 'shared/utils/styles';
import {
  BOOKING_STATUS_COLORS,
  BOOKING_STATUS_TEXTS,
} from '../sales/components/BookingForm/constants';

interface OrderHistoryProps {
  order: OrderBooking;
  bill: BookingBill | undefined;
  handleOpenOrderForm: (order: OrderBooking) => void;
}
function lineClamp(line = 1) {
  return {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: line,
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'pre-wrap',
    width: 'fit-content',
  };
}

function renderBillItem(bill: BookingBill | undefined) {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!bill) return <></>;

  return (
    <>
      {bill?.items?.map((item) => (
        <ListItem key={item._id}>
          <ListItemText>{item.name}</ListItemText>
          <ListItemText>{currencyFormat(item.value)}</ListItemText>
        </ListItem>
      ))}
      {bill?.serviceCharge?.map((item) => (
        <ListItem key={item._id}>
          <ListItemText>{item.name}</ListItemText>
          <ListItemText>{currencyFormat(item.value)}</ListItemText>
        </ListItem>
      ))}
      <ListItem>
        <ListItemText>Tổng tiền</ListItemText>
        <ListItemText>{currencyFormat(bill?.totalAmount)}</ListItemText>
      </ListItem>
    </>
  );
}

function OrderHistory({ bill, order, handleOpenOrderForm }: OrderHistoryProps) {
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <SuiBox width="100%">
      <SuiBox display="flex" gap="1rem" flexDirection="column">
        <Accordion
          key={order?._id}
          expanded={expanded === order._id}
          onChange={handleChange(order._id)}
          sx={{
            boxShadow: '4px 4px 16px 0px rgba(12, 74, 157, 0.08)',
            borderRadius: ' 0.5rem !important',
            border: '1px solid #f2f2f2',
            '&::before': {
              display: 'none',
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${order?._id}-content`}
            id={`${order?._id}-header`}
            sx={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: (theme) =>
                ` ${
                  expanded === order?._id
                    ? `1px dashed ${theme.palette.inputColors.borderColor.main}`
                    : 'none'
                }`,
              '> .MuiAccordionSummary-content': {
                columnGap: {
                  xs: '0.5rem',
                  md: '0.75rem',
                },
                margin: '1rem 0',
                '& p': {
                  position: 'relative',
                },
                alignItems: 'center',
              },

              '> .MuiAccordionSummary-content p:not(:last-of-type)': {
                paddingRight: {
                  xs: '0.5rem',
                  md: '0.75rem',
                },
              },
              '> .MuiAccordionSummary-content p:not(:last-of-type)::before': {
                content: '""',
                position: 'absolute',
                top: '50%',
                right: 0,
                width: '2px',
                backgroundColor: '#BDBDBD',
                height: '0.9375rem',
                transform: 'translateY(-50%)',
              },
              '.MuiAccordionSummary-expandIconWrapper': {
                color: '#323232',
                fontSize: '1.5rem',
                // transform: 'translateY(-50%)',
              },
            }}
          >
            <SuiTypography
              sx={{
                '&::before': {
                  fontWeight: 'bold',
                },
                minWidth: 'fit-content',
                ...lineClamp(1),
              }}
              fontWeight="bold"
              fontSize="1rem"
            >
              Phòng: {order?.childRoom?.name}
            </SuiTypography>
            <SuiTypography fontSize="1rem" sx={getEllipsisStyles(1)}>
              {order?.childRoom?.room?.name}
            </SuiTypography>
            <SuiBox marginLeft="auto" pr={1}>
              <Chip
                label={BOOKING_STATUS_TEXTS[order?.status]}
                sx={{
                  backgroundColor: BOOKING_STATUS_COLORS[order?.status],
                  color: '#fff',
                }}
                onDelete={() => {
                  handleOpenOrderForm(order);
                }}
                deleteIcon={
                  <EditIcon
                    fontSize="small"
                    sx={{
                      color: '#fff!important',
                    }}
                  />
                }
              />
            </SuiBox>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              marginTop: '0.5rem',
              px: {
                xs: 1,
                md: 2,
              },
            }}
          >
            <List
              dense
              sx={{
                width: '100%',
                'li:not(:last-child)': {
                  marginBottom: '1rem',
                },
                padding: '0 0.5rem',
                span: {
                  fontSize: {
                    xs: '0.875rem',
                    md: '1rem',
                  },
                  fontWeight: '500',
                },
                'li div:nth-of-type(2)': {
                  textAlign: 'right',
                },
                'li:last-child div:nth-of-type(2)': {
                  color: (theme) => theme.palette.success.main,
                },
              }}
            >
              {renderBillItem(bill)}
            </List>
          </AccordionDetails>
        </Accordion>
      </SuiBox>
    </SuiBox>
  );
}

export default OrderHistory;
