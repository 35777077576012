export enum UserRole {
  Admin = 'admin',
  Sale = 'sale',
  Distributor = 'distributor',
}

export interface User {
  _id: string;
  uid: string;
  email: string;
  name: string;
  photoUrl: string;
  role: UserRole;
  gender: string;
  phone: string;
  lastActivedAt: string;
  assignedDistributors: Array<{ _id: string; name: string }>;
}
