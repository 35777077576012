import {
  Divider,
  Drawer,
  DrawerProps,
  Icon,
  Link,
  List,
  Typography,
} from '@mui/material';
import MylaLogo from 'assets/images/logos/myla-logo.svg';
import clsx from 'clsx';
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import { useAuth } from 'context/auth';
import { useSoftUIController } from 'context/softUI';
import SidenavCard from 'examples/Sidenav/SidenavCard';
import SidenavCollapse from 'examples/Sidenav/SidenavCollapse';
import SidenavItem from 'examples/Sidenav/SidenavItem';
import SidenavList from 'examples/Sidenav/SidenavList';
import styles from 'examples/Sidenav/styles/sidenav';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { UserRole } from 'shared/models/user.model';
import { BaseSidebarItem, CollapseItem, SidebarItem } from './types';
import { isCollapsableSidebarItem } from './utils';

interface SidenavProps extends DrawerProps {
  routes: SidebarItem[];
}

const isAuthorized = (
  userRole: UserRole,
  authorizer: BaseSidebarItem['authorize']
) => {
  if (typeof authorizer === 'function') {
    return authorizer(userRole);
  }

  return !authorizer || authorizer.includes(userRole);
};

function Sidenav({ routes, ...rest }: SidenavProps) {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentSidenav } = controller;
  const classes = styles({ miniSidenav, transparentSidenav });
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split('/').slice(1)[0];
  const itemName = pathname.split('/').slice(1)[1] || collapseName;
  const [openCollapse, setOpenCollapse] = useState<string | undefined>(
    collapseName
  );
  const [openNestedCollapse, setOpenNestedCollapse] = useState<string>();
  const closeSizenav = () => dispatch({ type: 'MINI_SIDENAV', value: true });
  const { user } = useAuth();

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      dispatch({
        type: 'MINI_SIDENAV',
        value: window.innerWidth < 1200,
      });
    }

    /** 
    The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener('resize', handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = useCallback(
    (collapse: CollapseItem[]) =>
      collapse.map(({ name, route = '', key, href }) =>
        href ? (
          <Link
            key={key}
            href={href}
            target="_blank"
            rel="noreferrer"
            className={classes.sidenav_navlink}
          >
            <SidenavItem name={name} nested />
          </Link>
        ) : (
          <NavLink to={route} key={key} className={classes.sidenav_navlink}>
            <SidenavItem name={name} active={route === pathname} nested />
          </NavLink>
        )
      ),
    [classes.sidenav_navlink, pathname]
  );

  // Render the all the collpases from the routes.js
  const renderCollapse = useCallback(
    (collapses: CollapseItem[], parentActive = false) =>
      collapses.map((item) => {
        const { name, route = '', href, key, authorize } = item;
        if (!user || !isAuthorized(user.role, authorize)) {
          return null;
        }

        if (isCollapsableSidebarItem(item)) {
          return (
            <SidenavList key={key}>
              <SidenavItem
                key={key}
                name={name}
                active={parentActive && key === itemName}
                open={openNestedCollapse === key}
                onClick={() =>
                  openNestedCollapse === key
                    ? setOpenNestedCollapse(undefined)
                    : setOpenNestedCollapse(key)
                }
              >
                {renderNestedCollapse(item.collapse)}
              </SidenavItem>
            </SidenavList>
          );
        }

        return (
          <SidenavList key={key}>
            {href ? (
              <Link
                href={href}
                key={key}
                target="_blank"
                rel="noreferrer"
                className={classes.sidenav_navlink}
              >
                <SidenavItem
                  name={name}
                  active={parentActive && key === itemName}
                />
              </Link>
            ) : (
              <NavLink to={route} key={key} className={classes.sidenav_navlink}>
                <SidenavItem
                  name={name}
                  active={parentActive && key === itemName}
                />
              </NavLink>
            )}
          </SidenavList>
        );
      }),
    [
      classes.sidenav_navlink,
      itemName,
      openNestedCollapse,
      renderNestedCollapse,
      user,
    ]
  );

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderedRoutes = useMemo(
    () =>
      routes.map((item) => {
        const { key, icon, authorize } = item;
        if (!user || !isAuthorized(user.role, authorize)) {
          return null;
        }

        switch (item.type) {
          case 'collapse': {
            const { name, collapse, route } = item;
            return route ? (
              <Link
                href={route}
                key={key}
                target="_blank"
                rel="noreferrer"
                className={classes.sidenav_navlink}
              >
                <SidenavCollapse
                  name={name}
                  icon={icon}
                  active={key === collapseName}
                />
              </Link>
            ) : (
              <SidenavCollapse
                key={key}
                name={name}
                icon={icon}
                active={key === collapseName}
                open={openCollapse === key}
                onClick={() =>
                  openCollapse === key
                    ? setOpenCollapse(undefined)
                    : setOpenCollapse(key)
                }
              >
                {collapse
                  ? renderCollapse(collapse, key === collapseName)
                  : null}
              </SidenavCollapse>
            );
          }
          case 'title':
            return (
              <SuiTypography
                key={key}
                variant="caption"
                fontWeight="bold"
                textTransform="uppercase"
                customClass={classes.sidenav_title}
              >
                {item.title}
              </SuiTypography>
            );
          case 'divider':
            return <Divider key={key} />;
          default:
            return null;
        }
      }),
    [
      classes.sidenav_navlink,
      classes.sidenav_title,
      collapseName,
      openCollapse,
      renderCollapse,
      routes,
      user,
    ]
  );

  if (!user) {
    return null;
  }

  return (
    <Drawer
      {...rest}
      variant="permanent"
      classes={{
        paper: clsx(classes.sidenav, {
          [classes.sidenav_open]: !miniSidenav,
          [classes.sidenav_close]: miniSidenav,
        }),
      }}
    >
      <SuiBox customClass={classes.sidenav_header}>
        <SuiBox
          display={{ xs: 'block', xl: 'none' }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          customClass="cursor-pointer"
          onClick={closeSizenav}
        >
          <SuiTypography variant="h6" textColor="secondary">
            <Icon className=" font-bold">close</Icon>
          </SuiTypography>
        </SuiBox>
        <NavLink to="/">
          <SuiBox
            sx={{ mr: 1 }}
            component="img"
            src={MylaLogo}
            alt="Bookdee Logo"
            customClass={classes.sidenav_logo}
          />
          <SuiBox customClass={classes.sidenav_logoLabel}>
            <Typography
              variant="h2"
              sx={{ textTransform: 'uppercase !important', fontSize: 16 }}
            >
              Bookdee
            </Typography>
          </SuiBox>
        </NavLink>
      </SuiBox>
      <Divider />
      <List>{renderedRoutes}</List>
      <SuiBox customClass={classes.sidenav_footer}>
        <SidenavCard />
      </SuiBox>
    </Drawer>
  );
}

export default React.memo(Sidenav);
