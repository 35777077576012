import { getMessaging, getToken, isSupported } from '@firebase/messaging';
import { captureException } from '@sentry/react';
import { sendDeviceToken } from 'apis/deviceToken.api';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import {
  DocumentData,
  FirestoreDataConverter,
  getFirestore,
} from 'firebase/firestore';
import { Messaging } from 'firebase/messaging';
import { NOTIFICATION_PERMISSION_LOCAL_STORAGE_KEY } from 'shared/constants';
import Swal from 'sweetalert2';

const firebaseConfig = JSON.parse(import.meta.env.VITE_FIREBASE_CONFIG);
const app = initializeApp(firebaseConfig);

export const vapidKey = import.meta.env.VITE_VAPID_KEY;
export const auth = getAuth(app);
export const firestore = getFirestore(app);

export const converter = <T>(): FirestoreDataConverter<T> => ({
  toFirestore: (data): DocumentData => data as DocumentData,
  fromFirestore: (snapshot): T => snapshot.data() as T,
});

export const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(app);
    }
    console.error('FCM not supported this browser');
    return null;
  } catch (err) {
    console.error(err);
    return null;
  }
})();

const requestToken = (
  resolvedMessaging: Messaging,
  serviceWorkerRegistration: ServiceWorkerRegistration
) =>
  getToken(resolvedMessaging, { vapidKey, serviceWorkerRegistration })
    .then(async (currentToken) => {
      if (currentToken) {
        await sendDeviceToken(currentToken);
      }
    })
    .catch((err) => {
      console.error(
        'An error occurred when requesting to receive the token.',
        err
      );
      captureException(err);
    });

const requestPermission = async (
  resolvedMessaging: Messaging,
  serviceWorkerRegistration: ServiceWorkerRegistration
) => {
  if (Notification.permission === 'granted') {
    await requestToken(resolvedMessaging, serviceWorkerRegistration);
  } else {
    await Swal.fire({
      title: 'Bật thông báo',
      text: 'Hãy bật thông báo để nhận cập nhật về đơn phòng mới nhất từ Myla nhé!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Bật thông báo',
      cancelButtonText: 'Từ chối',
      reverseButtons: true,
      customClass: {
        cancelButton: 'button button-secondary',
        confirmButton: 'button button-success',
      },
      allowOutsideClick: false,
    }).then((result) => {
      localStorage.setItem(
        NOTIFICATION_PERMISSION_LOCAL_STORAGE_KEY,
        `${result.isConfirmed}`
      );
      if (result.isConfirmed) {
        Notification.requestPermission()
          .then((permission) => {
            if (permission === 'granted') {
              requestToken(resolvedMessaging, serviceWorkerRegistration);
            } else {
              console.error('User Permission Denied.');
            }
          })
          .catch((err) => {
            console.error('An error occurred when requesting permission.', err);
            captureException(err);
          });
      }
    });
  }
};

export const requestNotificationPermission = async (
  serviceWorkerRegistration: ServiceWorkerRegistration
) => {
  const isSupportedBrowser = await isSupported();
  if (!isSupportedBrowser) {
    return false;
  }

  const resolvedMessaging = await getMessaging(app);

  if (serviceWorkerRegistration.active) {
    await requestPermission(resolvedMessaging, serviceWorkerRegistration);
  } else if (serviceWorkerRegistration.installing) {
    serviceWorkerRegistration.installing.addEventListener(
      'statechange',
      async (event) => {
        const serviceWorker = event.target as ServiceWorker;
        if (serviceWorker?.state === 'activated') {
          await requestPermission(resolvedMessaging, serviceWorkerRegistration);
        }
      }
    );
  }

  return true;
};

export default app;
