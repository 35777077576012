export enum DeviceTypeEnum {
  LOCK = 'lock',
  SWITCH = 'switch',
  SENSOR = 'sensor',
  CAMERA = 'camera',
  LIGHT = 'light',
}

export enum DeviceStatusEnum {
  ONLINE = 'online',
  OFFLINE = 'offline',
  UNKNOWN = 'unknown',
}

export enum DeviceProviderEnum {
  TTLOCK = 'ttlock',
  TUYA = 'tuya',
}

export interface Device<T = Record<string, unknown>> {
  _id: string;
  deviceId: string;
  name: string;
  alias: string;
  type: DeviceTypeEnum;
  status: DeviceStatusEnum;
  provider: DeviceProviderEnum;
  metadata: T;
}
