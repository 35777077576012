import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import LockIcon from '@mui/icons-material/Lock';
import LockClockIcon from '@mui/icons-material/LockClock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {
  Card,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { compact } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import mylaClient from 'shared/api/myla';
import { Device } from 'shared/models/device.model';
import { useMutation } from 'shared/packages/myla-query';
import { ITTLockDevice, LockState } from 'shared/models';
import { QUERY_KEYS } from '../../../constants';
import LockHistories from './LockHistories';
import LockPassCodeList from './LockPassCodeList';
import TTLockDevice from './TTLockDevice';

function LockCard(device: Device<ITTLockDevice>) {
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openHistories, setOpenHistories] = useState(false);
  const [openPassCodeList, setOpenPassCodeList] = useState(false);
  const { lockId, state } = device.metadata;
  const { mutate: changeLockState, isLoading } = useMutation<
    AxiosResponse,
    AxiosError<{
      message: string;
      statusCode: number;
    }>,
    LockState
  >({
    mutationFn: (lockState) =>
      mylaClient.post(
        `/user-devices/lock/${
          lockState === LockState.UNLOCKED ? 'lock' : 'unlock'
        }`,
        { lockId }
      ),
    onSuccess: () =>
      setTimeout(() => {
        queryClient.invalidateQueries([QUERY_KEYS.LOCK_LIST]);
        queryClient.invalidateQueries([QUERY_KEYS.LOCK_HISTORIES]);
      }, 2000),
    notify: {
      loading: 'Đang thực hiện tác vụ...',
      success: 'Thực hiện tác vụ thành công!',
      error: ({ message, response }) => ({
        message:
          response?.data?.message ||
          message ||
          'Tác vụ không thành công. Vui lòng thử lại sau hoặc liên hệ admin',
      }),
    },
  });

  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const menuItems = useMemo(
    () =>
      compact([
        state === LockState.UNLOCKED && {
          icon: <LockIcon />,
          text: 'Đóng khoá',
          onClick: () => changeLockState(LockState.LOCKED),
        },
        state === LockState.LOCKED && {
          icon: <LockOpenIcon />,
          text: 'Mở khoá',
          onClick: () => changeLockState(LockState.LOCKED),
        },
        {
          icon: <KeyboardIcon />,
          text: 'Danh sách mật khẩu',
          onClick: () => setOpenPassCodeList(true),
        },
        {
          icon: <LockClockIcon />,
          text: 'Lịch sử mở khoá',
          onClick: () => setOpenHistories(true),
        },
      ]),
    [changeLockState, state]
  );

  return (
    <Card
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <TTLockDevice
        {...device}
        childrenRight={
          <Stack direction="row" alignItems="flex-start" gap={2}>
            {!!menuItems.length && (
              <>
                <IconButton
                  edge="end"
                  size="large"
                  disabled={isLoading}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                  <ExpandMoreIcon />
                </IconButton>
                <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={closeMenu}>
                  {menuItems.map(({ text, icon, onClick }) => (
                    <MenuItem
                      key={text}
                      onClick={() => {
                        onClick();
                        closeMenu();
                      }}
                    >
                      <ListItemIcon>{icon}</ListItemIcon>
                      <ListItemText>{text}</ListItemText>
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </Stack>
        }
      />
      {openHistories && (
        <LockHistories
          lockId={lockId}
          onClose={() => setOpenHistories(false)}
        />
      )}
      {openPassCodeList && (
        <LockPassCodeList
          lockId={lockId}
          onClose={() => setOpenPassCodeList(false)}
        />
      )}
    </Card>
  );
}

export default LockCard;
