import clsx from 'clsx';
import styles from 'components/SuiBox/styles';
import React, { forwardRef } from 'react';

import { Box, BoxProps } from '@mui/material';

export interface SuiBoxProps extends BoxProps, Record<string, unknown> {
  backgroundColor?: string;
  color?: string;
  backgroundGradient?: boolean;
  opacity?: number;
  borderRadius?: string;
  boxShadow?: string;
  customClass?: string;
}

const SuiBox = forwardRef<HTMLDivElement, SuiBoxProps>(
  (
    {
      backgroundColor = 'transparent',
      backgroundGradient = false,
      color = 'dark',
      opacity = 1,
      borderRadius = 'none',
      boxShadow = 'none',
      customClass = '',
      ...rest
    },
    ref
  ) => {
    const classes = styles({
      backgroundColor,
      color,
      opacity,
      borderRadius,
      boxShadow,
    });

    return (
      <Box
        ref={ref}
        className={clsx(classes.suiBox, customClass, {
          [classes.suiBox_backgroundGradient]: backgroundGradient,
        })}
        {...rest}
      />
    );
  }
);

export default SuiBox;
