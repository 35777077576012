import { forwardRef } from 'react';
import {
  NumericFormat,
  NumericFormatProps,
  numericFormatter,
} from 'react-number-format';

interface CustomProps {
  onChange: (event: {
    target: {
      name: string;
      value: {
        deposit: number;
        totalPrice: number;
      };
    };
  }) => void;
  name: string;
  value: {
    deposit: number;
    totalPrice: number;
  };
}

const NumericFormatInputWithTotal = forwardRef<NumericFormatProps, CustomProps>(
  (props, ref) => {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        value={props?.value?.deposit}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: {
                deposit: values.floatValue || 0,
                totalPrice: props?.value?.totalPrice,
              },
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        suffix={` / ${numericFormatter(props?.value?.totalPrice?.toString(), {
          thousandSeparator: true,
        })}`}
        displayType="input"
      />
    );
  }
);

export default NumericFormatInputWithTotal;
