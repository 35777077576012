// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Soft UI Dashboard PRO React contexts
import { useSoftUIController } from 'context/softUI';

// Custom styles for the SidenavItem
import styles from 'examples/Sidenav/styles/sidenavItem';
// prop-types is a library for typechecking of props.
import React from 'react';

import {
  Collapse,
  Icon,
  ListItem,
  ListItemText,
  ListItemProps,
} from '@mui/material';

interface SidenavItemProps extends ListItemProps {
  name: string;
  active?: boolean;
  nested?: boolean;
  open?: boolean;
}

function SidenavItem({
  name,
  children,
  active = false,
  nested = false,
  open = false,
  ...rest
}: SidenavItemProps) {
  const [controller] = useSoftUIController();
  const { miniSidenav } = controller;
  const classes = styles({ active, open, nested, miniSidenav, name });

  return (
    <>
      <ListItem {...rest} component="li" className={classes.item}>
        <SuiBox customClass={classes.item_content}>
          <ListItemText primary={name} />
          {children && (
            <Icon component="i" className={classes.item_arrow}>
              expand_less
            </Icon>
          )}
        </SuiBox>
      </ListItem>
      {children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

export default SidenavItem;
