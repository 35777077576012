import CreateIcon from '@mui/icons-material/Create';
import LockClockIcon from '@mui/icons-material/LockClock';
import PasswordIcon from '@mui/icons-material/Password';
import PinIcon from '@mui/icons-material/Pin';
import RemoveIcon from '@mui/icons-material/Remove';
import SyncIcon from '@mui/icons-material/Sync';
import {
  Box,
  Card,
  IconButton,
  Theme,
  Tooltip,
  Typography,
  keyframes,
  useTheme,
} from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getListPassCode } from 'apis/ttlock.api';
import { Loader } from 'components/LoadingIndicator';
import SuiBox from 'components/SuiBox';
import SuiModal, { SuiModalProps } from 'components/SuiModal';
import { format } from 'date-fns';
import { flatten } from 'lodash-es';
import {
  QUERY_KEYS,
  SERVER_RECORD_TYPE_PASSCODES,
} from 'pages/lockManagement/constants';
import { useMemo } from 'react';
import { Waypoint } from 'react-waypoint';
import { PaginatedResponse } from 'shared/api/myla';
import { PassCode } from 'shared/models';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CasinoIcon from '@mui/icons-material/Casino';

interface LockHistoriesProps
  extends Omit<SuiModalProps, 'open' | 'children' | 'title'> {
  lockId: number;
}

const getIconColors = ({ palette, functions }: Theme, isSuccess = false) => {
  const mainColor = isSuccess
    ? palette.gradients.success
    : palette.gradients.error;

  return functions.linearGradient(mainColor.main, mainColor.state);
};

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

function LockPassCodeList({ lockId, ...modalProps }: LockHistoriesProps) {
  const theme = useTheme();
  const {
    data,
    hasNextPage,
    isFetching,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery<PaginatedResponse<PassCode>>({
    queryKey: [QUERY_KEYS.LOCK_HISTORIES],
    queryFn: ({ pageParam = { pageNo: 1, pageSize: 10 } }) =>
      getListPassCode({ lockId, ...pageParam }),
    getNextPageParam: ({ meta }) =>
      meta.hasNextPage
        ? { pageNo: meta.page + 1, pageSize: meta.take }
        : undefined,
  });

  const listPassCode = useMemo(
    () => flatten(data?.pages.map((page) => page.data)),
    [data]
  );

  return (
    <SuiModal
      open
      hideFooter
      title="Danh sách mật khẩu"
      desktopHeight="80dvh"
      desktopWidth={theme.spacing(120)}
      modalActions={
        <IconButton
          onClick={() => {
            if (!isFetching) {
              refetch();
            }
          }}
        >
          <SyncIcon
            sx={{
              animation: isFetching ? `${spin} 1s infinite ease` : undefined,
            }}
          />
        </IconButton>
      }
      {...modalProps}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          pt: 2,
          pb: 4,
        }}
      >
        {listPassCode.map(
          ({
            keyboardPwdId,
            keyboardPwd,
            keyboardPwdName,
            keyboardPwdType,
            startDate,
            endDate,
            isCustom,
            nickName,
            sendDate,
          }) => {
            const Icon = PasswordIcon;

            return (
              <Card
                key={keyboardPwdId}
                sx={{ p: 3, alignItems: 'center', flexDirection: 'row' }}
              >
                <Box
                  flex={1}
                  rowGap={1}
                  display="flex"
                  flexDirection="column"
                  sx={{
                    color: 'text.secondary',
                  }}
                >
                  <Typography display="flex" alignItems="center">
                    <LockClockIcon fontSize="small" sx={{ mr: 1.5 }} />
                    <Typography
                      display="flex"
                      alignItems="center"
                      variant="subtitle2"
                      component="span"
                    >
                      {startDate
                        ? format(new Date(startDate), 'HH:ss dd/MM/yyyy')
                        : 'N/A'}
                      &nbsp; <RemoveIcon fontSize="small" /> &nbsp;
                      {endDate
                        ? format(new Date(endDate), 'HH:ss dd/MM/yyyy')
                        : 'N/A'}
                    </Typography>
                  </Typography>
                  <SuiBox display="flex" alignItems="center">
                    <CreateIcon fontSize="medium" sx={{ mr: 1.5 }} />
                    <Typography variant="subtitle2" component="span">
                      {keyboardPwdName}
                    </Typography>
                  </SuiBox>
                  <SuiBox display="flex" alignItems="center">
                    <PinIcon fontSize="medium" sx={{ mr: 1.5 }} />
                    {keyboardPwd && (
                      <Typography component="span">
                        <Tooltip title={keyboardPwd}>
                          <b>{`${keyboardPwd.slice(0, 3)}***`}</b>
                        </Tooltip>
                      </Typography>
                    )}
                  </SuiBox>
                  <SuiBox display="flex" alignItems="center">
                    <WorkHistoryIcon fontSize="medium" sx={{ mr: 1.5 }} />
                    <Typography variant="subtitle2" component="span">
                      {format(new Date(sendDate), 'HH:ss dd/MM/yyyy')}
                    </Typography>
                  </SuiBox>
                  <SuiBox display="flex" alignItems="center">
                    <ManageAccountsIcon fontSize="medium" sx={{ mr: 1.5 }} />
                    <Typography variant="subtitle2" component="span">
                      {nickName}
                    </Typography>
                  </SuiBox>
                  <SuiBox display="flex" alignItems="center">
                    <CasinoIcon fontSize="medium" sx={{ mr: 1.5 }} />
                    <Typography variant="subtitle2" component="span">
                      {isCustom ? 'Mật khẩu tự tạo' : 'Mật khẩu ngẫu nhiên'}
                    </Typography>
                  </SuiBox>
                  <SuiBox display="flex" alignItems="center">
                    <HourglassTopIcon fontSize="medium" sx={{ mr: 1.5 }} />
                    <Typography variant="subtitle2" component="span">
                      {SERVER_RECORD_TYPE_PASSCODES[keyboardPwdType]}
                    </Typography>
                  </SuiBox>
                </Box>
              </Card>
            );
          }
        )}
        {isFetching && <Loader />}
        {hasNextPage && (
          <Waypoint
            onEnter={() => {
              if (!isFetching && !isFetchingNextPage) {
                fetchNextPage();
              }
            }}
          />
        )}
      </Box>
    </SuiModal>
  );
}

export default LockPassCodeList;
