import { useGetBooking } from 'apis-query/booking.query';
import { useParams } from 'react-router-dom';
import BookingHistory from '../dashboard/BookingHistory';

function BookingDetail() {
  const { id } = useParams();
  const { data } = useGetBooking(id || '', {
    populate:
      'distributor,unit,customer,bills,createdBy,orders,orders.childRoom,orders.childRoom.room',
  });

  if (!data) return null;

  return <BookingHistory booking={data} />;
}

export default BookingDetail;
