import {
  Box,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { BOOKING_STATUS_COLORS, BOOKING_STATUS_TEXTS } from './constants';

interface StatusTagProps {
  status: string;
  noBorder?: boolean;
}

function divideIfMobile(value: number, isMobile: boolean) {
  return isMobile ? value / 2 : value;
}

export default function StatusTag({ status, noBorder }: StatusTagProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

  return (
    <Box
      sx={{
        borderRadius: '1.5rem',
        border: noBorder ? 'none' : '1px solid #BDBDBD',
        padding: noBorder
          ? `${divideIfMobile(0.5, isMobile)}rem 0`
          : `${divideIfMobile(0.5, isMobile)}rem ${divideIfMobile(
              0.75,
              isMobile
            )}rem`,
        gap: theme.spacing(1.5),
      }}
      display="flex"
      alignItems="center"
    >
      <SvgIcon
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <rect
          opacity="0.15"
          x="2"
          y="2"
          width="20"
          height="20"
          rx="10"
          fill={BOOKING_STATUS_COLORS[status]}
        />
        <rect
          x="7"
          y="7"
          width="10"
          height="10"
          rx="5"
          fill={BOOKING_STATUS_COLORS[status]}
        />
      </SvgIcon>
      <Typography
        fontSize={{
          xs: '0.75rem',
          sm: '1rem',
        }}
        color="text.status"
      >
        {BOOKING_STATUS_TEXTS[status]}
      </Typography>
    </Box>
  );
}
