import { Backdrop, BackdropProps, Box, useTheme } from '@mui/material';
import { HashLoader } from 'react-spinners';

export function Loader() {
  const theme = useTheme();
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
      <HashLoader color={theme.palette.myla.main} />
    </Box>
  );
}

function LoadingIndicator({
  transparent = false,
  sx,
  ...rest
}: { transparent?: boolean } & Partial<BackdropProps>) {
  return (
    <Backdrop
      open
      sx={{
        ...sx,
        zIndex: 2000,
        backgroundColor: transparent ? undefined : 'white !important',
      }}
      {...rest}
    >
      <Loader />
    </Backdrop>
  );
}

export default LoadingIndicator;
