import DeviceUnknownIcon from '@mui/icons-material/DeviceUnknown';
import HelpIcon from '@mui/icons-material/Help';
import LightModeIcon from '@mui/icons-material/LightMode';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PhotoCameraFrontIcon from '@mui/icons-material/PhotoCameraFront';
import { Box, Chip, Typography, useTheme } from '@mui/material';
import SuiBox from 'components/SuiBox';
import React from 'react';
import { ITTLockDevice, LockState } from 'shared/models';
import {
  Device,
  DeviceStatusEnum,
  DeviceTypeEnum,
} from 'shared/models/device.model';
import { getEllipsisStyles } from 'shared/utils/styles';

export const getStateProps = (state: LockState) => {
  switch (state) {
    case LockState.LOCKED:
      return {
        icon: <LockIcon />,
        text: 'Đã khoá',
        color: 'success',
      };
    case LockState.UNLOCKED:
      return {
        icon: <LockOpenIcon />,
        text: 'Đang mở khoá',
        color: 'warning',
      };
    default:
      return {
        icon: <HelpIcon />,
        text: 'Không rõ',
        color: 'error',
      };
  }
};

interface IDeviceProps extends Device<ITTLockDevice> {
  renderName?: () => React.ReactNode;
  renderStatus?: () => React.ReactNode;
  icon?: React.ReactNode;
  childrenCenter?: React.ReactNode;
  childrenRight?: React.ReactNode;
}

function renderIcon(type: DeviceTypeEnum) {
  switch (type) {
    case DeviceTypeEnum.LOCK:
      return <LockIcon htmlColor="#ffffff" />;
    case DeviceTypeEnum.CAMERA:
      return <PhotoCameraFrontIcon htmlColor="#ffffff" />;
    case DeviceTypeEnum.LIGHT:
      return <LightModeIcon htmlColor="#ffffff" />;
    default:
      return <DeviceUnknownIcon htmlColor="#ffffff" />;
  }
}

function renderStatusInternal(status: DeviceStatusEnum): string {
  switch (status) {
    case DeviceStatusEnum.ONLINE:
      return 'Bật';
    case DeviceStatusEnum.OFFLINE:
      return 'Tắt';
    default:
      return 'Không rõ';
  }
}
function renderStatusColor(type: DeviceTypeEnum, colors: any) {
  switch (type) {
    case DeviceTypeEnum.LOCK:
      return colors.gradients.myla.main;
    case DeviceTypeEnum.CAMERA:
      return colors.warning.secondary.main;
    case DeviceTypeEnum.LIGHT:
      return colors.success.light.main;
    default:
      return colors.text.primary.main;
  }
}
function TTLockDevice({
  name,
  status,
  type,
  icon,
  renderName,
  renderStatus,
  childrenRight,
  childrenCenter,
  metadata,
}: IDeviceProps) {
  const { electricQuantity, state, childRoom } = metadata || {};
  const theme = useTheme();
  const stateProps = getStateProps(state ?? LockState.UNKNOWN);
  const colors = theme.palette;
  return (
    <SuiBox width="100%">
      <SuiBox display="flex" justifyContent="space-between" width="100%">
        <SuiBox display="flex">
          <SuiBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding="1rem"
            fontSize="60px"
            borderRadius="0.5rem"
            width="84px"
            height="84px"
            alignSelf="flex-start"
            backgroundColor={renderStatusColor(type, colors)}
            sx={{
              position: 'relative',
              sm: {
                width: '50px',
                height: '50px',
                fontSize: '30px',
              },
            }}
          >
            {icon && <SuiBox component="span">{icon}</SuiBox>}
            {!icon && renderIcon(type)}
          </SuiBox>
          <SuiBox
            sx={{
              ml: 2,
              display: 'flex',
              flexDirection: 'column',
              rowGap: '0.25rem',
            }}
          >
            {name && (
              <SuiBox display="flex" alignItems="center" columnGap={1.5}>
                <Typography
                  variant="subtitle1"
                  fontWeight={400}
                  fontSize="0.95rem"
                >
                  Tên:
                </Typography>
                <Typography
                  m={0}
                  flex={2}
                  variant="h6"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  title={name}
                  fontWeight={400}
                  sx={{
                    ...getEllipsisStyles(1),
                  }}
                >
                  {name}
                </Typography>
              </SuiBox>
            )}
            {!name && renderName && renderName()}
            {status && (
              <SuiBox columnGap={1.5} display="flex" alignItems="center">
                <Typography
                  variant="subtitle1"
                  fontWeight={400}
                  fontSize="0.95rem"
                  sx={{
                    display: 'inline-block',
                  }}
                >
                  Trạng thái:
                </Typography>
                {renderStatusInternal(status)}
              </SuiBox>
            )}
            {!status && renderStatus && renderStatus()}
            <SuiBox display="flex" alignItems="center" columnGap="0.5rem">
              <Typography
                variant="subtitle1"
                fontWeight={400}
                fontSize="0.95rem"
              >
                Tình trạng:
              </Typography>
              <Chip
                icon={stateProps.icon}
                color={stateProps.color as any}
                variant="filled"
                size="small"
                label={stateProps.text}
              />
            </SuiBox>

            {childrenCenter && <SuiBox>{childrenCenter}</SuiBox>}
          </SuiBox>
        </SuiBox>
        {childrenRight}
      </SuiBox>

      <Box
        sx={{
          mt: 2,
          mb: 2,
          width: '100%',
          height: '1px',
          backgroundColor: '#e0e0e0',
        }}
      />

      <SuiBox display="flex" alignItems="center" columnGap="0.5rem">
        <Typography variant="subtitle1" fontWeight={400} fontSize="0.95rem">
          Phần trăm pin:
        </Typography>
        <Typography variant="subtitle2" component="span">
          {electricQuantity || 'N/A'}%
        </Typography>
      </SuiBox>
      <SuiBox display="flex" alignItems="center" columnGap="0.5rem">
        <Typography variant="subtitle1" fontWeight={400} fontSize="0.95rem">
          Child room:
        </Typography>
        <Typography variant="subtitle2" component="span">
          {childRoom?.name?.toString() || 'Còn trống'}
        </Typography>
      </SuiBox>
    </SuiBox>
  );
}

export default TTLockDevice;
