import { QueryKey, UseQueryOptions, useQuery } from '@tanstack/react-query';
import { NotifyConfigs, NotifyStatus } from '../types';
import { useNotifyToast } from './useNotifyToast';

export interface UseMylaQueryOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
> extends UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> {
  notify?: NotifyConfigs<TData, TError>;
}

const useMylaQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>({
  onSuccess,
  onError,
  queryFn,
  notify = {},
  ...options
}: UseMylaQueryOptions<TQueryFnData, TError, TData, TQueryKey>) => {
  const { showToast } = useNotifyToast();
  const { success, error, loading } = notify;

  return useQuery<TQueryFnData, TError, TData, TQueryKey>({
    ...options,
    queryFn: queryFn
      ? (...params) => {
          if (loading) {
            showToast({
              notifyStatus: NotifyStatus.LOADING,
              toastContent:
                typeof loading === 'string' ? { message: loading } : loading(),
            });
          }
          return queryFn(...params);
        }
      : undefined,
    onError: (errorResponse) => {
      onError?.(errorResponse);
      if (error) {
        showToast({
          notifyStatus: NotifyStatus.ERROR,
          toastContent:
            typeof error === 'string'
              ? { message: error }
              : error(errorResponse),
        });
      }
    },
    onSuccess: (data) => {
      onSuccess?.(data);
      if (success) {
        showToast({
          notifyStatus: NotifyStatus.SUCCESS,
          toastContent:
            typeof success === 'string' ? { message: success } : success(data),
        });
      }
    },
  });
};

export default useMylaQuery;
