import { ApiResponseList } from 'interfaces/api-response.interface';
import { PageOptions } from 'interfaces/page-options.interface';
import mylaClient from 'shared/api/myla';
import { API_PATHS } from 'shared/constants';
import { Customer } from 'shared/models/customer.model';
import { jsonToQueryString } from 'utils/helpers/jsonToQueryString';

export async function searchCustomer(pageOption: PageOptions) {
  const res = await mylaClient.get<ApiResponseList<Customer>>(
    `${API_PATHS.CUSTOMERS}/${jsonToQueryString(pageOption)}`
  );
  return res.data;
}

export async function getCustomer(customerId: string) {
  const res = await mylaClient.get<Customer>(
    `${API_PATHS.CUSTOMERS}/${customerId}`
  );
  return res.data;
}

export async function getCustomerByPhone(phone: string) {
  const res = await mylaClient.get<Customer>(
    `${API_PATHS.CUSTOMERS}/phone/${phone}`
  );
  return res.data;
}
