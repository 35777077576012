import DashboardLayout from 'components/Layout/DashboardLayout';
import LoadingIndicator from 'components/LoadingIndicator';
import ProtectedOutlet from 'components/ProtectedOutlet';
import AuthProvider from 'context/auth';
import BookingDetail from 'pages/schedules/bookingDetail';
import { Suspense, lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import lockManagementRoute from 'pages/lockManagement/routes';
import sidebarItems from './sidebar';

const CustomerDetail = lazy(() => import('pages/customers/Detail'));
// Soft UI Dashboard PRO React layouts
const Default = lazy(() => import('layouts/dashboards/default'));

const SignIn = lazy(() => import('layouts/authentication/sign-in/'));
const ForgotPassword = lazy(
  () => import('layouts/authentication/forgot-password')
);
const CustomersList = lazy(() => import('pages/customers/List'));
const Error404 = lazy(() => import('layouts/authentication/error/404'));
const Error500 = lazy(() => import('layouts/authentication/error/500'));

// Setting
const UtilityList = lazy(() => import('pages/utility/utility-list'));
const PolicyList = lazy(() => import('pages/policy/policyList'));
const SellingKeyList = lazy(() => import('pages/sellingKey/selling-list'));
const SellingGroups = lazy(() => import('pages/sellingGroups'));
const SellingGroupForm = lazy(
  () => import('pages/sellingGroups/SellingGroupForm')
);

// Distributor
const DistributorList = lazy(() => import('pages/distributors'));
const Distributor = lazy(
  () =>
    import('components/features/Distributor/DistributorForm/DistributorForm')
);
const DistributorDetail = lazy(
  () =>
    import(
      'components/features/Distributor/DistributorDetail/DistributorDetail'
    )
);
const UnitFormEdit = lazy(
  () =>
    import('components/features/Distributor/components/UnitForm/UnitFormEdit')
);
const UnitFormCreate = lazy(
  () =>
    import('components/features/Distributor/components/UnitForm/UnitFormCreate')
);
const UnitFormView = lazy(
  () =>
    import('components/features/Distributor/components/UnitForm/UnitFormView')
);
const UserList = lazy(() => import('pages/userManagement/List'));
const SaleSchedules = lazy(() => import('pages/schedules/sales'));
const CollaboratorSchedules = lazy(
  () => import('pages/schedules/collaborator')
);
const SaleDashboard = lazy(() => import('pages/schedules/dashboard'));
const SaleHistory = lazy(() => import('pages/schedules/sale-history'));
const AccountSettings = lazy(() => import('pages/account/settings'));

const routes: RouteObject[] = [
  {
    path: '*',
    element: (
      <Suspense fallback={<LoadingIndicator />}>
        <AuthProvider />
      </Suspense>
    ),
    children: [
      {
        path: 'sign-in',
        element: <SignIn />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />,
      },
      {
        path: 'error/404',
        element: <Error404 />,
      },
      {
        path: 'error/500',
        element: <Error500 />,
      },
      {
        path: 'collaborator',
        element: <CollaboratorSchedules />,
      },
      {
        path: '*',
        element: (
          <DashboardLayout sidebarItems={sidebarItems}>
            <ProtectedOutlet />
          </DashboardLayout>
        ),
        children: [
          {
            path: 'dashboards',
            children: [{ index: true, element: <Default /> }],
          },
          {
            path: 'account',
            children: [
              {
                path: 'settings',
                element: <AccountSettings />,
              },
            ],
          },
          {
            path: 'distributors',
            children: [
              {
                index: true,
                element: <DistributorList />,
              },
              {
                path: 'create',
                element: <Distributor />,
              },
              {
                path: ':distributorId',
                children: [
                  { index: true, element: <DistributorDetail /> },
                  { path: 'edit', element: <Distributor isEdit /> },
                  {
                    path: 'units',
                    children: [
                      { index: true, element: <DistributorDetail /> },
                      { path: 'create', element: <UnitFormCreate /> },
                      {
                        path: ':unitId',
                        children: [
                          { index: true, element: <UnitFormView /> },
                          { path: 'edit', element: <UnitFormEdit /> },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: 'settings',
            children: [
              {
                path: 'utilities',
                element: <UtilityList />,
              },
              {
                path: 'policies',
                element: <PolicyList />,
              },
              {
                path: 'selling-keys',
                element: <SellingKeyList />,
              },
              {
                path: 'groups',

                children: [
                  { index: true, element: <SellingGroups /> },
                  { path: 'create', element: <SellingGroupForm /> },
                  { path: 'edit', element: <SellingGroupForm /> },
                ],
              },
            ],
          },
          {
            path: 'customers',
            children: [
              {
                index: true,
                element: <CustomersList />,
              },
              {
                path: ':customerId',
                element: <CustomerDetail />,
              },
            ],
          },
          {
            path: 'users',
            children: [
              {
                index: true,
                element: <UserList />,
              },
            ],
          },
          {
            path: 'schedules',
            children: [
              {
                path: 'sale',
                element: <SaleSchedules />,
              },
              {
                path: 'sale-dashboard',
                element: <SaleDashboard />,
              },
              {
                path: 'sale-history',
                element: <SaleHistory />,
              },
              {
                path: ':id',
                element: <BookingDetail />,
              },
              {
                path: '*',
                element: <Navigate to="/schedules/sale" replace />,
              },
            ],
          },
          lockManagementRoute,
          {
            path: '*',
            element: <Navigate to="/dashboards" />,
          },
        ],
      },
    ],
  },
];

export default routes;
