// clsx is a utility for constructing className strings conditionally
import clsx from 'clsx';

// Custom styles for SuiTypography
import styles from 'components/SuiTypography/styles';

// prop-types is a library for typechecking of props
import React, { Ref, forwardRef } from 'react';

import { Typography, TypographyProps } from '@mui/material';

export interface SuiTypographyProps
  extends TypographyProps,
    Record<string, unknown> {
  textColor?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error'
    | 'light'
    | 'dark'
    | 'text'
    | 'white';
  fontWeight?: 'light' | 'regular' | 'medium' | 'bold';
  textTransform?: 'none' | 'capitalize' | 'uppercase' | 'lowercase';
  verticalAlign?:
    | 'unset'
    | 'baseline'
    | 'sub'
    | 'super'
    | 'text-top'
    | 'text-bottom'
    | 'middle'
    | 'top'
    | 'bottom';
  textGradient?: boolean;
  children: React.ReactNode;
  opacity?: number;
  customClass?: string;
}

function SuiTypography(
  {
    textColor = 'dark',
    fontWeight = 'regular',
    textTransform = 'none',
    verticalAlign = 'unset',
    textGradient = false,
    opacity = 1,
    customClass = '',
    children,
    ...rest
  }: SuiTypographyProps,
  ref: Ref<HTMLParagraphElement>
) {
  const classes = styles({
    textColor,
    textTransform,
    verticalAlign,
    opacity,
  });

  return (
    <Typography
      {...rest}
      ref={ref}
      className={clsx(classes.suiTypography, customClass, {
        [classes[`suiTypography_${fontWeight}`]]: fontWeight,
        [classes.suiTypography_textTransform]: textTransform,
        [classes.suiTypography_verticalAlign]: verticalAlign,
        [classes.suiTypography_textGradient]: textGradient,
      })}
    >
      {children}
    </Typography>
  );
}

export default forwardRef<HTMLParagraphElement, SuiTypographyProps>(
  SuiTypography
);
