import { Box, Typography, useTheme } from '@mui/material';
import SuiButton from 'components/SuiButton';
import Lottie from 'lottie-react';
import { ReactNode } from 'react';
import astronaout from 'shared/lotties/astronaout.json';
import document from 'shared/lotties/document.json';

interface EmptyStateProps {
  title?: ReactNode;
  subTitle?: ReactNode;
  cta?: {
    text: string;
    onClick: () => void;
  };
  variant?: 'astronaout' | 'document';
}

const ANIMATIONS = {
  document,
  astronaout,
} as const;

export default function EmptyState({
  title,
  subTitle,
  cta,
  variant = 'astronaout',
}: EmptyStateProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 1,
        mb: 5,
      }}
    >
      <Box
        component={Lottie}
        animationData={ANIMATIONS[variant]}
        sx={{ width: theme.spacing(40), height: theme.spacing(40) }}
      />
      {title && (
        <Typography variant="h4" textAlign="center">
          {title}
        </Typography>
      )}
      {subTitle && (
        <Typography variant="body1" mb={2}>
          {subTitle}
        </Typography>
      )}
      {cta && (
        <SuiButton variant="gradient" buttonColor="dark" onClick={cta.onClick}>
          {cta.text}
        </SuiButton>
      )}
    </Box>
  );
}
