import { Alert, AlertProps, AlertTitle, Typography } from '@mui/material';
import { ApiError } from 'interfaces/api-response.interface';

interface FormErrorsProps extends AlertProps {
  title: string;
  error: ApiError | null | undefined;
}

export default function FormErrors({ title, error, ...rest }: FormErrorsProps) {
  const errorFromAPI = error?.response?.data?.message;
  const errorMessages =
    errorFromAPI && typeof errorFromAPI === 'string'
      ? [errorFromAPI]
      : errorFromAPI;

  const renderError = (message: unknown): any => {
    if (typeof message === 'string') return message;
    if (typeof message === 'object' && message !== null) {
      return (message as any)?.message || '';
    }
    return '';
  };

  return error ? (
    <Alert {...rest} severity="error">
      <AlertTitle sx={{ fontSize: 16, mb: errorMessages?.length ? 0.5 : 0 }}>
        {title}
      </AlertTitle>
      <Typography component="ul" sx={{ listStyle: 'inside', fontSize: 16 }}>
        {errorMessages?.map?.((message, index) => (
          <li key={index}>{renderError(message)}</li>
        ))}
      </Typography>
    </Alert>
  ) : null;
}
