import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import PasswordIcon from '@mui/icons-material/Password';
import {
  Box,
  Card,
  IconButton,
  Theme,
  Tooltip,
  Typography,
  keyframes,
  useTheme,
} from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import SuiModal, { SuiModalProps } from 'components/SuiModal';
import { format, isValid } from 'date-fns';
import { flatten } from 'lodash-es';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {
  QUERY_KEYS,
  SERVER_RECORD_TYPE_ICONS,
  SERVER_RECORD_TYPE_TEXTS,
} from 'pages/lockManagement/constants';
import queryString from 'query-string';
import { useMemo } from 'react';
import mylaClient, { PaginatedResponse } from 'shared/api/myla';
import { LockHistory } from 'shared/models';
import { Loader } from 'components/LoadingIndicator';
import { Waypoint } from 'react-waypoint';
import SuiButton from 'components/SuiButton';
import SyncIcon from '@mui/icons-material/Sync';

interface LockHistoriesProps
  extends Omit<SuiModalProps, 'open' | 'children' | 'title'> {
  lockId: number;
}

const getIconColors = ({ palette, functions }: Theme, isSuccess = false) => {
  const mainColor = isSuccess
    ? palette.gradients.success
    : palette.gradients.error;

  return functions.linearGradient(mainColor.main, mainColor.state);
};

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

function LockHistories({ lockId, ...modalProps }: LockHistoriesProps) {
  const theme = useTheme();
  const {
    data,
    hasNextPage,
    isFetching,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery<PaginatedResponse<LockHistory>>({
    queryKey: [QUERY_KEYS.LOCK_HISTORIES],
    queryFn: ({ pageParam = { pageNo: 1, pageSize: 10 } }) =>
      mylaClient
        .get(
          queryString.stringifyUrl({
            url: '/user-devices/lockRecord/list',
            query: { lockId, ...pageParam },
          })
        )
        .then((res) => res.data),
    getNextPageParam: ({ meta }) =>
      meta.hasNextPage
        ? { pageNo: meta.page + 1, pageSize: meta.take }
        : undefined,
  });

  const histories = useMemo(
    () => flatten(data?.pages.map((page) => page.data)),
    [data]
  );

  return (
    <SuiModal
      open
      hideFooter
      title="Lịch sử mở khoá"
      desktopHeight="80dvh"
      desktopWidth={theme.spacing(120)}
      modalActions={
        <IconButton
          onClick={() => {
            if (!isFetching) {
              refetch();
            }
          }}
        >
          <SyncIcon
            sx={{
              animation: isFetching ? `${spin} 1s infinite ease` : undefined,
            }}
          />
        </IconButton>
      }
      {...modalProps}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          pt: 2,
          pb: 4,
        }}
      >
        {histories.map(
          ({
            success,
            username,
            lockDate,
            recordId,
            recordType,
            keyboardPwd,
          }) => {
            const Icon = SERVER_RECORD_TYPE_ICONS[recordType] || PasswordIcon;

            return (
              <Card
                key={recordId}
                sx={{ p: 3, alignItems: 'center', flexDirection: 'row' }}
              >
                <Box flex={1}>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    display="flex"
                    alignItems="center"
                  >
                    <CalendarMonthIcon sx={{ mr: 0.5 }} />
                    {isValid(new Date(lockDate)) ? (
                      <>
                        {format(new Date(lockDate), 'dd/MM/yyyy')}
                        <AccessTimeFilledIcon sx={{ mx: 0.5 }} />
                        {format(new Date(lockDate), 'HH:ss')}
                      </>
                    ) : (
                      'N/A'
                    )}
                  </Typography>
                  <Typography>
                    <Typography variant="h6" component="span">
                      {SERVER_RECORD_TYPE_TEXTS[recordType]}
                    </Typography>
                    {keyboardPwd && (
                      <Typography component="span">
                        &nbsp;
                        <Tooltip title={keyboardPwd}>
                          <b>{`${keyboardPwd.slice(0, 3)}***`}</b>
                        </Tooltip>
                      </Typography>
                    )}
                  </Typography>
                  <Typography>{username}</Typography>
                </Box>
                <Box
                  sx={{
                    p: 1.5,
                    background: getIconColors(theme, Boolean(success)),
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: success
                      ? theme.palette.success.main
                      : theme.palette.error.main,
                  }}
                >
                  <Icon fontSize="medium" htmlColor="white" />
                </Box>
              </Card>
            );
          }
        )}
        {isFetching && <Loader />}
        {hasNextPage && (
          <Waypoint
            onEnter={() => {
              if (!isFetching && !isFetchingNextPage) {
                fetchNextPage();
              }
            }}
          />
        )}
      </Box>
    </SuiModal>
  );
}

export default LockHistories;
