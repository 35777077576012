import mylaClient, { PaginatedResponse } from 'shared/api/myla';
import { API_PATHS } from 'shared/constants';
import { ITTLockDevice, PassCode } from 'shared/models';
import { Device } from 'shared/models/device.model';

export async function getListPassCode(params: Record<string, any>) {
  const res = await mylaClient.get<PaginatedResponse<PassCode>>(
    `${API_PATHS.TTLOCK.LIST_PASSCODE}`,
    {
      params,
    }
  );
  return res.data;
}

export async function getListDeviceAvailable(params?: Record<string, any>) {
  const res = await mylaClient.get<Device<ITTLockDevice>[]>(
    `${API_PATHS.TTLOCK.LIST_DEVICE_AVAILABLE}`,
    {
      params,
    }
  );
  return res.data;
}

export async function getListDevice(params?: Record<string, any>) {
  const res = await mylaClient.get<PaginatedResponse<Device<ITTLockDevice>>>(
    `${API_PATHS.TTLOCK.LIST_DEVICE}`,
    {
      params,
    }
  );
  return res.data;
}

export async function deletePassCode(params: {
  keyboardPwdId: string;
  lockId: string;
}) {
  const res = await mylaClient.post<{
    message: string;
    statusCode: number;
  }>(`${API_PATHS.TTLOCK.DELETE_PASSCODE}`, params);
  return res.data;
}
