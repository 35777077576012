import { PageOptions } from 'interfaces/page-options.interface';
import mylaClient from 'shared/api/myla';
import { API_PATHS } from 'shared/constants';
import {
  ExportDataParams,
  OrderBill,
  UpdateOrderBill,
} from 'shared/models/orderBill.model';
import { replaceParams } from 'utils/helpers';

export async function getOrderBill(orderId: string, option?: PageOptions) {
  const res = await mylaClient.get<OrderBill>(
    `${replaceParams(API_PATHS.BILL.GET_BY_ORDER_ID, {
      orderId,
    })}`,
    {
      params: option,
    }
  );
  return res.data;
}

export async function updateOrderBillInfo(order: UpdateOrderBill) {
  const res = await mylaClient.patch<OrderBill>(
    `${replaceParams(API_PATHS.BILL.UPDATE_INFO, { id: order._id || '' })}`,
    order
  );
  return res.data;
}

export async function getBillByBooking(
  bookingId: string,
  option?: PageOptions
) {
  const res = await mylaClient.get<OrderBill[]>(
    `${replaceParams(API_PATHS.BILL.BOOKING, {
      bookingId,
    })}`,
    {
      params: option,
    }
  );
  return res.data;
}

export async function downloadBill(params: ExportDataParams) {
  const res = await mylaClient.get(API_PATHS.BILL.DOWNLOAD, {
    responseType: 'blob',
    params,
  });
  return res.data;
}
