import { QueryKey } from '@tanstack/query-core';
import { QueryObserverResult, useQuery } from '@tanstack/react-query';
import { UseQueryResult } from '@tanstack/react-query/src/types';
import { useCallback, useRef } from 'react';
import { UseMylaQueryOptions } from 'shared/packages/myla-query/hooks/useQuery';

export const useLazyQuery = <
  TQueryFnData = unknown,
  TLazyParams = void,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>({
  fetcher,
  ...options
}: Omit<
  UseMylaQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  'initialData'
> & {
  initialData?: () => undefined;
  fetcher: (params: TLazyParams | null) => Promise<TQueryFnData>;
}): UseQueryResult<TData, TError> & {
  lazyQuery: (
    params: TLazyParams
  ) => Promise<QueryObserverResult<TData, TError>>;
} => {
  const paramsRef = useRef<TLazyParams | null>(null);
  const queryResult = useQuery<TQueryFnData, TError, TData, TQueryKey>({
    ...options,
    enabled: false,
    queryFn: () => fetcher(paramsRef.current),
  });

  const lazyQuery = useCallback(
    async (queryParams: TLazyParams) => {
      paramsRef.current = queryParams;
      return queryResult.refetch();
    },
    [queryResult]
  );

  return { lazyQuery, ...queryResult };
};
