import ArchiveIcon from '@mui/icons-material/Archive';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  Tooltip,
} from '@mui/material';
import mylaClient from 'shared/api/myla';
import { API_PATHS } from 'shared/constants';
import useMylaMutation from 'shared/packages/myla-query/hooks/useMutation';

interface NotificationsToolbarProps {
  toggleAll: VoidFunction;
  resetSelectedNotification: VoidFunction;
  isAllSelected: boolean;
  selectedNotification: string[];
}

export default function NotificationsToolbar({
  toggleAll,
  isAllSelected,
  selectedNotification,
  resetSelectedNotification,
}: NotificationsToolbarProps) {
  const { isLoading, mutate: archiveNotification } = useMylaMutation<
    void,
    void,
    string[]
  >({
    mutationFn: (notificationIdsToArchive) =>
      mylaClient.patch(API_PATHS.NOTIFICATION.ARCHIVE, {
        ids: notificationIdsToArchive,
      }),
    onSuccess: resetSelectedNotification,
    notify: {
      success: 'Lưu trữ mục đã chọn thành công',
      error: 'Lưu trữ mục đã chọn thất bại vui lòng thử lại sau',
      loading: 'Đang lưu trữ mục đã chọn',
    },
  });

  return (
    <Collapse
      orientation="vertical"
      in={!!selectedNotification.length}
      sx={{
        bgcolor: (theme) => theme.palette.background.default,
      }}
    >
      <Box
        sx={{
          py: 0.5,
          px: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Button
          variant="text"
          onClick={toggleAll}
          color={isAllSelected ? 'secondary' : 'success'}
          startIcon={<DoneAllIcon />}
        >
          {isAllSelected ? 'Bỏ chọn tất cả' : 'Chọn tất cả'}
        </Button>
        <Tooltip title="Lưu trữ mục đã chọn">
          <IconButton
            size="small"
            onClick={() => archiveNotification(selectedNotification)}
          >
            {isLoading ? <CircularProgress size="small" /> : <ArchiveIcon />}
          </IconButton>
        </Tooltip>
      </Box>
    </Collapse>
  );
}
