import { Loader } from 'components/LoadingIndicator';
import { useAuth } from 'context/auth';
import { Navigate, Outlet } from 'react-router-dom';

function ProtectedOutlet() {
  const { user } = useAuth();

  if (user === undefined) {
    return <Loader />;
  }

  if (!user) {
    return <Navigate to="/sign-in" />;
  }

  return <Outlet />;
}

export default ProtectedOutlet;
