import React from 'react';
// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// Custom styles for the SidenavList
import styles from 'examples/Sidenav/styles/sidenavList';

import { List } from '@mui/material';

function SidenavList({ children }) {
  const classes = styles();

  return <List className={classes.list}>{children}</List>;
}

// Typechecking props for the SidenavItem
SidenavList.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SidenavList;
