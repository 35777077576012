import mylaClient from 'shared/api/myla';
import { API_PATHS } from 'shared/constants';

export async function updateStatusSeen(notifyListId: string[]) {
  const res = await mylaClient.patch<string>(
    `${API_PATHS.NOTIFICATION.UPDATE_SEEN}`,
    {
      ids: notifyListId,
    }
  );
  return res.data;
}
