import { captureException } from '@sentry/react';
import LoadingIndicator from 'components/LoadingIndicator';
import React, { useCallback, useState } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './ErrorFallback';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

const DYNAMIC_CHUNKS_ERROR_MESSAGE = [
  'Failed to fetch dynamically imported module',
  'Load failed',
  "'text/html' is not a valid JavaScript MIME type",
];

function DefaultLoadingIndicator() {
  return <LoadingIndicator />;
}

function ErrorBoundary({ children }: ErrorBoundaryProps) {
  const [isDynamicImportError, setIsDynamicImportError] = useState(false);
  const handleError = useCallback(
    (error: Error, info: { componentStack: string }) => {
      captureException(error, { extra: info });
      const errorMessage = error.message;
      if (
        DYNAMIC_CHUNKS_ERROR_MESSAGE.some((message) =>
          errorMessage.includes(message)
        )
      ) {
        setIsDynamicImportError(true);
        window.location.reload();
      }
    },
    []
  );

  return (
    <ReactErrorBoundary
      onError={handleError}
      FallbackComponent={
        isDynamicImportError ? DefaultLoadingIndicator : ErrorFallback
      }
      onReset={() => {
        if (isDynamicImportError) {
          setIsDynamicImportError(false);
        }
      }}
    >
      {children}
    </ReactErrorBoundary>
  );
}
export default ErrorBoundary;

export { ErrorBoundary, ErrorFallback };
