import { useSetAtom } from 'jotai/react';
import { atom } from 'jotai/vanilla';
import { ReactNode, useEffect } from 'react';

export const breadcrumbsAtom = atom<Record<string, ReactNode>>({});

export const useCustomBreadcrumb = (items: Record<string, ReactNode>) => {
  const setBreadcrumb = useSetAtom(breadcrumbsAtom);

  useEffect(() => {
    setBreadcrumb((prev) => ({ ...prev, ...items }));
  }, [items, setBreadcrumb]);
};
