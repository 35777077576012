// Soft UI Dashboard PRO React base styles

import colors from 'shared/theme/base/colors';

// Soft UI Dashboard PRO React helper functions
import rgba from 'shared/theme/functions/rgba';

const { blue, white } = colors;

export default {
  '.flatpickr-day:hover, .flatpickr-day:focus, .flatpickr-day.nextMonthDay:hover, .flatpickr-day.nextMonthDay:focus':
    {
      background: rgba(blue.main, 0.28),
      borderColor: 'transparent !important',
    },

  '.flatpickr-day.today, .flatpickr-day.today:hover, .flatpickr-day.today:focus, .flatpickr-day.selected, .flatpickr-day.selected:hover, .flatpickr-day.selected:focus, .flatpickr-day.nextMonthDay.selected, .flatpickr-day.nextMonthDay.selected:hover, .flatpickr-day.nextMonthDay.selected:focus':
    {
      background: blue.main,
      color: white.main,
      borderColor: 'transparent !important',
    },
};
