import {
  CircularProgress,
  Icon,
  InputAdornment,
  InputBaseComponentProps,
  TextField,
  TextFieldProps,
} from '@mui/material';
import React, { forwardRef, useMemo } from 'react';

export interface SuiInputProps extends Omit<TextFieldProps, 'ref'> {
  withIcon?: {
    icon?: React.ReactNode | boolean;
    direction?: 'none' | 'left' | 'right';
  };
  error?: boolean;
  success?: boolean;
  customClass?: string;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  shrink?: boolean;
  loading?: boolean;
  inputComponent?: React.ElementType<InputBaseComponentProps>;
}

const SuiInput = forwardRef<HTMLInputElement, SuiInputProps>(
  (
    {
      helperText,
      label,
      success,
      inputComponent,
      withIcon = {
        icon: false,
        direction: 'none',
      },
      error = false,
      disabled = false,
      readOnly = false,
      placeholder = '',
      loading = false,
      ...rest
    },
    ref
  ) => {
    return (
      <TextField
        fullWidth
        ref={ref}
        error={error}
        helperText={helperText}
        disabled={disabled}
        label={label}
        sx={{ mt: label ? '6px' : 0 }}
        InputProps={{
          inputComponent,
          placeholder,
          startAdornment: withIcon?.direction === 'left' && (
            <InputAdornment position="start">
              <Icon fontSize="small">{withIcon.icon}</Icon>
            </InputAdornment>
          ),
          endAdornment: (withIcon?.direction === 'right' || loading) && (
            <InputAdornment position="end">
              {loading ? (
                <CircularProgress size={16} />
              ) : (
                <Icon fontSize="small">{withIcon.icon}</Icon>
              )}
            </InputAdornment>
          ),
          readOnly: readOnly || loading,
          sx: {
            input: {
              width: '100% !important',
            },
          },
          ...rest.InputProps,
        }}
        {...rest}
      />
    );
  }
);

export default SuiInput;
