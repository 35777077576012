function replaceParams(p: string, params: { [key: string]: string }) {
  let path = p;
  // Match all parameters in the path using a regular expression
  const matches = path.match(/:[a-zA-Z]+/g);

  // If there are no parameters, return the original path
  if (!matches) {
    return path;
  }

  // Replace each parameter with its corresponding value
  matches.forEach((match) => {
    const paramName = match.slice(1); // Remove the leading colon
    if (params[paramName]) {
      path = path.replace(match, params[paramName]);
    }
  });

  return path;
}

export default replaceParams;
