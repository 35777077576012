import { Box, IconButton, Typography } from '@mui/material';
import { ReactNode, useCallback, useState } from 'react';
import {
  Id as ToastId,
  ToastOptions,
  TypeOptions,
  toast,
} from 'react-toastify';
import { NotifyStatus, ToastAction } from '../types';

export type ShowToastCallback = (
  params: {
    notifyStatus: NotifyStatus;
    toastContent: { message: ReactNode; actions?: ToastAction[] };
  },
  options?: ToastOptions
) => void;

export interface NotifyToastReturnValues {
  toastId: ToastId | null;
  showToast: ShowToastCallback;
}

function ToastContent({
  message,
  actions = [],
}: {
  message: ReactNode;
  actions?: ToastAction[];
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 0.5,
        flexWrap: 'no-wrap',
        justifyContent: 'space-between',
      }}
    >
      <Typography fontSize={16} sx={{ flexGrow: 2, whiteSpace: 'pre-wrap' }}>
        {message}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        {actions.map(({ key, icon: Icon, onClick }) => (
          <IconButton
            key={key}
            onClick={onClick}
            size="small"
            sx={{
              width: (theme) => theme.spacing(5),
              height: (theme) => theme.spacing(5),
              alignSelf: 'center',
            }}
          >
            <Icon fontSize="small" />
          </IconButton>
        ))}
      </Box>
    </Box>
  );
}

export const useNotifyToast = (): NotifyToastReturnValues => {
  const [toastId, setToastId] = useState<ToastId | null>(null);

  const showToast: ShowToastCallback = useCallback(
    ({ notifyStatus, toastContent }, options) => {
      const content = <ToastContent {...toastContent} />;
      const toastConfigs: ToastOptions = {
        autoClose: 2500,
        isLoading: notifyStatus === NotifyStatus.LOADING,
        type: (notifyStatus === NotifyStatus.LOADING
          ? 'info'
          : notifyStatus) as TypeOptions,
        ...options,
      };

      if (toastId) {
        toast.update(toastId, { render: content, ...toastConfigs });
        setToastId(null);
        return;
      }

      const newToastId = toast(content, toastConfigs);
      if (notifyStatus === NotifyStatus.LOADING) {
        setToastId(newToastId);
      }
    },
    [toastId]
  );

  return {
    toastId,
    showToast,
  };
};
