import {
  Box,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { BILL_QUERY_KEY } from 'apis-query/bill.query';
import { BOOING_QUERY_KEY } from 'apis-query/booking.query';
import { ORDER_QUERY_KEY } from 'apis-query/orderBooking.query';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import axios, { AxiosError } from 'axios';
import { queryClient } from 'queryClient';
import { useCallback, useEffect, useRef, useState } from 'react';
import mylaClient from 'shared/api/myla';
import { BookingStatus } from 'shared/enums';
import Swal from 'sweetalert2';
import useMylaMutation from 'shared/packages/myla-query/hooks/useMutation';
import { useBookingFormContext } from '../../contexts/BookingFormContext';
import StatusTag from './StatusTag';

const getStatusOptions = (status: BookingStatus) => {
  switch (status) {
    case BookingStatus.NEW:
      return [BookingStatus.LOCKED, BookingStatus.CANCELLED];
    case BookingStatus.LOCKED:
      return [BookingStatus.CHECKIN, BookingStatus.CANCELLED];
    case BookingStatus.CHECKIN:
      return [BookingStatus.CHECKOUT];
    default:
      return [];
  }
};

const UPDATE_STATUS_API_PATH_MAPPER: Record<string, string> = {
  [BookingStatus.LOCKED]: 'LOCKED',
  [BookingStatus.CHECKIN]: 'CHECKIN',
  [BookingStatus.CHECKOUT]: 'CHECKOUT',
  [BookingStatus.CANCELLED]: 'CANCELLED',
};

export default function BookingStatusTag({
  bookingId,
  status,
  onSubmitForm,
}: {
  bookingId?: string;
  status: BookingStatus;
  onSubmitForm?: () => Promise<void>;
}) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [bookingStatus, setBookingStatus] = useState<BookingStatus>(status);
  const { handlePrint } = useBookingFormContext();

  const { mutate: updateBookingStatus, isLoading } = useMylaMutation<
    BookingStatus,
    Error | AxiosError,
    BookingStatus
  >({
    mutationFn: async (newStatus) => {
      if (typeof onSubmitForm === 'function') {
        await onSubmitForm();
      }
      return mylaClient
        .patch(`/bookings/${bookingId}/update-status`, {
          status: UPDATE_STATUS_API_PATH_MAPPER[newStatus],
        })
        .then(() => newStatus);
    },
    onSuccess: async (newStatus) => {
      queryClient.invalidateQueries([BOOING_QUERY_KEY.GET_LIST_BOOKING]);
      queryClient.invalidateQueries([BOOING_QUERY_KEY.GET_BOOKING]);
      queryClient.invalidateQueries([BILL_QUERY_KEY.GET_BOOKING_BILL]);
      queryClient.invalidateQueries([BILL_QUERY_KEY.GET_ORDER_BILL]);
      queryClient.invalidateQueries([ORDER_QUERY_KEY.GET_ORDER_BOOKING]);
      setBookingStatus(newStatus);
    },
    notify: {
      error: (error) => ({
        message: axios.isAxiosError(error)
          ? error.response?.data?.message || error.message
          : 'Cập nhật trạng thái booking thất bại, vui lòng kiểm tra lại',
      }),
      success: (newStatus) => ({
        message: 'Cập nhật trạng thái booking thành công!',
        actions:
          newStatus === BookingStatus.CHECKOUT
            ? [
                {
                  key: 'invoice',
                  icon: FileDownloadIcon,
                  onClick: handlePrint,
                },
              ]
            : undefined,
      }),
    },
  });

  const onClose = useCallback(() => setOpen(false), []);

  const handleChangeStatus = useCallback(
    (statusOption: BookingStatus) => {
      onClose();
      if (statusOption === BookingStatus.CANCELLED) {
        Swal.fire({
          title: 'Bạn có chắc chắn?',
          text: 'Bạn có chắc chắn muốn hủy booking này?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Huỷ booking',
          cancelButtonText: 'Quay lại',
          reverseButtons: true,
          customClass: {
            cancelButton: 'button button-secondary',
            confirmButton: 'button button-error',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            updateBookingStatus(statusOption);
          }
        });
      } else {
        updateBookingStatus(statusOption);
      }
    },
    [onClose, updateBookingStatus]
  );

  useEffect(() => {
    setBookingStatus(status);
  }, [status]);

  return (
    <>
      <Box
        ref={anchorRef}
        onClick={() => setOpen(true)}
        sx={{
          userSelect: 'none',
          cursor: isLoading ? 'not-allowed' : 'pointer',
          pointerEvents: isLoading ? 'none' : 'all',
        }}
      >
        <StatusTag status={bookingStatus} />
      </Box>
      <Popper
        transition
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        sx={{ zIndex: 3000 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper sx={{ borderRadius: '0.5rem', overflow: 'hidden' }}>
              <ClickAwayListener onClickAway={onClose}>
                <MenuList autoFocusItem={open}>
                  {getStatusOptions(bookingStatus).map((statusOption) => (
                    <MenuItem
                      key={statusOption}
                      sx={{ borderRadius: 0 }}
                      onClick={() => handleChangeStatus(statusOption)}
                    >
                      <StatusTag noBorder status={statusOption} />
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
