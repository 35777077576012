import { ErrorBoundary } from 'components/ErrorBoundary';
import { useSoftUIController } from 'context/softUI';
import Configurator from 'examples/Configurator';
import Footer from 'examples/Footer';
import DashboardContainer from 'examples/LayoutContainers/DashboardContainer';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Sidenav from 'examples/Sidenav';
import { ReactNode, useState } from 'react';
import { SidebarItem } from '../../examples/Sidenav/types';

export default function DashboardLayout({
  sidebarItems,
  children,
}: {
  children: ReactNode;
  sidebarItems: SidebarItem[];
}) {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      dispatch({ type: 'MINI_SIDENAV', value: false });
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      dispatch({ type: 'MINI_SIDENAV', value: true });
      setOnMouseEnter(false);
    }
  };

  return (
    <>
      <Sidenav
        routes={sidebarItems}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />
      <Configurator />
      <ErrorBoundary>
        <DashboardContainer>
          <DashboardNavbar />
          {children}
          <Footer />
        </DashboardContainer>
      </ErrorBoundary>
    </>
  );
}
