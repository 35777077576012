import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export enum NotifyStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  LOADING = 'loading',
}

export interface ToastAction {
  key: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
  onClick: () => void;
}

export type ToastContentGetter<TParams> = (params: TParams) => {
  message: React.ReactNode;
  actions?: ToastAction[];
};

export interface NotifyConfigs<TData, TError> {
  [NotifyStatus.SUCCESS]?: string | ToastContentGetter<TData>;
  [NotifyStatus.ERROR]?: string | ToastContentGetter<TError>;
  [NotifyStatus.LOADING]?: string | ToastContentGetter<void>;
}
