import { atom } from 'jotai/vanilla';
import { ChildRoomWithRoomData } from 'shared/models/booking.model';
import { OrderMeta } from 'shared/models/orderMeta.model';

export interface CalendarAtomValues {
  selectedRows: ChildRoomWithRoomData[];
  selectedRange?: [Date, Date];
  selectedOrder?: OrderMeta;
}

export const calendarAtom = atom<CalendarAtomValues>({ selectedRows: [] });

export interface Availabilities {
  canInclude: boolean;
  canCheckIn: boolean;
  canCheckOut: boolean;
}

export type AvailabilitiesAtomValues = Record<
  string,
  Record<number, Availabilities>
>;

export const availabilitiesAtom = atom<AvailabilitiesAtomValues>({});
