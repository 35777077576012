import {
  Logout as LogoutIcon,
  PersonOutlined as PersonIcon,
} from '@mui/icons-material';
import {
  AppBar,
  Avatar,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import SuiBox from 'components/SuiBox';
import { useAuth } from 'context/auth';
import { useSoftUIController } from 'context/softUI';
import Breadcrumbs from 'examples/Breadcrumbs';
import styles from 'examples/Navbars/DashboardNavbar/styles';
import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from 'shared/constants';
import { getUserPhotoUrl } from 'shared/utils/getUserPhotoUrl';
import NotificationButton from './NotificationButton';

interface DashboardNavbarProps {
  absolute?: boolean;
  light?: boolean;
  isMini?: boolean;
}

enum NavBarType {
  Sticky = 'sticky',
  Static = 'static',
}

function DashboardNavbar({
  absolute = false,
  light = false,
  isMini = false,
}: DashboardNavbarProps) {
  const [navbarType, setNavbarType] = useState<NavBarType>();

  const [controller, dispatch] = useSoftUIController();
  const navigate = useNavigate();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } =
    controller;
  const classes = styles({ transparentNavbar, absolute, light, isMini });
  const route = useLocation().pathname.split('/').slice(1);
  const { user, signOut } = useAuth();

  const latestTransparentNavbarRef = useRef(transparentNavbar);
  latestTransparentNavbarRef.current = transparentNavbar;

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType(NavBarType.Sticky);
    } else {
      setNavbarType(NavBarType.Static);
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      const isTransparent =
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar;
      if (isTransparent !== latestTransparentNavbarRef.current) {
        dispatch({
          type: 'TRANSPARENT_NAVBAR',
          value: isTransparent,
        });
      }
    }

    /** 
    The event listener that's calling the handleTransparentNavbar function when 
    scrolling the window.
    */
    window.addEventListener('scroll', handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener('scroll', handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () =>
    dispatch({ type: 'MINI_SIDENAV', value: !miniSidenav });

  const handleConfiguratorOpen = () =>
    dispatch({ type: 'OPEN_CONFIGURATOR', value: !openConfigurator });

  const [accountMenuAnchorEl, setAccountMenuAnchorEl] =
    useState<HTMLDivElement | null>(null);

  const open = Boolean(accountMenuAnchorEl);

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    setAccountMenuAnchorEl(event.currentTarget);
  };

  const handleCloseAccountMenu = useCallback(() => {
    setAccountMenuAnchorEl(null);
  }, []);
  const accountMenuItems = useMemo(
    () => [
      {
        key: 'account',
        text: 'Tài khoản của tôi',
        icon: <PersonIcon fontSize="small" />,
        onClick: () => navigate(ROUTE_PATHS.ACCOUNT.SETTINGS),
      },
      {
        key: 'logout',
        text: 'Đăng xuất',
        icon: <LogoutIcon fontSize="small" />,
        onClick: signOut,
      },
    ],
    [navigate, signOut]
  );

  return (
    <AppBar
      position={absolute ? 'absolute' : navbarType}
      color="inherit"
      className={classes.navbar}
    >
      <Toolbar className={classes.navbar_container}>
        <SuiBox
          customClass={classes.navbar_row}
          color="inherit"
          mb={{ xs: 1, md: 0 }}
        >
          <Breadcrumbs
            icon="home"
            title={route[route.length - 1]}
            route={route}
            light={light}
          />
          <Icon
            className={classes.navbar_desktop_menu}
            fontSize="medium"
            onClick={handleMiniSidenav}
          >
            {miniSidenav ? 'menu_open' : 'menu'}
          </Icon>
        </SuiBox>
        {isMini ? null : (
          <SuiBox customClass={classes.navbar_row}>
            <SuiBox
              color={light ? 'white' : 'inherit'}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <Avatar
                src={getUserPhotoUrl(user)}
                alt={user?.name}
                onClick={handleClick}
                sx={{
                  width: 24,
                  height: 24,
                  mr: 1,
                  cursor: 'pointer',

                  '& > img': {
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                  },
                }}
              />
              <Menu
                anchorEl={accountMenuAnchorEl}
                open={open}
                onClose={handleCloseAccountMenu}
                transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                sx={{ mt: 1 }}
              >
                {accountMenuItems.map(({ onClick, key, text, icon }) => (
                  <MenuItem
                    key={key}
                    onClick={() => {
                      onClick?.();
                      handleCloseAccountMenu();
                    }}
                  >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText>
                      <Typography
                        sx={{
                          fontSize: '16px !important',
                          fontWeight: 'medium',
                        }}
                      >
                        {text}
                      </Typography>
                    </ListItemText>
                  </MenuItem>
                ))}
              </Menu>
              <IconButton
                size="small"
                color="inherit"
                className={classes.navbar_mobile_menu}
                onClick={handleMiniSidenav}
              >
                <Icon className={light ? 'text-white' : 'text-dark'}>
                  {miniSidenav ? 'menu_open' : 'menu'}
                </Icon>
              </IconButton>
              <IconButton
                size="small"
                color="inherit"
                className={classes.navbar_icon_button}
                onClick={handleConfiguratorOpen}
              >
                <Icon>settings</Icon>
              </IconButton>
              <NotificationButton
                lighTheme={light}
                userUid={user?.uid}
                className={classes.navbar_icon_button}
              />
            </SuiBox>
          </SuiBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default DashboardNavbar;
