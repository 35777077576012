// @mui material components
import { makeStyles } from '@mui/styles';

export default makeStyles(({ functions }) => {
  const { pxToRem } = functions;

  return {
    list: {
      paddingLeft: pxToRem(16),
      marginLeft: pxToRem(24),
    },
  };
});
