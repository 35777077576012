import { ApiResponseList } from 'interfaces/api-response.interface';
import { PageOptions } from 'interfaces/page-options.interface';
import mylaClient from 'shared/api/myla';
import { API_PATHS } from 'shared/constants';
import { Booking } from 'shared/models/booking.model';
import { replaceParams } from 'utils/helpers';

export async function getBooking(id: string, option: PageOptions) {
  const res = await mylaClient.get<Booking>(
    `${replaceParams(API_PATHS.BOOKING.GET, {
      id,
    })}`,
    {
      params: option,
    }
  );
  return res.data;
}

export async function createBooking(newData: Partial<Booking>) {
  const res = await mylaClient.post<Booking>(API_PATHS.BOOKING.CREATE, newData);
  return res.data;
}

export async function updateBooking(newData: Partial<Booking>) {
  const res = await mylaClient.patch<Booking>(
    replaceParams(API_PATHS.BOOKING.UPDATE, { id: newData._id || '' }),
    newData
  );
  return res.data;
}

export async function confirmBooking(newData: Partial<Booking>) {
  const res = await mylaClient.patch<Booking>(
    replaceParams(API_PATHS.BOOKING.CONFIRM, { id: newData._id || '' }),
    newData
  );
  return res.data;
}
export async function checkinBooking(newData: Partial<Booking>) {
  const res = await mylaClient.patch<Booking>(
    replaceParams(API_PATHS.BOOKING.CHECKIN, { id: newData._id || '' }),
    newData
  );
  return res.data;
}
export async function checkoutBooking(newData: Partial<Booking>) {
  const res = await mylaClient.patch<Booking>(
    replaceParams(API_PATHS.BOOKING.CHECKOUT, { id: newData._id || '' }),
    newData
  );
  return res.data;
}
export async function completeBooking(newData: Partial<Booking>) {
  const res = await mylaClient.patch<Booking>(
    replaceParams(API_PATHS.BOOKING.COMPLETE, { id: newData._id || '' }),
    newData
  );
  return res.data;
}
export async function cancelBooking(newData: Partial<Booking>) {
  const res = await mylaClient.patch<Booking>(
    replaceParams(API_PATHS.BOOKING.CANCEL, { id: newData._id || '' }),
    newData
  );
  return res.data;
}

export async function getListBooking(option: PageOptions) {
  const res = await mylaClient.get<ApiResponseList<Booking>>(
    API_PATHS.BOOKING.LIST,
    {
      params: option,
    }
  );
  return res.data;
}
