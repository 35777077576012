export const ROUTES_PATH = {
  DISTRIBUTOR: {
    LIST: '/distributors',
    CREATE: '/distributors/create',
    EDIT: '/distributors/:distributorId/edit',
    VIEW: '/distributors/:distributorId',
  },
  UNIT: {
    CREATE: '/distributors/:distributorId/units/create',
    EDIT: '/distributors/:distributorId/units/:unitId/edit',
    VIEW: '/distributors/:distributorId/units/:unitId',
    LIST: '/distributors/:distributorId/unit',
  },
} as const;
