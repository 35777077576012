import clsx from 'clsx';
import styles from 'components/SuiButton/styles';
import React, { forwardRef } from 'react';
import { Button, CircularProgress, ButtonProps, Theme } from '@mui/material';

export interface SuiButtonProps extends Omit<ButtonProps, 'variant'> {
  size?: 'small' | 'medium' | 'large';
  variant?: 'text' | 'contained' | 'outlined' | 'gradient';
  buttonColor?: keyof Theme['palette'];
  circular?: boolean;
  iconOnly?: boolean;
  children: React.ReactNode;
  customClass?: string;
  loading?: boolean;
  component?: React.ElementType;
  to?: string;
  target?: string;
  rel?: string;
}

const SuiButton = forwardRef<HTMLButtonElement, SuiButtonProps>(
  (
    {
      size = 'medium',
      variant = 'contained',
      buttonColor = 'white',
      circular = false,
      iconOnly = false,
      customClass = '',
      loading = false,
      children,
      ...rest
    },
    ref
  ) => {
    const classes = styles({ buttonColor, variant, size, iconOnly });
    return (
      <Button
        {...rest}
        ref={ref}
        color="primary"
        variant={variant === 'gradient' ? 'contained' : variant}
        size={size}
        sx={{ ...rest.sx, whiteSpace: 'nowrap' }}
        className={clsx(classes[variant], customClass, {
          [classes.circular]: circular,
          [classes.iconOnly]: iconOnly,
        })}
      >
        {children}
        {loading && (
          <CircularProgress color="inherit" size={12} sx={{ ml: 1 }} />
        )}
      </Button>
    );
  }
);

export default SuiButton;
