// @mui material components
import { Breadcrumbs as MuiBreadcrumbs, Icon } from '@mui/material';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';

// Custom styles for Breadcrumbs
import styles from 'examples/Breadcrumbs/styles';
import { useAtomValue } from 'jotai/react';

// prop-types is a library for typechecking of props.
import React from 'react';
// react-router-dom components
import { Link } from 'react-router-dom';
import { breadcrumbsAtom } from 'shared/atoms/breadcrumbs';

interface BreadcrumbsProps {
  icon: React.ReactNode;
  title: string;
  route: string[];
  light?: boolean;
}

function Breadcrumbs({ icon, title, route, light = false }: BreadcrumbsProps) {
  const classes = styles({ light });
  const routes = route.slice(0, -1);
  const breadcrumbLabels = useAtomValue(breadcrumbsAtom);

  return (
    <SuiBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs className={classes.breadcrumbs}>
        <Link to="/">
          <SuiTypography
            component="span"
            variant="body2"
            textColor={light ? 'white' : 'dark'}
            opacity={light ? 0.8 : 0.5}
            customClass="line-height-0"
          >
            <Icon>{icon}</Icon>
          </SuiTypography>
        </Link>
        {routes.map((el, index) => (
          <Link
            to={`/${routes.slice(0, index + 1).join('/')}`}
            key={el}
            className="decoration-none"
          >
            <SuiTypography
              component="span"
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
              textColor={light ? 'white' : 'dark'}
              opacity={light ? 0.8 : 0.5}
              customClass="line-height-0"
            >
              {breadcrumbLabels[el] || el}
            </SuiTypography>
          </Link>
        ))}
        <SuiTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          textColor={light ? 'white' : 'dark'}
          customClass="line-height-0"
        >
          {breadcrumbLabels[title] || title.replace('-', ' ')}
        </SuiTypography>
      </MuiBreadcrumbs>
    </SuiBox>
  );
}

export default Breadcrumbs;
