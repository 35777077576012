import { RouteObject } from 'react-router-dom';
import Authentication from './components/Authentication';
import LockBoard from './components/LockBoard';

const routes: RouteObject = {
  path: 'lock-management',
  children: [
    {
      index: true,
      element: <LockBoard />,
    },
    {
      path: 'authenticate',
      element: <Authentication />,
    },
  ],
};

export default routes;
