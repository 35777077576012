import { Box } from '@mui/material';
import ErrorSlate from 'components/ErrorSlate';

interface ErrorFallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

function ErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '80vh',
      }}
    >
      <ErrorSlate
        title="Oops!"
        subTitle={`Đã xảy ra lỗi: ${error.message}`}
        retry={resetErrorBoundary}
      />
    </Box>
  );
}

export default ErrorFallback;
