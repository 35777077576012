// Soft UI Dashboard PRO React Base Styles

import borders from 'shared/theme/base/borders';

// Soft UI Dashboard PRO React Helper Functions

import pxToRem from 'shared/theme/functions/pxToRem';

const { borderRadius } = borders;

export default {
  styleOverrides: {
    root: {
      borderRadius: borderRadius.xl,
      margin: `${pxToRem(16)} ${pxToRem(16)} 0`,
    },

    media: {
      width: 'auto',
    },
  },
};
