import { ThemeProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { QueryClientProvider } from '@tanstack/react-query';
import App from 'App';
import ServiceWorkerContextProvider from 'context/serviceWorker';
import { SoftUIControllerProvider } from 'context/softUI';
import { Provider } from 'jotai/react';
import { HydrateAtoms, queryClient } from 'queryClient';
import { createRoot } from 'react-dom/client';
import 'react-toastify/dist/ReactToastify.css';
import 'sweetalert2/dist/sweetalert2.css';
import './initialize/sentry';
import theme from './shared/theme';

const container = document.getElementById('root');

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ServiceWorkerContextProvider>
          <SoftUIControllerProvider>
            <Provider>
              <HydrateAtoms>
                <App />
              </HydrateAtoms>
            </Provider>
          </SoftUIControllerProvider>
        </ServiceWorkerContextProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </LocalizationProvider>
);
