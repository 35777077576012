import CampaignIcon from '@mui/icons-material/Campaign';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  Box,
  Grow,
  Icon,
  ListItem,
  ListItemIcon,
  ListItemText,
  alpha,
  useTheme,
} from '@mui/material';
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import { format } from 'date-fns';
import styles from 'examples/Items/NotificationItem/styles';
import { Ref, forwardRef, useMemo } from 'react';
import {
  IsSeenEnum,
  Notification,
  NotificationLevel,
} from 'shared/models/notification.model';

interface NotificationItemProps {
  notificationData: Notification;
  onClick: () => void;
  selected: boolean;
  toggleSelected: (id: string) => void;
}

const NotificationItem = forwardRef(
  (
    {
      notificationData,
      toggleSelected,
      selected,
      onClick,
      ...rest
    }: NotificationItemProps,
    ref: Ref<HTMLLIElement>
  ) => {
    const classes = styles();
    const theme = useTheme();

    const { colorType: level } = notificationData;
    const isSeen = notificationData.isSeen === IsSeenEnum.Seen;

    const notificationIcon = useMemo(() => {
      switch (level) {
        case NotificationLevel.WARNING:
          return (
            <ErrorOutlineIcon
              fontSize="small"
              sx={{ color: theme.palette.white.main }}
            />
          );
        case NotificationLevel.ERROR:
          return (
            <HighlightOffIcon
              fontSize="small"
              sx={{ color: theme.palette.white.main }}
            />
          );
        default:
          return (
            <CampaignIcon
              fontSize="small"
              sx={{ color: theme.palette.white.main }}
            />
          );
      }
    }, [level, theme.palette.white.main]);

    const itemColor = useMemo(() => {
      const mainColor =
        theme.palette.gradients[level] || theme.palette.gradients.info;

      return theme.functions.linearGradient(mainColor.main, mainColor.state);
    }, [level, theme.functions, theme.palette.gradients]);

    return (
      <ListItem
        {...rest}
        ref={ref}
        className={classes.notificationItem}
        sx={{
          cursor: 'pointer',
          backgroundColor: isSeen
            ? 'transparent'
            : alpha(theme.palette.light.main, 0.5),
        }}
      >
        <ListItemIcon sx={{ minWidth: 40 }}>
          <Box
            className={classes.notificationItem_img}
            sx={{
              mt: 0.5,
              cursor: 'pointer',
              background: selected ? theme.palette.success.main : itemColor,
            }}
            onClick={(event) => {
              event.preventDefault();
              toggleSelected(notificationData.id);
            }}
          >
            {selected ? (
              <Grow in={selected}>
                <CheckIcon
                  fontSize="small"
                  sx={{ color: theme.palette.white.main }}
                />
              </Grow>
            ) : (
              notificationIcon
            )}
          </Box>
        </ListItemIcon>
        <ListItemText onClick={onClick}>
          <SuiBox display="flex" flexDirection="column">
            <SuiBox display="flex" columnGap={1} alignItems="center">
              <SuiTypography
                variant="button"
                textTransform="capitalize"
                fontWeight="medium"
                noWrap
              >
                {notificationData.title}
              </SuiTypography>
              {!isSeen && (
                <SuiBox
                  sx={{
                    backgroundColor: `${theme.palette.info.main} !important`,
                    width: 8,
                    height: 8,
                    borderRadius: '100%',
                  }}
                />
              )}
            </SuiBox>
            <SuiTypography
              variant="caption"
              fontWeight="regular"
              sx={{ whiteSpace: 'normal' }}
            >
              {notificationData.body}
            </SuiTypography>
          </SuiBox>
          <SuiTypography
            variant="caption"
            textColor="secondary"
            customClass={classes.notificationItem_date}
          >
            <SuiTypography
              component={Icon}
              variant="button"
              textColor="secondary"
              sx={{ mr: 0.5 }}
              className={`material-icon-round ${classes.notificationItem_icon}`}
            >
              watch_later
            </SuiTypography>
            {notificationData.id &&
              format(new Date(notificationData.id), 'dd/MM/yyyy HH:mm:ss')}
          </SuiTypography>
        </ListItemText>
      </ListItem>
    );
  }
);

export default NotificationItem;
