import EditIcon from '@mui/icons-material/Edit';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PinDropIcon from '@mui/icons-material/PinDrop';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { Typography, Unstable_Grid2 as Grid2, useTheme } from '@mui/material';
import {
  useGetOrderBooking,
  useUpdateOrderBooking,
} from 'apis-query/orderBooking.query';
import FormikInput from 'components/Formik/FormikInput';
import SuiBox from 'components/SuiBox';
import SuiModal from 'components/SuiModal';
import SuiTypography from 'components/SuiTypography';
import NumericFormatInput from 'components/NumericFormatInput';
import { Formik, FormikProps, FormikValues } from 'formik';
import { useCallback, useMemo, useRef, useState } from 'react';
import pxToRem from 'shared/theme/functions/pxToRem';
import * as yup from 'yup';
import { useGetOrderBill } from 'apis-query/bill.query';
import { Loader } from 'components/LoadingIndicator';
import StatusTag from '../BookingForm/StatusTag';
import displayPeriodDay from '../BookingForm/helpers';
import OrderBillForm from '../OrderBill';
import FormErrors from '../FormErrors';

const bookingFormValidationSchema = yup.object().shape({
  totalPrice: yup.number().required('Tổng giá không được để trống'),
  numberOfGuests: yup.number().required('Số khách không được để trống'),
});

interface OrderFormProps {
  onClose: () => void;
  onOpenBookingForm: (bookingId: string) => void;
  orderId: string;
}

export default function OrderForm({
  orderId,
  onClose,
  onOpenBookingForm,
}: OrderFormProps) {
  const theme = useTheme();
  const formikRef = useRef<FormikProps<FormikValues> | null>(null);
  const { data: orderBooking, isLoading } = useGetOrderBooking(orderId, {
    populate: 'distributor,customer,unit,childRoom,childRoom.room,booking',
  });
  const { data: orderBill, isLoading: isLoadingOrderBill } =
    useGetOrderBill(orderId);
  const [openOrderBill, setOpenOrderBill] = useState(false);
  const {
    mutate: updateOrderBookingMutation,
    isLoading: isLoadingUpdate,
    error: updateOrderError,
  } = useUpdateOrderBooking({
    onSuccess: async () => {
      onClose();
    },
  });
  const onSubmit = useCallback(async (values: any) => {
    updateOrderBookingMutation(values);
  }, []);

  const initFormValues = useMemo(
    () => ({
      _id: orderBooking?._id,
      customerName: orderBooking?.customer?.name || '',
      customerPhone: orderBooking?.customer?.phone || '',
      numberOfGuests: orderBooking?.childRoom?.room?.maxCapacity || 0,
      dateRange: displayPeriodDay(
        orderBooking?.checkInDate,
        orderBooking?.checkOutDate
      ),
      totalPrice: orderBill?.totalAmount || 0,
      notes: orderBooking?.notes || '',
    }),
    [orderBooking, orderBill]
  );
  const suiModalConfig = useMemo(() => {
    return {
      unitName: orderBooking?.unit?.name || '',
      distributorName: orderBooking?.distributor?.name || '',
      title: `Cập nhật đơn phòng`,
      confirmText: `Lưu cập nhật`,
      cancelText: 'ĐÓNG',
    };
  }, [orderBooking?.distributor?.name, orderBooking?.unit?.name]);

  const handleEditOrderBill = () => {
    setOpenOrderBill(true);
  };

  return (
    <SuiModal
      showCloseIcon={false}
      open={!!orderId}
      onClose={onClose}
      sx={{
        h4: {
          width: '100%',
        },
      }}
      loading={isLoading || isLoadingUpdate || isLoadingOrderBill}
      title={
        <SuiBox
          display="flex"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
        >
          <Typography
            sx={{
              fontSize: {
                xs: '1.2rem',
                sm: '1.6rem',
                md: '2rem',
              },
            }}
            fontWeight="bold"
          >
            {suiModalConfig.title}
          </Typography>
          {orderBooking?.status && (
            <StatusTag noBorder status={orderBooking.status} />
          )}
        </SuiBox>
      }
      confirmText={suiModalConfig.confirmText}
      cancelText={suiModalConfig.cancelText}
      onConfirm={() => formikRef.current?.submitForm()}
      desktopHeight="80dvh"
      desktopWidth={theme.spacing(100)}
      backText="Về trang trước"
    >
      {orderId && isLoading ? (
        <Loader />
      ) : (
        <SuiBox>
          <SuiBox
            mt={1}
            mb={1.5}
            display="flex"
            columnGap={pxToRem(29)}
            sx={{
              gap: {
                xs: '8px 24px',
                md: 4,
              },
              flexWrap: 'wrap',
            }}
          >
            <SuiBox
              display="flex"
              alignItems="center"
              onClick={() => {
                if (orderBooking?.booking?._id) {
                  onOpenBookingForm(orderBooking?.booking?._id);
                } else if (typeof orderBooking?.booking === 'string') {
                  onOpenBookingForm(orderBooking?.booking);
                }
              }}
            >
              <SuiBox mr={pxToRem(9)}>
                <ReceiptLongIcon />
              </SuiBox>
              <SuiTypography
                variant="h5"
                fontWeight="regular"
                fontSize="1rem"
                textColor="info"
                sx={{
                  paddingBottom: pxToRem(1),
                  borderBottom: `1px solid ${theme.palette.info.main}`,
                  cursor: 'pointer',
                }}
              >
                Thông tin booking
              </SuiTypography>
            </SuiBox>
            <SuiBox display="flex" alignItems="center">
              <SuiBox mr={pxToRem(9)}>
                <HomeWorkIcon />
              </SuiBox>
              <SuiTypography variant="h5" fontWeight="regular" fontSize="1rem">
                {suiModalConfig.distributorName}
              </SuiTypography>
            </SuiBox>
            <SuiBox display="flex" alignItems="center">
              <SuiBox mr={pxToRem(9)}>
                <PinDropIcon />
              </SuiBox>
              <SuiTypography variant="h5" fontWeight="regular" fontSize="1rem">
                {suiModalConfig.unitName}
              </SuiTypography>
            </SuiBox>
          </SuiBox>
          <SuiBox
            sx={{
              borderTop: '1px dashed #BDBDBD',
            }}
            pt={1.5}
          >
            <Formik
              enableReinitialize
              onSubmit={onSubmit}
              initialValues={initFormValues}
              innerRef={formikRef}
              validationSchema={bookingFormValidationSchema}
            >
              <Grid2
                container
                mb={3}
                width="100%"
                rowSpacing={{ xs: 2 }}
                columnSpacing={{ xs: 1, md: 2 }}
              >
                <Grid2 xs={12}>
                  <FormErrors
                    title="Cập nhật đơn phòng không thành công"
                    error={updateOrderError}
                  />
                </Grid2>
                <Grid2 xs={12} md={4}>
                  <FormikInput
                    name="customerName"
                    placeholder="Nhập tên khách hàng"
                    label="Họ tên khách"
                    withIcon={{
                      icon: <PersonOutlineIcon />,
                      direction: 'left',
                    }}
                    customClass="suiInputPlaceholder"
                    readOnly
                  />
                </Grid2>
                <Grid2 xs={12} md={4}>
                  <FormikInput
                    name="customerPhone"
                    placeholder="Nhập số điện thoại khách hàng"
                    label="Số điện thoại"
                    type="tel"
                    withIcon={{
                      icon: <PhoneIphoneIcon />,
                      direction: 'left',
                    }}
                    readOnly
                  />
                </Grid2>

                <Grid2 xs={12} md={4}>
                  <FormikInput
                    name="numberOfGuests"
                    placeholder="Số khách"
                    inputProps={{
                      inputMode: 'numeric',
                    }}
                    inputComponent={NumericFormatInput as any}
                    readOnly
                  />
                </Grid2>

                <Grid2 xs={12} md={6}>
                  <FormikInput
                    hideSuccessIndicator
                    name="dateRange"
                    label="Thời gian lưu trú"
                    disabled
                    readOnly
                  />
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <FormikInput
                    name="totalPrice"
                    placeholder="Tiền phòng (VNĐ)"
                    inputProps={{
                      inputMode: 'decimal',
                    }}
                    inputComponent={NumericFormatInput as any}
                    withIcon={{
                      direction: 'right',
                      icon: (
                        <EditIcon
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleEditOrderBill()}
                        />
                      ),
                    }}
                    readOnly
                  />
                </Grid2>
                <Grid2 xs={12}>
                  <FormikInput
                    name="notes"
                    multiline
                    placeholder="Ghi chú"
                    rows={2}
                  />
                </Grid2>
              </Grid2>
            </Formik>
          </SuiBox>
          {orderId && openOrderBill && (
            <OrderBillForm
              orderId={orderId}
              onClose={() => setOpenOrderBill(false)}
            />
          )}
        </SuiBox>
      )}
    </SuiModal>
  );
}
