import { Box, Typography, useTheme } from '@mui/material';
import ErrorAirBalloon from 'components/Illustrations/ErrorAirBalloon';
import SuiButton from 'components/SuiButton';

interface ErrorSlateProps {
  title?: string;
  subTitle?: string;
  retry?: () => void;
}

export default function ErrorSlate({
  title = 'Đã xảy ra lỗi',
  subTitle,
  retry,
}: ErrorSlateProps) {
  const theme = useTheme();
  return (
    <Box
      p={2}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box sx={{ maxWidth: theme.spacing(120), width: '100%' }}>
        <ErrorAirBalloon />
      </Box>

      {title && (
        <Typography variant="h4" mb={2} textAlign="center">
          {title}
        </Typography>
      )}
      {subTitle && (
        <Typography variant="body1" mb={2} textAlign="center">
          {subTitle}
        </Typography>
      )}
      {retry && (
        <SuiButton variant="contained" buttonColor="dark" onClick={retry}>
          Thử lại
        </SuiButton>
      )}
    </Box>
  );
}
