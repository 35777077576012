import { FormHelperText } from '@mui/material';
import { useField } from 'formik';
import SuiBox from '../SuiBox';
import SuiInput, { SuiInputProps } from '../SuiInput';

interface FormikInputProps
  extends Omit<SuiInputProps, 'onChange' | 'value' | 'ref'> {
  name: string;
  label?: string;
  hideSuccessIndicator?: boolean;
  validate?: (value: string) => string | undefined;
}

function FormikInput({
  name,
  helperText,
  disabled,
  placeholder,
  readOnly,
  label = placeholder,
  hideSuccessIndicator = false,
  ...props
}: FormikInputProps) {
  const [field, meta] = useField({
    validate: props.validate,
    name,
  });
  const { error, touched } = meta;
  const isError = !!error && touched;

  return (
    <SuiInput
      {...field}
      {...props}
      readOnly={readOnly}
      label={label}
      error={isError}
      placeholder={placeholder}
      success={!readOnly && !hideSuccessIndicator && touched && !error}
      helperText={isError ? error : helperText}
    />
  );
}

export default FormikInput;
