// @mui material components
import { makeStyles } from '@mui/styles';
import { get } from 'lodash-es';
import { Theme } from '@mui/material';

export default makeStyles<
  Theme,
  {
    active: boolean;
    noCollapse: boolean;
    open: boolean;
    miniSidenav: boolean;
    transparentSidenav: boolean;
    sidenavColor: string;
  }
>(
  ({
    palette,
    transitions,
    breakpoints,
    typography,
    boxShadows,
    borders,
    functions,
  }) => {
    const { dark, white, info, text, gradients, light, transparent, myla } =
      palette;
    const { fontWeightRegular, fontWeightMedium, size } = typography;
    const { regular, xxl } = boxShadows;
    const { borderRadius } = borders;
    const { rgba, pxToRem } = functions;

    return {
      collapse_item: {
        background: ({ active }) => (active ? white.main : transparent.main),
        color: ({ active }) => (active ? myla.main : text.main),
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        padding: `${pxToRem(10.8)} ${pxToRem(12.8)} ${pxToRem(10.8)} ${pxToRem(
          16
        )}`,
        margin: `0 ${pxToRem(16)}`,
        borderRadius: borderRadius.md,
        cursor: 'pointer',
        userSelect: 'none',
        whiteSpace: 'nowrap',
        boxShadow: 'none',
        [breakpoints.up('xl')]: {
          boxShadow: ({ active, transparentSidenav }) => {
            if (active) {
              return transparentSidenav ? xxl : 'none';
            }

            return 'none';
          },
          transition: transitions.create('box-shadow', {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.shorter,
          }),
        },
      },

      collapse_iconBox: {
        background: ({ active, sidenavColor }) => {
          if (active) {
            return sidenavColor === 'default'
              ? info.main
              : get(palette, `${sidenavColor}.main`);
          }

          return light.main;
        },
        minWidth: pxToRem(32),
        minHeight: pxToRem(32),
        borderRadius: borderRadius.md,
        display: 'grid',
        placeItems: 'center',
        boxShadow: regular,
        transition: transitions.create('margin', {
          easing: transitions.easing.easeInOut,
          duration: transitions.duration.standard,
        }),

        [breakpoints.up('xl')]: {
          background: ({ active, transparentSidenav, sidenavColor }) => {
            let background;

            if (!active) {
              background = transparentSidenav ? white.main : light.main;
            } else if (sidenavColor === 'default') {
              background = info.main;
            } else if (sidenavColor === 'warning') {
              background = gradients.warning.main;
            } else {
              background = get(palette, `myla.main`);
            }

            return background;
          },
        },

        '& svg, svg g': {
          fill: ({ active }: { active: boolean }) =>
            active ? white.main : gradients.dark.state,
        },
      },

      collapse_icon: {
        color: ({ active }) => (active ? white.main : gradients.dark.state),
      },

      collapse_text: {
        marginLeft: pxToRem(12.8),

        [breakpoints.up('xl')]: {
          opacity: ({ miniSidenav, transparentSidenav }) =>
            miniSidenav || (miniSidenav && transparentSidenav) ? 0 : 1,
          maxWidth: ({ miniSidenav, transparentSidenav }) =>
            miniSidenav || (miniSidenav && transparentSidenav) ? 0 : '100%',
          marginLeft: ({ miniSidenav, transparentSidenav }) =>
            miniSidenav || (miniSidenav && transparentSidenav)
              ? 0
              : pxToRem(12.8),
          transition: transitions.create(['opacity', 'margin'], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },

        '& span': {
          fontWeight: ({ active }) =>
            active ? fontWeightMedium : fontWeightRegular,
          fontSize: size.sm,
          lineHeight: 0,
        },
      },

      collapse_arrow: {
        fontSize: `${size.regular} !important`,
        fontWeight: 700,
        marginBottom: pxToRem(-1),
        [breakpoints.up('xl')]: {
          display: ({ noCollapse, transparentSidenav, miniSidenav }) =>
            noCollapse || (transparentSidenav && miniSidenav) || miniSidenav
              ? 'none'
              : 'block',
        },
        transform: ({ open }) => (open ? 'rotate(0)' : 'rotate(-180deg)'),
        color: ({ open }) =>
          open ? dark.main : rgba(gradients.dark.state, 0.4),
        transition: transitions.create(['color', 'transform', 'opacity'], {
          easing: transitions.easing.easeInOut,
          duration: transitions.duration.shorter,
        }),
      },
    };
  }
);
