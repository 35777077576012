import { Box, useTheme } from '@mui/material';
import { useSoftUIController } from 'context/softUI';
import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function DashboardContainer({ children }: { children: ReactNode }) {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction } = controller;
  const { pathname } = useLocation();
  const theme = useTheme();

  useEffect(() => {
    dispatch({ type: 'LAYOUT', value: 'dashboard' });
  }, [pathname]);

  return (
    <Box
      sx={{
        position: 'relative',
        p: 3,
        minHeight: '100vh',
        px: {
          xs: 1.5,
          md: 3,
        },
        pb: {
          md: 6,
          xs: 12,
        },
        [theme.breakpoints.up('xl')]: {
          marginLeft: () => {
            if (direction === 'ltr') {
              return miniSidenav ? theme.spacing(15) : theme.spacing(34);
            }

            return false;
          },
          marginRight: () => {
            if (direction === 'rtl') {
              return miniSidenav ? theme.spacing(15) : theme.spacing(34);
            }

            return false;
          },
          transition: theme.transitions.create(
            ['margin-left', 'margin-right'],
            {
              easing: theme.transitions.easing.easeInOut,
              duration: theme.transitions.duration.standard,
            }
          ),
        },
      }}
    >
      {children}
    </Box>
  );
}

export default DashboardContainer;
