import { Theme } from '@mui/material';
import { Interpolation } from '@mui/styled-engine';

const globalStyles: Interpolation<Theme> = {
  html: {
    scrollbarGutter: 'stable both-edges',
  },

  'body > .Sui-Modal': {
    opacity: 0,
  },

  ':nth-last-child(1 of div.Sui-Modal)': {
    opacity: 1,
  },

  '.Toastify__toast': {
    borderRadius: '8px !important',
  },

  '.Toastify__toast-theme--light > .Toastify__toast-body::before': {
    content: "''",
    width: '4px',
    alignSelf: 'stretch',
    marginRight: '12px',
    borderRadius: '4px',
  },
  '.Toastify__toast-icon': {
    marginRight: '12px',
  },
  '.Toastify__toast--info > .Toastify__toast-body::before': {
    backgroundColor: 'var(--toastify-color-info)',
  },
  '.Toastify__toast--success > .Toastify__toast-body::before': {
    backgroundColor: 'var(--toastify-color-success)',
  },
  '.Toastify__toast--warning > .Toastify__toast-body::before': {
    backgroundColor: 'var(--toastify-color-warning)',
  },
  '.Toastify__toast--error > .Toastify__toast-body::before': {
    backgroundColor: 'var(--toastify-color-error)',
  },
  '.Toastify__toast--spinner > .Toastify__toast-body::before': {
    backgroundColor: 'var(--toastify-color-spinner)',
  },
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#e9ecef',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#adb5bd',
    borderRadius: '8px',
  },
};

export default globalStyles;
