// Soft UI Dashboard PRO React base styles

import colors from 'shared/theme/base/colors';

import boxShadows from 'shared/theme/base/boxShadows';

import borders from 'shared/theme/base/borders';

const { white } = colors;
const { xxl } = boxShadows;
const { borderRadius } = borders;

export default {
  styleOverrides: {
    root: {
      backgroundColor: white.main,
      boxShadow: xxl,
      borderRadius: borderRadius.xl,
    },
  },
};
