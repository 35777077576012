import {
  CssBaseline,
  GlobalStyles,
  StyledEngineProvider,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ErrorBoundary } from 'components/ErrorBoundary';
import LoadingIndicator from 'components/LoadingIndicator';
import CloseButton from 'components/Toast/CloseButton';
import globalStyles from 'globalStyles';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import routes from 'routes';
import useWaitingForNotification from 'shared/hooks/useWaitingForNotification';

const router = createBrowserRouter(routes);

export default function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useWaitingForNotification();

  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline enableColorScheme />
      <GlobalStyles styles={globalStyles} />
      <ErrorBoundary>
        <RouterProvider
          router={router}
          fallbackElement={<LoadingIndicator transparent />}
          future={{ v7_startTransition: true }}
        />
        <ToastContainer
          newestOnTop
          pauseOnFocusLoss
          draggable
          pauseOnHover
          hideProgressBar
          closeOnClick
          position={isMobile ? 'top-center' : 'bottom-right'}
          style={{
            width: '100%',
            maxWidth: isMobile ? '100%' : 400,
          }}
          autoClose={2500}
          closeButton={CloseButton}
        />
      </ErrorBoundary>
    </StyledEngineProvider>
  );
}
