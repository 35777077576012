import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import LoadingIndicator from 'components/LoadingIndicator';
import { auth, requestNotificationPermission } from 'config/firebase';
import { User as FirebaseUser } from 'firebase/auth';
import {
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Outlet } from 'react-router-dom';
import mylaClient from 'shared/api/myla';
import { API_PATHS } from 'shared/constants';
import { useLazyQuery } from 'shared/hooks/useLazyQuery';
import { User, UserRole } from 'shared/models/user.model';
import { useServiceWorkerContext } from './serviceWorker';

export const USER_PROFILE_QUERY_KEY = 'auth/USER_PROFILE';
export const FIREBASE_TOKEN_QUERY_KEY = 'auth/FIREBASE_TOKEN';

export interface UserWithRole extends User {
  isAdmin: boolean;
}

const AuthContext = createContext<{
  user: UserWithRole | null | undefined;
  signOut: () => void;
  isPushNotificationSupported?: boolean;
}>({
  user: undefined,
  signOut: () => {},
});

export const useAuth = () => useContext(AuthContext);

function AuthProvider(): ReactElement {
  const [firebaseUser, setFirebaseUser] = useState<
    FirebaseUser | null | undefined
  >(undefined);
  const queryClient = useQueryClient();
  const { registration } = useServiceWorkerContext();
  const [isPushNotificationSupported, setIsPushNotificationSupported] =
    useState(false);

  const { data: userData, lazyQuery: lazyFetchUserProfile } =
    useLazyQuery<User | null>({
      queryKey: [USER_PROFILE_QUERY_KEY],
      fetcher: async () =>
        mylaClient
          .get<User>(`${API_PATHS.USERS}/me`)
          .then((response) => response.data),
    });

  useEffect(() => {
    if (userData && registration) {
      requestNotificationPermission(registration).then((isSupported) =>
        setIsPushNotificationSupported(isSupported)
      );
    }
  }, [userData, registration]);

  useQuery<string | null, AxiosError>({
    retry: false,
    suspense: true,
    keepPreviousData: true,
    enabled: !!firebaseUser,
    queryKey: [FIREBASE_TOKEN_QUERY_KEY],
    queryFn: async () => {
      const currentFirebaseToken = await auth.currentUser
        ?.getIdToken(true)
        .catch(() => null);
      if (currentFirebaseToken) {
        mylaClient.defaults.headers.common.Authorization = currentFirebaseToken;
        lazyFetchUserProfile();
        return currentFirebaseToken;
      }

      return null;
    },
    refetchOnWindowFocus: true,
    refetchInterval: 1000 * 60 * 30, // 30 minutes,
    staleTime: 1000 * 60 * 30,
    useErrorBoundary: (err) => {
      return !!err.status && err.status >= 500;
    },
  });

  useEffect(() => {
    return auth.onAuthStateChanged((currentUser) =>
      setFirebaseUser(currentUser)
    );
  }, []);

  const contextValue = useMemo(() => {
    if (userData) {
      return {
        signOut: async () => {
          await auth.signOut();
          await queryClient.setQueriesData(
            [USER_PROFILE_QUERY_KEY],
            () => null
          );
        },
        user: userData
          ? {
              ...userData,
              isAdmin: userData?.role === UserRole.Admin,
            }
          : null,
        isPushNotificationSupported,
      };
    }

    return {
      user: null,
      signOut: () => {},
      isPushNotificationSupported: false,
    };
  }, [userData, isPushNotificationSupported, queryClient]);

  if (firebaseUser === undefined || (firebaseUser !== null && !userData)) {
    return <LoadingIndicator />;
  }

  return (
    <AuthContext.Provider value={contextValue}>
      <Outlet />
    </AuthContext.Provider>
  );
}

export default AuthProvider;
