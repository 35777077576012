// Soft UI Dashboard PRO React Base Styles

import colors from 'shared/theme/base/colors';

import typography from 'shared/theme/base/typography';

import borders from 'shared/theme/base/borders';

// Soft UI Dashboard PRO helper functions

import pxToRem from 'shared/theme/functions/pxToRem';

const { dark, white, grey, inputColors } = colors;
const { size, fontWeightRegular } = typography;
const { borderWidth, borderRadius } = borders;

export default {
  styleOverrides: {},
} as const;
