import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import {
  getListOrders,
  getOrderBooking,
  updateOrderBooking,
} from 'apis/order.api';
import { ApiError, ApiResponseList } from 'interfaces/api-response.interface';
import { PageOptions } from 'interfaces/page-options.interface';
import {
  OrderBooking,
  UpdateOrderBooking,
} from 'shared/models/orderBooking.model';
import useMylaQuery, {
  UseMylaQueryOptions,
} from 'shared/packages/myla-query/hooks/useQuery';

export const ORDER_QUERY_KEY = {
  GET_ORDER_BOOKING: 'GET_ORDER_BOOKING',
  GET_LIST_ORDER_BOOKING: 'GET_LIST_ORDER_BOOKING',
};

export const useGetOrderBooking = (id: string, option: PageOptions) =>
  useQuery<OrderBooking>({
    queryKey: [ORDER_QUERY_KEY.GET_ORDER_BOOKING, id],
    queryFn: () => getOrderBooking(id, option),
    refetchOnWindowFocus: false,
    enabled: !!id,
  });

export const useGetListOrderBooking = (
  pageOption: PageOptions,
  options: UseMylaQueryOptions<
    ApiResponseList<OrderBooking>,
    ApiError,
    ApiResponseList<OrderBooking>
  >
) =>
  useMylaQuery<
    ApiResponseList<OrderBooking>,
    ApiError,
    ApiResponseList<OrderBooking>
  >({
    ...options,
    queryKey: [ORDER_QUERY_KEY.GET_LIST_ORDER_BOOKING, pageOption],
    queryFn: () => getListOrders(pageOption),
    enabled: options.enabled,
  });

export const useUpdateOrderBooking = (
  options: Omit<
    UseMutationOptions<OrderBooking, ApiError, UpdateOrderBooking, unknown>,
    'mutationFn'
  >
) =>
  useMutation<OrderBooking, ApiError, UpdateOrderBooking>({
    ...options,
    mutationFn: (values) => updateOrderBooking(values),
  });
