import { forwardRef } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

interface CustomProps {
  onChange: (event: {
    target: {
      name: string;
      value: number;
    };
  }) => void;
  name: string;
  value: number;
}

// #TODO: Replace this component with NumericFormatInput component
const FloatFormatInput = forwardRef<NumericFormatProps, CustomProps>(
  (props, ref) => {
    const { onChange, value, ...other } = props;

    return (
      <NumericFormat
        {...other}
        value={value}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.floatValue || 0,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        displayType="input"
        allowLeadingZeros={false}
      />
    );
  }
);

export default FloatFormatInput;
