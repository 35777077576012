import SyncIcon from '@mui/icons-material/Sync';
import {
  Box,
  Unstable_Grid2 as Grid2,
  Typography,
  keyframes,
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import EmptyState from 'components/EmptyState';
import ErrorSlate from 'components/ErrorSlate';
import { Loader } from 'components/LoadingIndicator';
import SuiButton from 'components/SuiButton';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import mylaClient, { PaginatedResponse } from 'shared/api/myla';
import { useCustomBreadcrumb } from 'shared/atoms/breadcrumbs';
import { useMutation } from 'shared/packages/myla-query';
import { Device } from 'shared/models/device.model';
import { ITTLockDevice } from 'shared/models';
import { getListDevice } from 'apis/ttlock.api';
import { ApiError } from 'interfaces/api-response.interface';
import { QUERY_KEYS } from '../../constants';
import LockCard from '../shared/LockCard';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

function LockBoard() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data, error, isLoading } = useQuery<
    PaginatedResponse<Device<ITTLockDevice>>,
    ApiError
  >({
    queryKey: [QUERY_KEYS.LOCK_LIST],
    queryFn: () => getListDevice(),
    onError: () => {
      navigate('/lock-management/authenticate');
    },
  });

  const { isLoading: isSyncingDevices, mutate: syncDevices } = useMutation({
    mutationFn: () => mylaClient.get('/user-devices/sync-device'),
    onSuccess: () => queryClient.invalidateQueries([QUERY_KEYS.LOCK_LIST]),
    notify: {
      success: 'Đồng bộ thiết bị thành công!',
      error: 'Đồng bộ thất bại, vui lòng thử lại sau.',
    },
  });

  const isDataReady = !isLoading && (data || error);

  useCustomBreadcrumb(
    useMemo(
      () => ({
        'lock-management': 'Quản lý nhóm bán hàng',
      }),
      []
    )
  );

  if (!isDataReady) {
    return <Loader />;
  }

  if (!data) {
    return <ErrorSlate />;
  }

  return (
    <>
      <Box
        mb={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h5" sx={{ m: 0 }}>
          Danh sách khoá phòng
        </Typography>
        <SuiButton
          startIcon={
            <SyncIcon
              sx={{
                animation: isSyncingDevices
                  ? `${spin} 1s infinite ease`
                  : undefined,
              }}
            />
          }
          onClick={() => {
            if (!isSyncingDevices) {
              syncDevices();
            }
          }}
          buttonColor="dark"
          variant="gradient"
          size="small"
        >
          Đồng bộ
        </SuiButton>
      </Box>
      <Grid2 container>
        {data.data.map((item) => (
          <Grid2 key={item.deviceId} xs={12} md={6} lg={6} xl={4}>
            <LockCard {...item} />
          </Grid2>
        ))}
      </Grid2>
      {!data.data?.length && (
        <EmptyState
          variant="document"
          title="Bạn chưa có khoá được kết nối"
          subTitle="Kết nối khoá để tối ưu hoá thao tác quản lý phòng của bạn"
          cta={{
            text: 'Liên hệ Bookdee',
            onClick: () =>
              window.open(
                'https://www.facebook.com/MylaHomestayVungtau',
                '_blank'
              ),
          }}
        />
      )}
    </>
  );
}

export default LockBoard;
