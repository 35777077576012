import { captureException } from '@sentry/react';
import axios, { AxiosError } from 'axios';

export interface PaginatedResponse<TData> {
  data: TData[];
  meta: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    itemCount: number;
    page: number;
    pageCount: number;
    take: number;
  };
}

const { VITE_MYLA_ENDPOINT } = import.meta.env;

const mylaClient = axios.create({
  baseURL: `${VITE_MYLA_ENDPOINT}`,
  headers: { 'Content-Type': 'application/json' },
});

mylaClient.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error: AxiosError) => {
    captureException(error, {
      extra: {
        response: error.response?.data,
        request: error.request,
        status: error.status,
      },
    });
    return Promise.reject(error);
  }
);

export default mylaClient;
