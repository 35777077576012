// Soft UI Dashboard PRO React helper functions

import pxToRem from 'shared/theme/functions/pxToRem';

// Soft UI Dashboard PRO React base styles

import colors from 'shared/theme/base/colors';

import boxShadows from 'shared/theme/base/boxShadows';

import borders from 'shared/theme/base/borders';

const { transparent } = colors;
const { lg } = boxShadows;
const { borderRadius } = borders;

export default {
  styleOverrides: {
    paper: {
      backgroundColor: transparent.main,
      boxShadow: lg,
      padding: pxToRem(8),
      borderRadius: borderRadius.lg,
    },
  },
};
