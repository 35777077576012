import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { NotifyConfigs, NotifyStatus } from '../types';
import { useNotifyToast } from './useNotifyToast';

export interface UseMylaMutationOptions<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
> extends UseMutationOptions<TData, TError, TVariables, TContext> {
  notify?: NotifyConfigs<TData, TError>;
}

const useMylaMutation = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
>({
  onSuccess,
  onError,
  mutationFn,
  notify = {},
  ...options
}: UseMylaMutationOptions<TData, TError, TVariables, TContext>) => {
  const { showToast } = useNotifyToast();
  const { success, error, loading } = notify;

  return useMutation<TData, TError, TVariables, TContext>({
    ...options,
    mutationFn: mutationFn
      ? (...params) => {
          if (loading) {
            showToast({
              notifyStatus: NotifyStatus.LOADING,
              toastContent:
                typeof loading === 'string' ? { message: loading } : loading(),
            });
          }
          return mutationFn(...params);
        }
      : undefined,
    onError: (errorResponse, ...restErrorParams) => {
      onError?.(errorResponse, ...restErrorParams);
      if (error) {
        showToast({
          notifyStatus: NotifyStatus.ERROR,
          toastContent:
            typeof error === 'string'
              ? { message: error }
              : error(errorResponse),
        });
      }
    },
    onSuccess: (data, ...restSuccessParams) => {
      onSuccess?.(data, ...restSuccessParams);
      if (success) {
        showToast({
          notifyStatus: NotifyStatus.SUCCESS,
          toastContent:
            typeof success === 'string' ? { message: success } : success(data),
        });
      }
    },
  });
};

export default useMylaMutation;
