// @mui material components
import { makeStyles } from '@mui/styles';

export default makeStyles(({ palette, borders, transitions, functions }) => {
  const { light, secondary } = palette;
  const { borderRadius } = borders;
  const { pxToRem } = functions;

  return {
    notificationItem: {
      display: 'flex',
      alignItems: 'flex-start',
      color: secondary.main,
      columnGap: '8px',
      padding: `${pxToRem(8)} ${pxToRem(16)}`,
      transition: transitions.create('background-color', {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),

      '&:hover': {
        backgroundColor: light.main,
      },
    },

    notificationItem_content: {
      flex: 1,
    },

    notificationItem_img: {
      display: 'grid',
      placeItems: 'center',
      width: pxToRem(36),
      height: pxToRem(36),
      borderRadius: borderRadius.lg,
      '& img': {
        width: '100%',
        borderRadius: borderRadius.lg,
      },
    },

    notificationItem_date: {
      display: 'flex',
      alignItems: 'center',
      marginTop: pxToRem(4),
    },

    notificationItem_icon: {
      lineHeight: 1.2,
      marginRight: pxToRem(4),
    },
  };
});
