import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { IconButton, useTheme } from '@mui/material';

export interface CloseButtonProps {
  closeToast: (e: React.MouseEvent<HTMLElement>) => void;
}

export default function CloseButton({ closeToast }: CloseButtonProps) {
  const theme = useTheme();

  return (
    <IconButton
      onClick={closeToast}
      size="small"
      sx={{
        width: theme.spacing(5),
        height: theme.spacing(5),
        alignSelf: 'center',
      }}
    >
      <CloseRoundedIcon fontSize="small" />
    </IconButton>
  );
}
