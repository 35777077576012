// Soft UI Dashboard PRO React Base Styles

import colors from 'shared/theme/base/colors';

import borders from 'shared/theme/base/borders';

import boxShadows from 'shared/theme/base/boxShadows';

// Soft UI Dashboard PRO React Helper Function

import rgba from 'shared/theme/functions/rgba';

const { black, white } = colors;
const { borderWidth, borderRadius } = borders;
const { xxl } = boxShadows;

export default {
  styleOverrides: {
    root: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      minWidth: 0,
      wordWrap: 'break-word',
      backgroundColor: white.main,
      backgroundClip: 'border-box',
      border: `${borderWidth[0]} solid ${rgba(black.main, 0.125)}`,
      borderRadius: borderRadius.xl,
      boxShadow: xxl,
    },
  },
} as const;
