import { Asset } from './asset.model';
import { Policy } from './policy.model';
import { Room } from './room.model';
import { SellingKey } from './sellingKey.model';
import { Utility } from './utility.model';

export interface UtilityUnit {
  _id: string;
  quantity: number;
  utility: Utility;
}

export enum UnitType {
  VILLA = 'villa',
  HOME_STAY = 'homestay',
  HOTEL = 'hotel',
  APARTMENT = 'apartment',
}

export interface Unit {
  thumbnail: Asset;
  listImage: Asset[];
  address: string;
  googleMapUrl: string;
  isFullHouse: boolean;
  inUsedCapacities: number[];
  distributor: string;
  distributorId: string;
  policies: Policy[];
  utilities: UtilityUnit[];
  createdAt: string;
  name: string;
  _id: string;
  desc: string;
  rooms: Room[];
  sellingKeys: SellingKey[];
  updatedAt: string;
  enabled?: boolean;
  shortName?: string;
  minPrice?: string;
  minCapacity?: number;
  maxCapacity?: number;
  slug?: string;
  phone?: number;
  zaloUrl?: string;
  imageFolderUrl?: string;
  unitType: UnitType;
}
