// Soft UI Dashboard PRO React components
import SuiBox, { SuiBoxProps } from 'components/SuiBox';

// Soft UI Dashboard PRO React context
import { useSoftUIController } from 'context/softUI';

// Custom styles for the SidenavCollapse
import styles from 'examples/Sidenav/styles/sidenavCollapse';
// prop-types is a library for typechecking of props.
import React, { PropsWithChildren, ReactElement } from 'react';

import {
  Collapse,
  Icon,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

interface SidenavCollapseProps extends Omit<SuiBoxProps, 'ref'> {
  icon?: string | ReactElement;
  name: string;
  active?: boolean;
  open?: boolean;
}

function SidenavCollapse({
  icon,
  name,
  children,
  active = false,
  open = false,
  ...rest
}: PropsWithChildren<SidenavCollapseProps>) {
  const [controller] = useSoftUIController();
  const { miniSidenav, transparentSidenav, sidenavColor } = controller;

  const classes = styles({
    active,
    open,
    miniSidenav,
    transparentSidenav,
    sidenavColor,
    noCollapse: !children,
  });

  return (
    <>
      <ListItem component="li">
        <SuiBox {...rest} customClass={classes.collapse_item}>
          <ListItemIcon className={classes.collapse_iconBox}>
            {typeof icon === 'string' ? (
              <Icon className={classes.collapse_icon}>{icon}</Icon>
            ) : (
              icon
            )}
          </ListItemIcon>

          <ListItemText
            primary={name}
            classes={{ root: classes.collapse_text }}
          />

          <Icon className={classes.collapse_arrow}>expand_less</Icon>
        </SuiBox>
      </ListItem>
      {children && (
        <Collapse unmountOnExit in={open}>
          {children}
        </Collapse>
      )}
    </>
  );
}
export default SidenavCollapse;
