import AddIcon from '@mui/icons-material/Add';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SensorDoorOutlinedIcon from '@mui/icons-material/SensorDoorOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import {
  Unstable_Grid2 as Grid2,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import {
  BILL_QUERY_KEY,
  useGetOrderBill,
  useUpdateOrderBill,
} from 'apis-query/bill.query';
import { ORDER_QUERY_KEY } from 'apis-query/orderBooking.query';
import FormikInput from 'components/Formik/FormikInput';
import NumericFormatInput from 'components/NumericFormatInput';
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';
import SuiModal from 'components/SuiModal';
import SuiTypography from 'components/SuiTypography';
import { Formik, FormikProps } from 'formik';
import { queryClient } from 'queryClient';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { OrderBill, ServiceCharge } from 'shared/models/orderBill.model';
import pxToRem from 'shared/theme/functions/pxToRem';
import { currencyFormat } from 'utils/currencyFormat';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import useCalculatorBill from '../../hooks/useCalculatorBill';
import displayPeriodDay from '../BookingForm/helpers';
import FormErrors from '../FormErrors';

const orderBillFormValidationSchema = yup.object().shape({
  name: yup.string().required('Tên chi phí phát sinh bắt buộc'),
  value: yup.number().required('Chi phí không được để trống'),
});

interface OrderBillProps {
  onClose: () => void;
  orderId: string;
  open?: boolean;
}

export default function OrderBillForm({
  orderId,
  onClose,
  open = true,
}: OrderBillProps) {
  const theme = useTheme();
  const formikRef = useRef<FormikProps<any> | null>(null);
  const [bill, setBill] = useState<OrderBill>();
  const [isAddServiceCharge, setIsAddServiceCharge] = useState(false);
  const { data: orderBill, isLoading } = useGetOrderBill(orderId, {
    populate:
      'distributor,customer,unit,order,order.childRoom,order.childRoom.room,booking',
  });
  const billCalculator = useCalculatorBill({
    bills: bill ? [bill] : [],
    booking: orderBill?.booking,
  });

  const {
    mutateAsync: updateOrderBillMutation,
    isLoading: isLoadingUpdate,
    error: updateOrderBillError,
  } = useUpdateOrderBill({
    onSuccess: async () => {
      await queryClient.invalidateQueries([
        BILL_QUERY_KEY.GET_ORDER_BILL,
        orderId,
      ]);
      await queryClient.invalidateQueries([
        BILL_QUERY_KEY.GET_BOOKING_BILL,
        bill?.booking?._id,
      ]);
      await queryClient.invalidateQueries([
        ORDER_QUERY_KEY.GET_ORDER_BOOKING,
        orderId,
      ]);
    },
  });

  const [serviceChargeSelected, setServiceChargeSelected] =
    useState<ServiceCharge>();

  const initFormValues = useMemo(
    () => ({
      _id: serviceChargeSelected?._id || '',
      name: serviceChargeSelected?.name || '',
      value: serviceChargeSelected?.value || 0,
    }),
    [serviceChargeSelected]
  );

  const suiModalConfig = useMemo(() => {
    return {
      unitName: orderBill?.unit?.name || '',
      distributorName: orderBill?.distributor?.name || '',
      title: 'Chi phí phòng',
      confirmText: `Lưu cập nhật`,
      cancelText: `Về trang trước`,
    };
  }, [orderBill?.distributor?.name, orderBill?.unit?.name]);

  const handleBackToBooking = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    onClose && onClose();
  }, [onClose]);

  const handleUpdateBill = useCallback(async () => {
    if (!bill || !bill?._id || !Array.isArray(bill?.serviceCharge)) return;

    await updateOrderBillMutation({
      _id: bill?._id,
      serviceCharge: bill?.serviceCharge,
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    onClose && onClose();
  }, [bill, updateOrderBillMutation, onClose]);

  const onSubmit = useCallback(
    async (values: ServiceCharge) => {
      if (bill) {
        const findIndex = bill.serviceCharge.findIndex(
          (s) => s._id === values?._id
        );
        if (findIndex === -1) {
          setBill({
            ...bill,
            serviceCharge: [
              ...bill.serviceCharge,
              { ...values, _id: uuidv4() },
            ],
          });
        } else {
          const newServiceCharge = bill.serviceCharge;
          newServiceCharge[findIndex] = values;
          setBill({
            ...bill,
            serviceCharge: newServiceCharge,
          });
        }
      }
      setServiceChargeSelected(undefined);
      formikRef?.current?.resetForm();
    },
    [bill]
  );

  const handleRemoveServiceCharge = useCallback(
    (serviceCharge: ServiceCharge) => {
      if (bill) {
        setBill({
          ...bill,
          serviceCharge: bill.serviceCharge.filter(
            (s) => s._id !== serviceCharge._id
          ),
        });
      }
    },
    [bill]
  );

  const totalAmount = useMemo(() => {
    return bill?.totalAmount
      ? currencyFormat(bill.totalAmount + billCalculator.totalServiceCharge)
      : 0;
  }, [bill, billCalculator.totalServiceCharge]);

  useEffect(() => {
    if (orderBill) setBill(orderBill);
  }, [orderBill]);

  return (
    <SuiModal
      open={open}
      loading={isLoading || isLoadingUpdate}
      onClose={onClose}
      sx={{
        h4: {
          width: '100%',
          paddingLeft: '1.5rem',
        },
      }}
      confirmText={suiModalConfig.confirmText}
      onConfirm={() => handleUpdateBill()}
      cancelText={suiModalConfig.cancelText}
      backText="Về trang trước"
      onBack={handleBackToBooking}
      showCloseIcon={false}
      title={
        <SuiBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          px={3}
        >
          <Typography
            sx={{
              fontSize: {
                xs: '1.2rem',
                sm: '1.6rem',
                md: '2rem',
              },
            }}
            fontWeight="bold"
          >
            {suiModalConfig.title}
          </Typography>
        </SuiBox>
      }
      desktopHeight="80dvh"
      desktopWidth={theme.spacing(100)}
    >
      <SuiBox px={3}>
        <FormErrors
          sx={{ mb: 2 }}
          title="Cập nhật chi phí phòng không thành công"
          error={updateOrderBillError}
        />
        <SuiBox
          mt={1}
          mb={1.5}
          display="flex"
          flexWrap={{
            xs: 'wrap',
          }}
          columnGap={{
            xs: 1,
            sm: 2,
            md: pxToRem(29),
          }}
          rowGap={2}
        >
          <SuiBox display="flex" alignItems="center">
            <SuiBox mr={pxToRem(9)} display="flex" alignItems="center">
              <SensorDoorOutlinedIcon />
            </SuiBox>
            <SuiTypography variant="h5" fontWeight="regular" fontSize="1rem">
              Phòng: {orderBill?.order?.childRoom?.room?.name}
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" alignItems="center">
            <SuiBox mr={pxToRem(9)} display="flex" alignItems="center">
              <CalendarTodayIcon />
            </SuiBox>
            <SuiTypography variant="h5" fontWeight="regular" fontSize="1rem">
              {displayPeriodDay(
                orderBill?.order?.checkInDate,
                orderBill?.order?.checkOutDate
              )}
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" alignItems="center">
            <SuiBox mr={pxToRem(9)} display="flex" alignItems="center">
              <SupervisorAccountOutlinedIcon />
            </SuiBox>
            <SuiTypography variant="h5" fontWeight="regular" fontSize="1rem">
              {orderBill?.booking?.numberOfGuests} khách
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox
          sx={{
            borderTop: '1px dashed #BDBDBD',
          }}
          pt={1.5}
        />
        <SuiBox mb={1.5}>
          <List
            sx={{
              width: '100%',
              'li:not(:last-child)': {
                marginBottom: '0.75rem',
              },
              span: {
                fontSize: '1rem',
              },
              'li div:nth-of-type(2)': {
                textAlign: 'right',
                '> span': {},
              },
            }}
          >
            {bill?.items?.map((item) => (
              <ListItem key={`${item.name}_${item.value}_${item?._id}`}>
                <ListItemText>{item?.name}</ListItemText>
                <ListItemText
                  sx={{
                    span: {
                      fontWeight: 600,
                    },
                  }}
                >
                  {currencyFormat(item?.value)}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </SuiBox>
        <SuiBox>
          <List
            sx={{
              width: '100%',
              'li:not(:last-child)': {
                marginBottom: '0.75rem',
              },
              span: {
                fontSize: '1rem',
              },
              'li div:nth-of-type(2)': {
                textAlign: 'right',
              },
            }}
          >
            {bill?.serviceCharge?.map((serviceCharge) => (
              <ListItem
                key={`${serviceCharge.name}_${serviceCharge.value}_${serviceCharge?._id}`}
              >
                <ListItemText>{serviceCharge?.name}</ListItemText>
                <ListItemText
                  sx={{
                    position: 'relative',
                    span: {
                      fontWeight: 600,
                    },
                  }}
                >
                  {currencyFormat(serviceCharge?.value)}
                  <DeleteOutlineIcon
                    fontSize="medium"
                    sx={{
                      position: 'absolute',
                      top: '45%',
                      transform: 'translateY(-50%)',
                      left: '100%',
                      cursor: 'pointer',
                    }}
                    color="info"
                    onClick={() => handleRemoveServiceCharge(serviceCharge)}
                  />
                </ListItemText>
              </ListItem>
            ))}
          </List>
          {isAddServiceCharge && (
            <SuiBox>
              <SuiBox
                my={2}
                sx={{
                  borderTop: '1px dashed #BDBDBD',
                }}
              />
              <Formik
                enableReinitialize
                onSubmit={onSubmit}
                initialValues={initFormValues}
                innerRef={formikRef}
                validationSchema={orderBillFormValidationSchema}
              >
                <Grid2
                  container
                  width="100%"
                  rowSpacing={{ xs: 1, md: 2 }}
                  columnSpacing={{ xs: 1, md: 2 }}
                  m={0}
                  mb={1}
                >
                  <Grid2 xs={6}>
                    <FormikInput
                      name="name"
                      placeholder="Nhập tên chi phí"
                      label="Tên chi phí"
                    />
                  </Grid2>
                  <Grid2 xs={6}>
                    <FormikInput
                      name="value"
                      placeholder="Nhập chi phí phát sinh"
                      label="Chi phí phát sinh"
                      inputComponent={NumericFormatInput as any}
                      inputProps={{
                        allowLeadingZeros: false,
                        isAllowed: (values: any) => {
                          const { floatValue, formattedValue } = values;
                          return (
                            formattedValue === '' ||
                            floatValue <= Number.MAX_SAFE_INTEGER
                          );
                        },
                        inputMode: 'decimal',
                      }}
                    />
                  </Grid2>
                </Grid2>
              </Formik>
              <SuiBox
                px={1}
                mb={2}
                display="flex"
                columnGap={2}
                justifyContent="flex-end"
              >
                <SuiButton
                  onClick={() => {
                    setServiceChargeSelected(undefined);
                    setIsAddServiceCharge(false);
                  }}
                  variant="outlined"
                  buttonColor="secondary"
                >
                  Huỷ
                </SuiButton>
                <SuiButton
                  onClick={() => {
                    formikRef.current?.submitForm();
                  }}
                  variant="contained"
                  buttonColor="info"
                >
                  Thêm
                </SuiButton>
              </SuiBox>
            </SuiBox>
          )}
          {!isAddServiceCharge && (
            <>
              <SuiBox mt={2.5}>
                <SuiButton
                  variant="text"
                  buttonColor="info"
                  fullWidth
                  sx={{
                    border: '1px dashed #2F80ED',
                    padding: '0.75rem 0',
                  }}
                  startIcon={<AddIcon />}
                  onClick={() => setIsAddServiceCharge((prev) => !prev)}
                >
                  Chi phí phát sinh
                </SuiButton>
              </SuiBox>
              <List
                sx={{
                  marginTop: 3,
                  width: '100%',
                  'li:not(:last-child)': {
                    marginBottom: '1rem',
                  },
                  span: {
                    fontSize: '1rem',
                  },
                  'li div:nth-of-type(2)': {
                    textAlign: 'right',
                  },
                }}
              >
                <ListItem>
                  <ListItemText>Tổng tiền</ListItemText>
                  <ListItemText
                    sx={{
                      span: {
                        fontWeight: 600,
                      },
                    }}
                  >
                    {currencyFormat(billCalculator.totalMustPay)}
                  </ListItemText>
                </ListItem>
              </List>
            </>
          )}
        </SuiBox>
        <SuiBox
          my={2.5}
          sx={{
            border: '1px dashed #BDBDBD',
          }}
        />
        <List
          sx={{
            width: '100%',
            'li:not(:last-child)': {
              marginBottom: '1rem',
            },

            span: {
              fontSize: {
                xs: '0.8rem',
                sm: '0.9rem',
                md: '1rem',
              },
              fontWeight: 400,
            },
            'li div:nth-of-type(2)': {
              textAlign: 'right',
            },
            'li:last-child div:nth-of-type(2)': {
              color: theme.palette.success.main,
            },
          }}
        />
        <List
          sx={{
            width: '100%',
            'li:not(:last-child)': {
              marginBottom: '1rem',
            },
            span: {
              fontSize: '1rem',
              fontWeight: 600,
            },
            'li div:nth-of-type(2)': {
              textAlign: 'right',
            },
            'li:last-child div:nth-of-type(2)': {
              color: theme.palette.success.main,
            },
          }}
        >
          <ListItem>
            <ListItemText>KHÁCH CẦN THANH TOÁN</ListItemText>
            <ListItemText
              sx={{
                span: {
                  fontWeight: 600,
                },
              }}
            >
              {totalAmount}
            </ListItemText>
          </ListItem>
        </List>
      </SuiBox>
    </SuiModal>
  );
}
