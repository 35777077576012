// Soft UI Dashboard PRO React base styles

import colors from 'shared/theme/base/colors';

import borders from 'shared/theme/base/borders';

import boxShadows from 'shared/theme/base/boxShadows';

// Soft UI Dashboard PRO React helper functions

import rgba from 'shared/theme/functions/rgba';

import pxToRem from 'shared/theme/functions/pxToRem';

import linearGradient from 'shared/theme/functions/linearGradient';

const { white, light, gradients } = colors;
const { borderWidth } = borders;
const { regular } = boxShadows;

export default {
  defaultProps: {
    disableRipple: true,
  },

  styleOverrides: {
    root: {
      width: pxToRem(40),
      height: pxToRem(20),
      margin: `${pxToRem(4)} 0`,
      padding: 0,
      borderRadius: pxToRem(160),
      transition: 'transform 250ms ease-in',
    },

    switchBase: {
      padding: 0,
      top: '50%',
      transform: `translate(${pxToRem(2)}, -50%)`,
      transition: `transform 250ms ease-in-out`,

      '&.Mui-checked': {
        transform: `translate(${pxToRem(22)}, -50%)`,

        '& + .MuiSwitch-track': {
          backgroundColor: `${rgba(gradients.dark.state, 0.95)} !important`,
          borderColor: `${rgba(gradients.dark.state, 0.95)} !important`,
          opacity: 1,
        },
      },

      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: '0.3 !important',
      },

      '&.Mui-focusVisible .MuiSwitch-thumb': {
        backgroundImage: linearGradient(
          gradients.info.main,
          gradients.info.state
        ),
      },
    },

    thumb: {
      width: pxToRem(16),
      height: pxToRem(16),
      backgroundColor: white.main,
      boxShadow: regular,
      top: '50%',
    },

    track: {
      backgroundColor: rgba(gradients.dark.state, 0.1),
      border: `${borderWidth[1]} solid ${light.main}`,
      borderRadius: pxToRem(160),
      opacity: 1,
      transition: `background-color 250ms ease, border-color 200ms ease`,
    },

    checked: {},
  },
};
