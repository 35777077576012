import { PrintRounded } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import PinDropIcon from '@mui/icons-material/PinDrop';
import {
  CircularProgress,
  Unstable_Grid2 as Grid2,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useGetBillByBooking } from 'apis-query/bill.query';
import { BOOING_QUERY_KEY } from 'apis-query/booking.query';
import { updateBooking } from 'apis/booking.api';
import FormikInput from 'components/Formik/FormikInput';
import NumericFormatInput from 'components/NumericFormatInput';
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';
import SuiModal from 'components/SuiModal';
import SuiTypography from 'components/SuiTypography';
import { Form, Formik, FormikProps } from 'formik';
import { ApiError } from 'interfaces/api-response.interface';
import { queryClient } from 'queryClient';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NumberFormatValues } from 'react-number-format';
import { BookingStatus, OrderStatus } from 'shared/enums';
import { Booking, UpdateBooking } from 'shared/models/booking.model';
import { ServiceCharge } from 'shared/models/orderBill.model';
import { Unit } from 'shared/models/unit.model';
import { UnitWithDistributor } from 'shared/models/unitWithDistributor.model';
import useMylaMutation from 'shared/packages/myla-query/hooks/useMutation';
import pxToRem from 'shared/theme/functions/pxToRem';
import { currencyFormat } from 'utils/currencyFormat';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import {
  ALLOW_FOR_STATUSES,
  ScreenActiveEnum,
  WAITING_FOR_STATUSES,
} from '../../constants';
import useCalculatorBill from '../../hooks/useCalculatorBill';
import usePrintBill from '../../hooks/usePrintBill';
import ListChildRoom from '../BookingForm/ListChildRoom';
import NumericFormatInputWithTotal from '../BookingForm/NumericFormatInputWithTotal';
import displayPeriodDay from '../BookingForm/helpers';
import FormErrors from '../FormErrors';

const bookingFormValidationSchema = yup.object().shape({
  priceOrder: yup.object().shape({
    deposit: yup.number().required('Tiền cọc không được để trống'),
    totalPrice: yup.number().required('Tổng giá không được để trống'),
  }),
});

interface BookingFormProps {
  booking: Partial<
    Omit<Booking, 'unit'> & { unit: Unit | UnitWithDistributor }
  >;
  open: boolean;
  onClose: () => void;
}
const serviceChargeValidate = yup.object().shape({
  name: yup.string().required('Tên chi phí phát sinh bắt buộc'),
  value: yup.number().required('Chi phí không được để trống'),
});

export default function BookingBill({
  booking,
  open = false,
  onClose,
}: BookingFormProps) {
  const theme = useTheme();
  const [bookingServiceCharge, setBookingServiceCharge] =
    useState<
      Partial<Omit<Booking, 'unit'> & { unit: Unit | UnitWithDistributor }>
    >(booking);
  const formikRef = useRef<FormikProps<any> | null>(null);
  const formikServiceChargeRef = useRef<FormikProps<any> | null>(null);
  const [isAddServiceCharge, setIsAddServiceCharge] = useState(false);
  const initFormServiceChargeValues = {
    _id: '',
    name: '',
    value: 0,
  };
  const {
    data: bookingBillTotal,
    isLoading: isLoadingBillBooking,
    isFetching,
  } = useGetBillByBooking(booking._id || '', {
    populate: 'order,order.childRoom,order.childRoom.room',
  });

  const bookingBill = useMemo(() => {
    return (
      bookingBillTotal?.filter(
        (bill) => bill.order?.status !== OrderStatus.CANCELLED
      ) || []
    );
  }, [bookingBillTotal]);

  const billCalculator = useCalculatorBill({
    bills: bookingBill,
    booking: bookingServiceCharge,
  });

  const {
    mutate: updateBookingMutation,
    isLoading: isLoadingUpdate,
    error: updateBookingError,
  } = useMylaMutation<Booking, ApiError, UpdateBooking, unknown>({
    mutationFn: (params) => updateBooking(params),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [BOOING_QUERY_KEY.GET_BOOKING, booking?._id],
      });
    },
    notify: {
      success: 'Cập nhật booking thành công',
      error: 'Cập nhật booking thất bại',
    },
  });

  const onSubmit = useCallback(
    async (values: any) => {
      if (booking?._id) {
        updateBookingMutation({
          _id: booking._id || '',
          notes: values.notes,
          numberOfGuests: values.numberOfGuests,
          deposit: values.priceOrder.deposit,
          serviceCharge: bookingServiceCharge?.serviceCharge || [],
          discount: values.discount,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [booking?._id, bookingServiceCharge?.serviceCharge]
  );

  const initFormValues = useMemo(
    () => ({
      _id: booking?._id,
      customerName: booking?.customer?.name || '',
      customerPhone: booking?.customer?.phone || '',
      numberOfGuests: booking?.numberOfGuests,
      dateRange: displayPeriodDay(
        booking?.expectCheckInDate,
        booking?.expectCheckOutDate
      ),
      priceOrder: {
        deposit: bookingServiceCharge?.deposit || 0,
        totalPrice: billCalculator.totalAfterDiscount || 0,
      },
      notes: booking?.notes || '',
      discount: booking?.discount || 0,
    }),
    [
      booking?._id,
      booking?.customer?.name,
      booking?.customer?.phone,
      booking?.numberOfGuests,
      booking?.expectCheckInDate,
      booking?.expectCheckOutDate,
      bookingServiceCharge?.deposit,
      booking?.notes,
      booking?.discount,
      billCalculator.totalAfterDiscount,
    ]
  );
  const suiModalConfig = useMemo(() => {
    return {
      unitName: booking?.unit?.name || '',
      distributorName: booking?.distributor?.name || '',
      title: `Tổng chi phí booking`,
      confirmText: `Lưu cập nhật`,
      cancelText: `Về trang trước`,
    };
  }, [booking?.distributor?.name, booking?.unit?.name]);

  const handleBackToBooking = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    onClose && onClose();
  }, [onClose]);

  const onSubmitServiceCharge = useCallback(
    async (values: ServiceCharge) => {
      if (bookingServiceCharge) {
        const newServiceCharge = [
          ...(bookingServiceCharge?.serviceCharge || []),
          { ...values, _id: uuidv4() },
        ];
        if (
          booking?._id &&
          booking.numberOfGuests !== undefined &&
          booking.deposit !== undefined &&
          booking.discount !== undefined
        ) {
          updateBookingMutation({
            _id: booking._id || '',
            notes: booking.notes || '',
            numberOfGuests: booking.numberOfGuests,
            deposit: booking.deposit,
            serviceCharge: newServiceCharge,
            discount: booking.discount,
          });
        }
      }
      formikServiceChargeRef?.current?.resetForm();
      setIsAddServiceCharge(false);
    },
    [
      booking._id,
      booking.deposit,
      booking.discount,
      booking.notes,
      booking.numberOfGuests,
      bookingServiceCharge,
      updateBookingMutation,
    ]
  );

  const handleRemoveServiceCharge = useCallback(
    (serviceCharge: ServiceCharge) => {
      if (serviceCharge) {
        if (
          booking?._id &&
          booking.numberOfGuests !== undefined &&
          booking.deposit !== undefined &&
          booking.discount !== undefined
        ) {
          const newServiceCharge = (
            bookingServiceCharge?.serviceCharge || []
          ).filter((s) => s._id !== serviceCharge._id);
          updateBookingMutation({
            _id: booking._id || '',
            notes: booking.notes || '',
            numberOfGuests: booking.numberOfGuests,
            deposit: booking.deposit,
            serviceCharge: newServiceCharge,
            discount: booking.discount,
          });
        }
      }
    },
    [
      booking._id,
      booking.deposit,
      booking.discount,
      booking.notes,
      booking.numberOfGuests,
      bookingServiceCharge?.serviceCharge,
      updateBookingMutation,
    ]
  );

  useEffect(() => {
    setBookingServiceCharge(booking);
  }, [booking]);

  const { printElement } = usePrintBill({
    bookingId: booking._id ?? '',
    // eslint-disable-next-line react/no-unstable-nested-components
    triggerElement: ({ loading, handlePrint }) => (
      <Tooltip title="In hoá đơn">
        <IconButton disabled={loading} onClick={handlePrint}>
          {loading ? <CircularProgress size={24} /> : <PrintRounded />}
        </IconButton>
      </Tooltip>
    ),
  });

  return (
    <SuiModal
      loading={isLoadingUpdate || (isLoadingBillBooking && isFetching)}
      open={open}
      onClose={onClose}
      sx={{
        h4: {
          width: '100%',
          paddingLeft: '1.5rem',
        },
      }}
      title={
        <SuiBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Typography
            fontSize={{
              xs: '1.5rem',
              md: '1.8rem',
              lg: '2rem',
            }}
            fontWeight="bold"
          >
            {suiModalConfig.title}
          </Typography>
        </SuiBox>
      }
      confirmText={suiModalConfig.confirmText}
      onConfirm={() => formikRef.current?.submitForm()}
      cancelText={suiModalConfig.cancelText}
      desktopHeight="80dvh"
      desktopWidth={theme.spacing(100)}
      backText="Về trang trước"
      onBack={handleBackToBooking}
      showCloseIcon={false}
      modalActions={
        booking?.status === BookingStatus.CHECKOUT ? printElement : undefined
      }
    >
      <SuiBox>
        <SuiBox mt={1} mb={1.5} display="flex" columnGap={pxToRem(29)}>
          <SuiBox display="flex" alignItems="center" columnGap={pxToRem(9)}>
            <HomeWorkIcon />
            <SuiTypography variant="h5" fontWeight={400} fontSize="1rem">
              {suiModalConfig.distributorName}
            </SuiTypography>
          </SuiBox>
          <SuiBox display="flex" alignItems="center" columnGap={pxToRem(9)}>
            <PinDropIcon />
            <SuiTypography variant="h5" fontWeight={400} fontSize="1rem">
              {suiModalConfig.unitName}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox
          sx={{
            borderTop: '1px dashed #BDBDBD',
          }}
          pt={1.5}
        >
          <Formik
            onSubmit={onSubmit}
            initialValues={initFormValues}
            innerRef={formikRef}
            validationSchema={bookingFormValidationSchema}
            enableReinitialize
          >
            {({ values }) => (
              <Grid2 container mb={3} width="100%" rowGap={3}>
                {updateBookingError && (
                  <Grid2 xs={12}>
                    <FormErrors
                      title="Cập nhật chi phí booking không thành công"
                      error={updateBookingError}
                    />
                  </Grid2>
                )}
                <Grid2 xs={12} md={12}>
                  <FormikInput
                    name="priceOrder"
                    placeholder={
                      !booking?.status ||
                      WAITING_FOR_STATUSES.includes(booking?.status)
                        ? 'Số tiền phải cọc'
                        : 'Số tiền đã thanh toán'
                    }
                    inputComponent={NumericFormatInputWithTotal as any}
                    withIcon={{
                      direction: 'right',
                      icon: !!booking?._id && <EditIcon />,
                    }}
                    readOnly={
                      !booking?.status ||
                      !ALLOW_FOR_STATUSES.includes(booking?.status)
                    }
                    inputProps={{
                      isAllowed: ({ floatValue }: NumberFormatValues) => {
                        return (
                          floatValue === undefined ||
                          floatValue <= billCalculator.totalAfterDiscount
                        );
                      },
                      allowNegative: false,
                      inputMode: 'decimal',
                    }}
                  />
                </Grid2>

                <Grid2 xs={12} md={12}>
                  <ListChildRoom
                    childRooms={booking?.childRooms || []}
                    title="Chi phí các phòng"
                    bills={bookingBill}
                    isEdit={!!booking?._id}
                    screenActive={ScreenActiveEnum.BOOKING_BILL}
                  />
                </Grid2>
                <Grid2 xs={12} md={12}>
                  <SuiBox mb={2}>
                    <SuiTypography fontSize={pxToRem(18)} fontWeight="medium">
                      Chi phí phát sinh
                    </SuiTypography>
                  </SuiBox>
                  <List
                    sx={{
                      width: '100%',
                      'li:not(:last-child)': {
                        marginBottom: '0.75rem',
                      },
                      span: {
                        fontSize: '1rem',
                      },
                      'li div:nth-of-type(2)': {
                        textAlign: 'right',
                      },
                    }}
                  >
                    {bookingServiceCharge?.serviceCharge?.map(
                      (serviceCharge) => (
                        <ListItem
                          key={`${serviceCharge.name}_${serviceCharge.value}_${serviceCharge?._id}`}
                        >
                          <ListItemText>{serviceCharge?.name}</ListItemText>
                          <ListItemText
                            sx={{
                              position: 'relative',
                              span: {
                                fontWeight: 600,
                                display: 'flex',
                                justifyContent: 'flex-end',
                              },
                            }}
                          >
                            {currencyFormat(serviceCharge?.value)}
                            <DeleteOutlineIcon
                              fontSize="medium"
                              sx={{
                                cursor: 'pointer',
                                position: 'relative',
                                zIndex: 2,
                              }}
                              color="error"
                              onClick={(e: React.MouseEvent) => {
                                e.stopPropagation();
                                handleRemoveServiceCharge(serviceCharge);
                              }}
                            />
                          </ListItemText>
                        </ListItem>
                      )
                    )}
                  </List>
                  <SuiBox mt={2.5}>
                    <SuiButton
                      variant="text"
                      buttonColor="info"
                      fullWidth
                      sx={{
                        border: '1px dashed #2F80ED',
                        padding: '0.75rem 0',
                      }}
                      startIcon={<AddIcon />}
                      onClick={() => setIsAddServiceCharge((prev) => !prev)}
                    >
                      Chi phí phát sinh
                    </SuiButton>
                  </SuiBox>
                  {isAddServiceCharge && (
                    <SuiBox>
                      <SuiBox
                        my={2}
                        sx={{
                          borderTop: '1px dashed #BDBDBD',
                        }}
                      />
                      <Formik
                        enableReinitialize
                        onSubmit={onSubmitServiceCharge}
                        initialValues={initFormServiceChargeValues}
                        innerRef={formikServiceChargeRef}
                        validationSchema={serviceChargeValidate}
                      >
                        {() => (
                          <Form id="form-service-charge">
                            <Grid2
                              container
                              width="100%"
                              rowSpacing={{ xs: 1, md: 2 }}
                              columnSpacing={{ xs: 1, md: 2 }}
                              m={0}
                              mb={1}
                            >
                              <Grid2 xs={6}>
                                <FormikInput
                                  name="name"
                                  placeholder="Nhập tên chi phí"
                                  label="Tên chi phí"
                                />
                              </Grid2>
                              <Grid2 xs={6}>
                                <FormikInput
                                  name="value"
                                  placeholder="Nhập chi phí phát sinh"
                                  label="Chi phí phát sinh"
                                  inputComponent={NumericFormatInput as any}
                                  inputProps={{
                                    allowLeadingZeros: false,
                                    isAllowed: ({
                                      floatValue,
                                      formattedValue,
                                    }: any) => {
                                      return (
                                        formattedValue === '' ||
                                        floatValue <= Number.MAX_SAFE_INTEGER
                                      );
                                    },
                                    inputMode: 'decimal',
                                  }}
                                />
                              </Grid2>
                            </Grid2>
                          </Form>
                        )}
                      </Formik>
                      <SuiBox
                        px={1}
                        mb={2}
                        display="flex"
                        columnGap={2}
                        justifyContent="flex-end"
                      >
                        <SuiButton
                          onClick={() => {
                            setIsAddServiceCharge(false);
                          }}
                          variant="outlined"
                          buttonColor="secondary"
                        >
                          Huỷ
                        </SuiButton>
                        <SuiButton
                          onClick={() => {
                            formikServiceChargeRef.current?.submitForm();
                          }}
                          variant="contained"
                          buttonColor="info"
                        >
                          Thêm
                        </SuiButton>
                      </SuiBox>
                    </SuiBox>
                  )}
                </Grid2>
                <Grid2 xs={12} md={12}>
                  <SuiBox
                    sx={{
                      border: '1px dashed #BDBDBD',
                    }}
                  />
                </Grid2>
                <Grid2 xs={12} md={12}>
                  <SuiBox
                    display="flex"
                    columnGap="0.5rem"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <SuiTypography fontSize={pxToRem(18)} fontWeight="medium">
                      Giảm giá
                    </SuiTypography>
                    <FormikInput
                      sx={{
                        width: '100%%',
                        maxWidth: '13rem',
                      }}
                      name="discount"
                      placeholder=""
                      size="small"
                      inputProps={{
                        isAllowed: ({ floatValue }: { floatValue: number }) => {
                          return (
                            floatValue === undefined ||
                            floatValue <=
                              billCalculator.totalMustPay -
                                (values?.priceOrder?.deposit || 0)
                          );
                        },
                        decimalScale: 0,
                        allowNegative: false,
                        inputMode: 'decimal',
                      }}
                      InputProps={{
                        inputComponent: NumericFormatInput as any,
                        endAdornment: (
                          <InputAdornment position="end">VNĐ</InputAdornment>
                        ),
                      }}
                    />
                  </SuiBox>
                </Grid2>
                <Grid2 xs={12} md={12}>
                  <SuiBox
                    sx={{
                      border: '1px dashed #BDBDBD',
                    }}
                  />
                </Grid2>
                <Grid2 xs={12} md={12}>
                  <List
                    sx={{
                      width: '100%',
                      'li:not(:last-child)': {
                        marginBottom: '0.75rem',
                      },
                      span: {
                        fontSize: {
                          xs: '0.8rem',
                          sm: '0.9rem',
                          md: '1rem',
                        },
                        fontWeight: 'bold',
                      },
                      'li div:nth-of-type(2)': {
                        textAlign: 'right',
                      },
                      'li:last-child div:nth-of-type(2)': {
                        '> span': {
                          color: theme.palette.success.main,
                        },
                      },
                      padding: '0 0.5rem',
                    }}
                  >
                    <ListItem>
                      <ListItemText>Tổng tiền phòng</ListItemText>
                      <ListItemText>
                        {currencyFormat(billCalculator.totalAmount)}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText>Tổng chi phí phát sinh</ListItemText>
                      <ListItemText>
                        {currencyFormat(billCalculator.totalServiceCharge)}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText>Tiền cọc</ListItemText>
                      <ListItemText>
                        {currencyFormat(values.priceOrder.deposit || 0)}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText>Tổng giảm giá</ListItemText>
                      <ListItemText>
                        <ListItemText>
                          {currencyFormat(values.discount || 0)}
                        </ListItemText>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText>KHÁCH CẦN THANH TOÁN</ListItemText>
                      <ListItemText>
                        {currencyFormat(
                          billCalculator.totalMustPay -
                            (values.priceOrder.deposit || 0) -
                            (values.discount || 0)
                        )}
                      </ListItemText>
                    </ListItem>
                  </List>
                </Grid2>
              </Grid2>
            )}
          </Formik>
        </SuiBox>
      </SuiBox>
    </SuiModal>
  );
}
