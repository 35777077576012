import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import {
  getBillByBooking,
  getOrderBill,
  updateOrderBillInfo,
} from 'apis/bill.api';
import { ApiError } from 'interfaces/api-response.interface';
import { PageOptions } from 'interfaces/page-options.interface';
import { OrderBill, UpdateOrderBill } from 'shared/models/orderBill.model';

export const BILL_QUERY_KEY = {
  GET_ORDER_BILL: 'GET_ORDER_BILL',
  GET_BOOKING_BILL: 'GET_BOOKING_BILL',
};

export const useGetOrderBill = (orderId: string, option?: PageOptions) =>
  useQuery<OrderBill>({
    queryKey: [BILL_QUERY_KEY.GET_ORDER_BILL, orderId],
    queryFn: () => getOrderBill(orderId, option),
    refetchOnWindowFocus: false,
    enabled: !!orderId,
  });

export const useGetBillByBooking = (bookingId: string, option?: PageOptions) =>
  useQuery<OrderBill[]>({
    queryKey: [BILL_QUERY_KEY.GET_BOOKING_BILL, bookingId],
    queryFn: () => getBillByBooking(bookingId, option),
    refetchOnWindowFocus: false,
    enabled: !!bookingId,
  });

export const useUpdateOrderBill = (
  options: Omit<
    UseMutationOptions<OrderBill, ApiError, UpdateOrderBill, unknown>,
    'mutationFn'
  >
) =>
  useMutation<OrderBill, ApiError, UpdateOrderBill>({
    ...options,
    mutationFn: (values) => updateOrderBillInfo(values),
  });
