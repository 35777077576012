import {
  useQuery,
  UseMutationOptions,
  useMutation,
} from '@tanstack/react-query';
import {
  createBooking,
  updateBooking,
  getBooking,
  getListBooking,
} from 'apis/booking.api';
import { ApiError, ApiResponseList } from 'interfaces/api-response.interface';
import { PageOptions } from 'interfaces/page-options.interface';

import {
  Booking,
  CreateBooking,
  UpdateBooking,
} from 'shared/models/booking.model';
import useMylaMutation, {
  UseMylaMutationOptions,
} from 'shared/packages/myla-query/hooks/useMutation';

export const BOOING_QUERY_KEY = {
  TOTAL_BOOKING_PRICE: 'TOTAL_BOOKING_PRICE',
  GET_BOOKING: 'GET_BOOKING',
  GET_LIST_BOOKING: 'GET_LIST_BOOKING',
};

export const useCreateBooking = (
  options: Omit<
    UseMutationOptions<Booking, ApiError, CreateBooking, unknown>,
    'mutationFn'
  >
) =>
  useMutation<Booking, ApiError, CreateBooking>({
    ...options,
    mutationFn: (values) => createBooking(values),
  });

export const useUpdateBooking = (
  options: Omit<
    UseMylaMutationOptions<Booking, ApiError, UpdateBooking, unknown>,
    'mutationFn'
  >
) =>
  useMylaMutation<Booking, ApiError, UpdateBooking>({
    ...options,
    mutationFn: (values) => updateBooking(values),
  });

export const useGetBooking = (id: string, option: PageOptions) =>
  useQuery<Booking>({
    queryKey: [BOOING_QUERY_KEY.GET_BOOKING, id, option.populate],
    queryFn: () => getBooking(id, option),
    refetchOnWindowFocus: false,
    enabled: !!id,
    keepPreviousData: false,
  });

export const useGetListBooking = (option: PageOptions) =>
  useQuery<ApiResponseList<Booking>>({
    queryKey: [BOOING_QUERY_KEY.GET_LIST_BOOKING, option],
    queryFn: () => getListBooking(option),
    refetchOnWindowFocus: false,
  });
