// Soft UI Dashboard PRO React base styles

import colors from 'shared/theme/base/colors';

// Soft UI Dashboard PRO React helper functions

const { transparent } = colors;

export default {
  styleOverrides: {
    select: {
      alignItems: 'center',

      '& .Mui-selected': {
        backgroundColor: transparent.main,
      },
    },

    selectMenu: {
      background: 'none',
      height: 'none',
      minHeight: 'none',
      overflow: 'unset',
    },

    icon: {
      display: 'none',
    },
  },
};
