import { BookingStatus } from 'shared/enums';

export const BOOKING_STATUS_TEXTS: Record<string, string> = {
  [BookingStatus.NEW]: 'Chưa cọc',
  [BookingStatus.LOCKED]: 'Đã cọc',
  [BookingStatus.CHECKIN]: 'Check in',
  [BookingStatus.CHECKOUT]: 'Check out',
  [BookingStatus.CANCELLED]: 'Đã huỷ',
  [BookingStatus.CONFLICT]: 'Trùng lịch',
  [BookingStatus.REFUND]: 'Hoàn tiền',
};

export const BOOKING_STATUS_COLORS: Record<string, string> = {
  [BookingStatus.NEW]: '#FFC000',
  [BookingStatus.LOCKED]: '#27AE60',
  [BookingStatus.CHECKIN]: '#2F80ED',
  [BookingStatus.CHECKOUT]: '#607d8b',
  [BookingStatus.CANCELLED]: '#EB5757',
  [BookingStatus.CONFLICT]: '#795548',
};
