export enum IsSeenEnum {
  NotSeen = '0',
  Seen = '1',
}

export enum NotificationLevel {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}

export enum NotificationStatus {
  ARCHIVE = 'ARCHIVE',
  NEW = 'NEW',
}

export interface Notification {
  id: string;
  body: string;
  bookingId: string;
  title: string;
  isSeen: IsSeenEnum;
  type: string;
  status: NotificationStatus;
  colorType: NotificationLevel;
}
