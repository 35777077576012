import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import { useMemo, useState } from 'react';
import { ChildRoomWithRoomData } from 'shared/models/booking.model';
import { OrderBill } from 'shared/models/orderBill.model';
import { currencyFormat } from 'utils/currencyFormat';
import EditIcon from '@mui/icons-material/Edit';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  List,
  ListItem,
  ListItemText,
  Typography,
  styled,
} from '@mui/material';
import { OrderBooking } from 'shared/models/orderBooking.model';
import { getEllipsisStyles } from 'shared/utils/styles';
import pxToRem from 'shared/theme/functions/pxToRem';
import { BOOKING_STATUS_COLORS, BOOKING_STATUS_TEXTS } from './constants';
import OrderForm from '../OrderForm';
import { ScreenActiveEnum } from '../../constants';
import OrderBillForm from '../OrderBill';

interface ListChildRoomProps {
  childRooms: ChildRoomWithRoomData[];
  title: string;
  bills?: OrderBill[];
  isEdit?: boolean;
  screenActive?: ScreenActiveEnum;
}

const ChildRoomStyled = styled(Accordion)`
  box-shadow: 4px 4px 16px 0px rgba(12, 74, 157, 0.08);
  border-radius: 0.5rem !important;
  border: 1px solid #f2f2f2;
  &::before {
    display: none;
  }
`;

function ListChildRoom({
  childRooms,
  title,
  bills,
  isEdit = false,
  screenActive,
}: ListChildRoomProps) {
  const [expanded, setExpanded] = useState<string | false>(false);
  const [selectedOrder, setSelectedOrder] = useState<OrderBooking | undefined>(
    undefined
  );

  const listChildRoom = useMemo(() => {
    if (!isEdit) return childRooms;
    return bills?.map((bill) => bill.order?.childRoom).filter(Boolean);
  }, [bills, childRooms, isEdit]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const renderBillByOrder = (childRoomId: string) => {
    if (!bills || !Array.isArray(bills)) return null;
    const orderBill = bills.find(
      (bill) => String(bill.childRoom).toString() === childRoomId
    ) as OrderBill;

    const totalAmount = () => {
      const totalServiceCharge = orderBill?.serviceCharge?.reduce(
        (acc, item) => {
          return acc + item.value;
        },
        0
      );
      return (orderBill?.totalAmount || 0) + totalServiceCharge;
    };

    // eslint-disable-next-line react/jsx-no-useless-fragment
    if (!orderBill) return <></>;

    return (
      <>
        {orderBill?.items?.map((item) => (
          <ListItem key={item._id}>
            <ListItemText>{item.name}</ListItemText>
            <ListItemText>{currencyFormat(item.value)}</ListItemText>
          </ListItem>
        ))}
        {orderBill?.serviceCharge?.map((item) => (
          <ListItem key={item._id}>
            <ListItemText>{item.name}</ListItemText>
            <ListItemText>{currencyFormat(item.value)}</ListItemText>
          </ListItem>
        ))}
        <ListItem>
          <ListItemText>Tổng tiền</ListItemText>
          <ListItemText>{currencyFormat(totalAmount())}</ListItemText>
        </ListItem>
      </>
    );
  };

  const mapChildRoomAndBill = useMemo(() => {
    const mapChildRoom = new Map<string, OrderBooking>();
    bills?.forEach((bill) => {
      if (bill.childRoom && bill.order)
        mapChildRoom.set(String(bill.childRoom || ''), bill.order);
    });

    return mapChildRoom;
  }, [bills]);

  const getOrder = (childRoomId: string) => {
    return mapChildRoomAndBill.get(childRoomId);
  };

  const renderModal = useMemo(() => {
    if (!selectedOrder) return null;
    if (screenActive === ScreenActiveEnum.BOOKING_BILL && selectedOrder?._id) {
      return (
        <OrderBillForm
          orderId={selectedOrder?._id}
          onClose={() => setSelectedOrder(undefined)}
        />
      );
    }
    return (
      <OrderForm
        onClose={() => setSelectedOrder(undefined)}
        orderId={selectedOrder._id}
        onOpenBookingForm={() => setSelectedOrder(undefined)}
      />
    );
  }, [screenActive, selectedOrder]);

  return (
    <SuiBox mb={2}>
      {title && (
        <SuiBox mb={2}>
          <SuiTypography fontSize={pxToRem(18)} fontWeight="medium">
            {title}
          </SuiTypography>
        </SuiBox>
      )}
      <SuiBox display="flex" rowGap="1rem" flexDirection="column">
        {listChildRoom?.map((childRoom) => (
          <ChildRoomStyled
            key={childRoom?._id}
            expanded={expanded === childRoom?._id}
            onChange={handleChange(childRoom?._id as string)}
            sx={{}}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${childRoom?._id}-content`}
              id={`${childRoom?._id}-header`}
              sx={{
                display: 'flex',
                borderBottom: (theme) =>
                  ` ${
                    expanded === childRoom?._id
                      ? `1px dashed ${theme.palette.inputColors.borderColor.main}`
                      : 'none'
                  }`,
                '> .MuiAccordionSummary-content': {
                  columnGap: {
                    xs: '0.5rem',
                    md: '0.75rem',
                  },
                  margin: '1rem 0',
                  '& p': {
                    position: 'relative',
                  },
                  alignItems: 'center',
                },
                '> .MuiAccordionSummary-content p:not(:first-of-type)': {
                  paddingLeft: {
                    xs: '0.5rem',
                    sm: '0.75rem',
                  },
                },
                '> .MuiAccordionSummary-content p:not(:first-of-type)::before':
                  {
                    content: '""',
                    position: 'absolute',
                    top: '50%',
                    left: 0,
                    width: '1.5px',
                    backgroundColor: '#BDBDBD',
                    height: '0.9375rem',
                    transform: 'translateY(-50%)',
                  },
              }}
            >
              <SuiTypography
                sx={{
                  '&::before': {
                    fontWeight: 'bold',
                  },
                  whiteSpace: 'nowrap',
                }}
                fontWeight="bold"
                fontSize="1rem"
              >
                {childRoom?.name}
              </SuiTypography>
              <Typography
                fontSize="1rem"
                sx={{
                  ...getEllipsisStyles(1),
                }}
              >
                {childRoom?.room?.name}
              </Typography>
              <Typography
                fontSize="1rem"
                sx={{
                  ...getEllipsisStyles(1),
                  minWidth: 'fit-content',
                  display: {
                    xs: 'none',
                    sm: 'block',
                  },
                }}
              >
                {childRoom?.room?.maxCapacity} khách
              </Typography>
              {!!getOrder(childRoom?._id || '') && (
                <SuiBox marginLeft="auto" pr={1}>
                  <Chip
                    label={
                      BOOKING_STATUS_TEXTS[
                        getOrder(childRoom?._id || '')?.status || ''
                      ]
                    }
                    sx={{
                      backgroundColor:
                        BOOKING_STATUS_COLORS[
                          getOrder(childRoom?._id || '')?.status || ''
                        ],
                      color: '#fff',
                    }}
                    onDelete={() => {
                      setSelectedOrder(getOrder(childRoom?._id || ''));
                    }}
                    deleteIcon={
                      <EditIcon
                        fontSize="small"
                        sx={{
                          color: '#fff!important',
                        }}
                      />
                    }
                  />
                </SuiBox>
              )}
            </AccordionSummary>
            <AccordionDetails
              sx={{
                marginTop: '0.5rem',
              }}
            >
              <List
                dense
                sx={{
                  width: '100%',
                  'li:not(:last-child)': {
                    marginBottom: '1rem',
                  },
                  padding: '0 1rem',
                  span: {
                    fontSize: '1rem',
                    fontWeight: 'bold',
                  },
                  'li div:nth-of-type(2)': {
                    textAlign: 'right',
                  },
                  'li:last-child div:nth-of-type(2)': {
                    color: (theme) => theme.palette.success.main,
                  },
                }}
              >
                {renderBillByOrder(childRoom?._id)}
              </List>
            </AccordionDetails>
          </ChildRoomStyled>
        ))}
      </SuiBox>
      {renderModal}
    </SuiBox>
  );
}

export default ListChildRoom;
