import { useField } from 'formik';
import { ChangeEventHandler, ComponentType, useCallback } from 'react';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  CheckboxProps,
} from '@mui/material';

export interface Option {
  value: string;
  text: string;
  disabled?: boolean;
}

export interface OptionComponentProps extends Option {
  checked: boolean;
}

interface FormikCheckboxProps extends Pick<CheckboxProps, 'sx'> {
  name: string;
  label?: string;
  required?: boolean;
  options?: Option[];
  helperText?: string;
  disabled?: boolean;
  checkboxProps?: Partial<Omit<CheckboxProps, 'name' | 'onChange' | 'checked'>>;
  OptionComponent?: ComponentType<OptionComponentProps>;
}

function FormikCheckbox({
  name,
  helperText,
  disabled,
  label,
  required,
  sx,
  options = [],
  OptionComponent,
  checkboxProps = {},
}: FormikCheckboxProps) {
  const [field, meta, helpers] = useField<string[]>(name);
  const { error, touched } = meta;
  const isError = !!error && touched;
  const { setValue } = helpers;
  const { value = [] } = field;

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { checked } = event.target;

      if (checked) {
        setValue([...value, event.target.name]);
      } else {
        setValue(value.filter((item) => item !== event.target.name));
      }
    },
    [value, setValue]
  );

  return (
    <FormControl component="fieldset" variant="standard" required={required}>
      {label && (
        <FormLabel
          error={isError}
          sx={{
            mb: 0.5,
            color: (theme) => `${theme.palette.black.main} !important`,
            fontSize: '0.75rem',
            fontWeight: 'bold',
          }}
        >
          {label}
        </FormLabel>
      )}
      <FormGroup sx={sx}>
        {options.map((option) => {
          const {
            value: optionValue,
            text,
            disabled: isDisabledOption,
          } = option;
          const checked = value.includes(optionValue);

          return OptionComponent ? (
            <FormControlLabel
              sx={{ my: 0.5 }}
              key={optionValue}
              disabled={isDisabledOption || disabled}
              control={
                <Checkbox
                  {...checkboxProps}
                  name={optionValue}
                  onChange={handleChange}
                  checked={checked}
                  disabled={isDisabledOption || disabled}
                  sx={{ display: 'none' }}
                />
              }
              label={<OptionComponent {...option} checked={checked} />}
            />
          ) : (
            <FormControlLabel
              key={optionValue}
              disabled={isDisabledOption || disabled}
              control={
                <Checkbox
                  {...checkboxProps}
                  name={optionValue}
                  onChange={handleChange}
                  checked={checked}
                  disabled={isDisabledOption || disabled}
                />
              }
              label={text}
              sx={{
                alignItems: 'center',

                '& .MuiFormControlLabel-label': {
                  fontWeight: 500,
                  fontSize: '0.8125rem',
                  color: (theme) => theme.palette.black.main,
                  lineHeight: 1.5,
                },
              }}
            />
          );
        })}
      </FormGroup>
      {(helperText || isError) && (
        <FormHelperText>{isError ? error : helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

export default FormikCheckbox;
