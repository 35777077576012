import borders from 'shared/theme/base/borders';

import colors from 'shared/theme/base/colors';

const { borderRadius } = borders;
const { light } = colors;

export default {
  styleOverrides: {},
} as const;
