import { createContext, useContext } from 'react';
import { BookingFormState } from '../components/BookingForm/types';

export interface BookingFormContextType {
  open: boolean;
  bookingData?: BookingFormState;
  handlePrint: () => void;
  toggleBookingForm: (open: boolean, bookingData?: BookingFormState) => void;
}

const BookingFormContext = createContext<BookingFormContextType>({
  open: false,
  toggleBookingForm: () => {},
  handlePrint: () => {},
});

export const BookingFormContextProvider = BookingFormContext.Provider;

export const useBookingFormContext = () => useContext(BookingFormContext);
