import { Box, Typography } from '@mui/material';
import { messaging } from 'config/firebase';
import { onMessage } from 'firebase/messaging';
import { useEffect } from 'react';
import { TypeOptions, toast } from 'react-toastify';

function ToastContent(notification: Record<string, string>) {
  return (
    <Box display="flex" alignItems="flex-start" flexDirection="column">
      <Typography
        fontWeight="medium"
        fontSize={16}
        variant="h6"
        textTransform="capitalize"
      >
        {notification.title}
      </Typography>
      <Typography fontSize={12}>{notification.body}</Typography>
    </Box>
  );
}

export default function useWaitingForNotification() {
  useEffect(() => {
    messaging.then((resolvedMessaging) => {
      if (resolvedMessaging)
        onMessage(resolvedMessaging, (payload) => {
          if (!payload.data) {
            return;
          }

          toast(<ToastContent {...payload.data} />, {
            autoClose: 2500,
            type: payload.data.statusNotification as TypeOptions,
          });
        });
    });
  }, []);
}
