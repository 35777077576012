// Soft UI Dashboard PRO React base styles

import boxShadows from 'shared/theme/base/boxShadows';

import typography from 'shared/theme/base/typography';

import colors from 'shared/theme/base/colors';

import borders from 'shared/theme/base/borders';

// Soft UI Dashboard PRO React helper functions

import pxToRem from 'shared/theme/functions/pxToRem';

const { lg } = boxShadows;
const { size } = typography;
const { text, white } = colors;
const { borderRadius } = borders;

export default {
  defaultProps: {
    disableAutoFocusItem: true,
  },

  styleOverrides: {
    paper: {
      minWidth: pxToRem(160),
      boxShadow: lg,
      padding: `${pxToRem(16)} ${pxToRem(8)}`,
      fontSize: size.sm,
      color: text.main,
      textAlign: 'left',
      backgroundColor: `${white.main} !important`,
      borderRadius: borderRadius.md,
    },
  },
} as const;
