import { SxProps } from '@mui/material';

export const getEllipsisStyles = (numberOfLines = 2): SxProps => ({
  display: '-webkit-box',
  WebkitLineClamp: numberOfLines.toString(),
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordBreak: 'break-word',
});
