import SuiBox from 'components/SuiBox';

// Soft UI Dashboard PRO React components
import SuiButton from 'components/SuiButton';
import SuiTypography from 'components/SuiTypography';

// Soft UI Dashboard PRO React context
import { useSoftUIController } from 'context/softUI';

// Custom styles for the SidenavCard
import styles from 'examples/Sidenav/styles/sidenavCard';
import React from 'react';

import { Card, CardContent, Icon, Link } from '@mui/material';

function SidenavCard() {
  const [controller] = useSoftUIController();
  const { miniSidenav, sidenavColor } = controller;
  const classes = styles({ miniSidenav, sidenavColor });

  return (
    <Card className={classes.card}>
      <CardContent className={classes.card_content}>
        <SuiBox customClass={classes.card_iconBox}>
          <Icon className={classes.card_icon} fontSize="medium">
            star
          </Icon>
        </SuiBox>
        <SuiBox lineHeight={1}>
          <SuiTypography variant="h6" textColor="white" mb={1}>
            Cần trợ giúp?
          </SuiTypography>
          <SuiBox mb={1.825} mt={-1}>
            <SuiTypography
              variant="caption"
              textColor="white"
              fontWeight="medium"
            >
              Liên hệ Bookdee
            </SuiTypography>
          </SuiBox>
          <SuiButton
            component={Link}
            href="https://www.facebook.com/homestayvungtauu/"
            target="_blank"
            rel="noreferrer"
            size="small"
            color="default"
            fullWidth
          >
            Myla HomeStay
          </SuiButton>
        </SuiBox>
      </CardContent>
    </Card>
  );
}

export default SidenavCard;
