import * as Sentry from '@sentry/react';

const SENTRY_DNS = import.meta.env.VITE_SENTRY_DNS;

if (SENTRY_DNS) {
  Sentry.init({
    environment: import.meta.env.VITE_NODE_ENV,
    dsn: SENTRY_DNS,
    integrations: [
      new Sentry.Integrations.GlobalHandlers({
        onerror: import.meta.env.VITE_NODE_ENV === 'production',
        onunhandledrejection: true,
      }),
      new Sentry.TryCatch(),
      new Sentry.Breadcrumbs(),
      new Sentry.LinkedErrors(),
      new Sentry.HttpContext(),
      new Sentry.Dedupe(),
      new Sentry.FunctionToString(),
      new Sentry.InboundFilters(),
    ],

    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    ignoreErrors: [
      /ResizeObserver loop completed with undelivered notifications/,
      /Can't find variable: zaloJSV2/,
    ],
  });
}
