import Calendar from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import StoreIcon from '@mui/icons-material/Store';
import LockIcon from '@mui/icons-material/Lock';
import { UserRole } from 'shared/models/user.model';
import { ROUTES_PATH } from '../constants/routes-path.constant';
import Settings from '../examples/Icons/Settings';
import Office from '../examples/Icons/Office';
import Shop from '../examples/Icons/Shop';
import { SidebarItem } from '../examples/Sidenav/types';

const sidebarItems: SidebarItem[] = [
  {
    type: 'collapse',
    name: 'Dashboards',
    key: 'dashboards',
    icon: <Shop size="12px" />,
    collapse: [
      {
        name: 'Tổng quan',
        key: 'dashboards',
        route: 'dashboards',
      },
    ],
  },
  {
    name: 'Nhà cung cấp',
    type: 'collapse',
    key: 'distributors',
    icon: <StoreIcon sx={{ fontSize: 12 }} />,
    authorize: [UserRole.Admin, UserRole.Sale, UserRole.Distributor],
    collapse: [
      {
        name: 'Danh sách',
        key: 'distributors',
        route: ROUTES_PATH.DISTRIBUTOR.LIST,
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Cài Đặt',
    key: 'settings',
    icon: <Settings size="12px" />,
    collapse: [
      {
        name: 'Tiện Ích',
        key: 'utilities',
        route: 'settings/utilities',
      },
      {
        name: 'Chính Sách',
        key: 'policies',
        route: 'settings/policies',
      },
      {
        name: 'Điểm Nổi Bật',
        key: 'selling-keys',
        route: 'settings/selling-keys',
      },
      {
        name: 'Nhóm bán hàng',
        key: 'groups',
        route: 'settings/groups',
        authorize: [UserRole.Admin],
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Quản lý khoá',
    key: 'lock-management',
    icon: <LockIcon />,
    authorize: [UserRole.Admin],
    collapse: [
      {
        name: 'Danh sách',
        key: 'lock-management',
        route: 'lock-management',
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Người dùng',
    key: 'users',
    icon: <PersonIcon />,
    authorize: [UserRole.Admin],
    collapse: [
      {
        name: 'Danh sách',
        key: 'users',
        route: 'users',
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Khách hàng',
    key: 'customers',
    icon: <Office size="12px" />,
    authorize: [UserRole.Admin],
    collapse: [
      {
        name: 'Danh sách',
        key: 'customers',
        route: 'customers',
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Lịch Đặt Phòng',
    key: 'schedules',
    icon: <Calendar />,
    collapse: [
      {
        name: 'Đặt phòng',
        key: 'sale',
        route: 'schedules/sale',
      },
      {
        name: 'Dashboard',
        key: 'sale-dashboard',
        route: 'schedules/sale-dashboard',
      },
      {
        name: 'Danh sách booking',
        key: 'sale-history',
        route: 'schedules/sale-history',
      },
    ],
  },
];

export default sidebarItems;
