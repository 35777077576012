import { Unit } from 'shared/models/unit.model';
import { sum } from 'lodash-es';
import { useMemo } from 'react';
import { Booking } from 'shared/models/booking.model';
import { OrderBill, ServiceCharge } from 'shared/models/orderBill.model';
import { UnitWithDistributor } from 'shared/models/unitWithDistributor.model';

function calculateServiceCharge(serviceCharge: ServiceCharge[]) {
  return serviceCharge.reduce((total, item) => {
    return total + item.value;
  }, 0);
}

interface UseCalculatorBillProps {
  bills: OrderBill[];
  booking?: Partial<
    Omit<Booking, 'unit'> & { unit: Unit | UnitWithDistributor }
  >;
  roomPrices?: Record<string, Record<string, number>>;
}

const DEFAULT_BILL = {
  totalAmount: 0, // tổng tiền phòng
  totalServiceCharge: 0, // tổng chi phí phát sinh
  totalMustPay: 0, // thổng tiền bao gồm cả tiền phòng, tiền phát sinh
  totalRemain: 0, // Số tiền phải thanh toán sau khi trừ đi tiền đặt cọc và giảm giá
  discount: 0,
  deposit: 0,
  totalAfterDiscount: 0, // số tiền sau khi trừ đi giảm giá: Tổng tiền phòng + chi phí phát sinh - giảm giá
};

export default function useCalculatorBill({
  bills,
  booking,
  roomPrices,
}: UseCalculatorBillProps) {
  return useMemo(() => {
    if (!booking?._id && roomPrices) {
      const initialAmount = roomPrices
        ? Object.values(roomPrices).reduce(
            (acc, cur) => sum(Object.values(cur)) + acc,
            0
          )
        : 0;

      return {
        ...DEFAULT_BILL,
        totalAmount: initialAmount,
        totalRemain: initialAmount,
        totalAfterDiscount: initialAmount,
      };
    }

    if (!bills || !booking) return DEFAULT_BILL;

    const calculatorBill = bills.reduce((total, bill) => {
      const totalServiceCharge = calculateServiceCharge(
        bill.serviceCharge || []
      );
      return {
        ...total,
        totalAmount: total.totalAmount + bill.totalAmount,
        totalServiceCharge: total.totalServiceCharge + totalServiceCharge,
      };
    }, DEFAULT_BILL);

    const bookingServiceCharge = calculateServiceCharge(
      booking.serviceCharge || []
    );

    const totalMustPay =
      calculatorBill.totalAmount +
      calculatorBill.totalServiceCharge +
      bookingServiceCharge;

    return {
      ...calculatorBill,
      totalMustPay,
      discount: booking.discount || 0,
      deposit: booking.deposit || 0,
      totalServiceCharge:
        calculatorBill.totalServiceCharge + bookingServiceCharge,
      totalRemain:
        totalMustPay - (booking.deposit || 0) - (booking.discount || 0),
      totalAfterDiscount: totalMustPay - (booking.discount || 0),
    };
  }, [bills, booking, roomPrices]);
}
