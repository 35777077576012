import { Box, Typography, styled } from '@mui/material';
import { BOOING_QUERY_KEY } from 'apis-query/booking.query';
import { getBooking } from 'apis/booking.api';
import MylaLogo from 'assets/images/logos/myla-logo.svg';
import { useAuth } from 'context/auth';
import { format } from 'date-fns';
import { get } from 'lodash-es';
import { Fragment, useCallback, useEffect, useMemo, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { OrderStatus } from 'shared/enums';
import { useLazyQuery } from 'shared/hooks/useLazyQuery';
import { Booking, BookingBill } from 'shared/models/booking.model';
import { currencyFormat } from 'utils/currencyFormat';
import useCalculatorBill from './useCalculatorBill';

interface UsePrintBillProps {
  bookingId: string;
  triggerElement?: (params: {
    loading: boolean;
    handlePrint: () => void;
  }) => void;
}

const extractItemQuantity = (item: string) => {
  const matches = /^(\d+)x\s(.+)/.exec(item);

  return matches
    ? {
        name: matches[2],
        quantity: parseInt(matches[1], 10),
      }
    : {
        name: item,
        quantity: 1,
      };
};

const RightAlignedCell = styled('td')`
  text-align: right;
`;

const StyledTable = styled('table')`
  border-collapse: collapse;
  width: 100%;

  & td {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  & td:first-child {
    white-space: nowrap;
    vertical-align: top;
    padding-right: 8px;
  }
`;

const StyledCell = styled('td')`
  border-top: 1px solid #a9a9a9;
  border-bottom: 1px solid #a9a9a9;
  && {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;

const StyledQuantityCell = styled(StyledCell)`
  width: 10%;
`;

const StyledTotalCell = styled(StyledCell)`
  text-align: right;
`;

const StyledDivider = styled('hr')`
  width: 100%;
  margin-bottom: 24px;
  margin-top: 24px;
  opacity: 0.3;
`;

const GET_BOOKING_POPULATION =
  'unit,bills,bills.order,childRooms,childRooms.room';

const usePrintBill = ({ bookingId, triggerElement }: UsePrintBillProps) => {
  const { user } = useAuth();
  const shouldInvokePrintRef = useRef(false);
  const printContentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printContentRef.current,
    pageStyle: `
    @page {
      margin: 12px 8px 8px 12px;
    }`,
  });

  const {
    data: bookingData,
    isFetching: isFetchingBooking,
    lazyQuery: fetchBooking,
  } = useLazyQuery<Booking>({
    queryKey: [BOOING_QUERY_KEY.GET_BOOKING, bookingId, GET_BOOKING_POPULATION],
    fetcher: () => getBooking(bookingId, { populate: GET_BOOKING_POPULATION }),
    notify: {
      loading: 'Đang tạo hoá đơn...',
    },
  });

  const bookingBills = useMemo(
    () =>
      (bookingData?.bills || []).reduce((acc, bill) => {
        if (bill.order?.status !== OrderStatus.CANCELLED) {
          return {
            ...acc,
            [bill.childRoom.toString()]: bill,
          };
        }

        return acc;
      }, {} as Record<string, BookingBill>),
    [bookingData]
  );

  const billCalculator = useCalculatorBill({
    bills: Object.values(bookingBills),
    booking: bookingData,
  });

  const renderBillItems = useCallback(
    (childRoomId: string) => {
      const billInfo = bookingBills[childRoomId];
      if (!billInfo) {
        return null;
      }

      const { items, serviceCharge } = billInfo;

      return (
        <>
          {items.map((item) => {
            const { name, quantity } = extractItemQuantity(item.name);

            return (
              <tr key={item._id}>
                <StyledCell>{name}</StyledCell>
                <StyledQuantityCell>{quantity}</StyledQuantityCell>
                <StyledTotalCell>{currencyFormat(item.value)}</StyledTotalCell>
              </tr>
            );
          })}
          {serviceCharge.map((item) => (
            <tr key={item._id}>
              <StyledCell>{item.name}</StyledCell>
              <StyledQuantityCell>1</StyledQuantityCell>
              <StyledTotalCell>{currencyFormat(item.value)}</StyledTotalCell>
            </tr>
          ))}
        </>
      );
    },
    [bookingBills]
  );

  useEffect(() => {
    if (bookingData && shouldInvokePrintRef.current) {
      shouldInvokePrintRef.current = false;
      handlePrint();
    }
  }, [bookingData, handlePrint]);

  const handleTriggerPrint = useCallback(() => {
    if (!bookingId) {
      return;
    }

    if (bookingData) {
      handlePrint();
      return;
    }

    shouldInvokePrintRef.current = true;
    fetchBooking();
  }, [bookingData, bookingId, fetchBooking, handlePrint]);

  const renderTriggerProps = {
    handlePrint: handleTriggerPrint,
    loading: isFetchingBooking,
  };

  return {
    ...renderTriggerProps,
    printElement: (
      <>
        {triggerElement?.(renderTriggerProps)}
        <Box
          ref={printContentRef}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          width="100%"
          p={1}
          sx={{
            '@media screen': {
              display: 'none',
            },
            '@media print': {
              display: 'flex',
            },
          }}
        >
          <Box component="img" src={MylaLogo} sx={{ width: '20%', mb: 1 }} />
          <Typography
            variant="h5"
            sx={{ textAlign: 'center', color: '#333333' }}
          >
            BOOKDEE
          </Typography>
          <StyledDivider />
          <StyledTable
            sx={{
              mb: 1.5,
            }}
          >
            <tr>
              <td>Booking:</td>
              <td>#{bookingData?.bookingCode}</td>
            </tr>
            <tr>
              <td>Chi nhánh:</td>
              <td>{bookingData?.unit?.name}</td>
            </tr>
            <tr>
              <td>Nhân viên:</td>
              <td>{user?.name}</td>
            </tr>
            <tr>
              <td>Thời gian:</td>
              <td>{format(new Date(), 'dd/MM/yyyy HH:MM')}</td>
            </tr>
          </StyledTable>
          <StyledTable sx={{ mb: 2 }}>
            {bookingData?.childRooms?.map?.(({ _id, room, name }) => (
              <Fragment key={_id}>
                <tr>
                  <Box
                    component="td"
                    sx={{ py: '12px !important' }}
                    colSpan={3}
                  >
                    <b>
                      {name}&nbsp;&nbsp;|&nbsp;&nbsp;
                      {get(room, 'name')}
                    </b>
                  </Box>
                </tr>
                {renderBillItems(_id)}
              </Fragment>
            ))}
          </StyledTable>
          <StyledTable>
            <tr>
              <td>Tiền phòng</td>
              <RightAlignedCell>
                {currencyFormat(billCalculator.totalAmount)}
              </RightAlignedCell>
            </tr>
            <tr>
              <td>Chi phí phát sinh</td>
              <RightAlignedCell>
                {currencyFormat(billCalculator.totalServiceCharge)}
              </RightAlignedCell>
            </tr>
            <tr>
              <td>Đã cọc</td>
              <RightAlignedCell>
                {currencyFormat(bookingData?.deposit || 0)}
              </RightAlignedCell>
            </tr>
            <tr>
              <td>Giảm giá</td>
              <RightAlignedCell>
                {currencyFormat(bookingData?.discount || 0)}
              </RightAlignedCell>
            </tr>
            <tr>
              <td>
                <b>THANH TOÁN</b>
              </td>
              <RightAlignedCell>
                <b>
                  {currencyFormat(
                    billCalculator.totalMustPay -
                      (bookingData?.deposit || 0) -
                      (bookingData?.discount || 0)
                  )}
                </b>
              </RightAlignedCell>
            </tr>
          </StyledTable>
          <StyledDivider />
          <Typography>
            Cám ơn bạn đã sử dụng dịch vụ! Vui lòng giữ lại hóa đơn để có thể
            yêu cầu tra soát trong vòng 31 ngày làm việc.
          </Typography>
        </Box>
      </>
    ),
  };
};

export default usePrintBill;
