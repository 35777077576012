// clsx is a utility for constructing className strings conditionally
import clsx from 'clsx';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';
import SuiTypography from 'components/SuiTypography';

// Soft UI Dashboard PRO React context
import { useSoftUIController } from 'context/softUI';

// Custom styles for the Configurator
import styles from 'examples/Configurator/styles';
import React, { useEffect, useState } from 'react';

import { Divider, Drawer, Icon, IconButton, Switch } from '@mui/material';

// react-github-btn

function Configurator() {
  const [controller, dispatch] = useSoftUIController();
  const {
    openConfigurator,
    transparentSidenav,
    miniSidenav,
    fixedNavbar,
    sidenavColor,
  } = controller;
  const [disabled, setDisabled] = useState(false);
  const classes = styles({ sidenavColor });
  const sidenavColors = [
    'primary',
    'dark',
    'info',
    'success',
    'warning',
    'error',
  ];

  // Use the useEffect hook to change the button state for the sidenav type based on window size.
  useEffect(() => {
    // A function that sets the disabled state of the buttons for the sidenav type.
    function handleDisabled() {
      return window.innerWidth > 1200 ? setDisabled(false) : setDisabled(true);
    }

    // The event listener that's calling the handleDisabled function when resizing the window.
    window.addEventListener('resize', handleDisabled);

    // Call the handleDisabled function to set the state with the initial value.
    handleDisabled();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleDisabled);
  }, []);

  const handleCloseConfigurator = () => {
    dispatch({ type: 'OPEN_CONFIGURATOR', value: false });
  };

  const handleTransparentSidenav = () => {
    dispatch({ type: 'TRANSPARENT_SIDENAV', value: true });
  };

  const handleWhiteSidenav = () => {
    dispatch({ type: 'TRANSPARENT_SIDENAV', value: false });
  };

  const handleMiniSidenav = () => {
    dispatch({ type: 'MINI_SIDENAV', value: !miniSidenav });
  };

  const handleFixedNavbar = () => {
    dispatch({ type: 'FIXED_NAVBAR', value: !fixedNavbar });
  };

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.configurator, {
          [classes.configurator_open]: openConfigurator,
          [classes.configurator_close]: !openConfigurator,
        }),
      }}
    >
      <SuiBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <SuiBox>
          <SuiTypography variant="h5">Cấu hình giao diện</SuiTypography>
          <SuiTypography variant="body2" textColor="text">
            Bookdee
          </SuiTypography>
        </SuiBox>

        <Icon
          className={`font-bold ${classes.configurator_close_icon}`}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </SuiBox>

      <Divider />

      <SuiBox pt={1.25} pb={3} px={3}>
        <SuiBox>
          <SuiTypography variant="h6">Màu sắc thanh tác vụ</SuiTypography>

          <SuiBox mb={0.5}>
            {sidenavColors.map((color) => (
              <IconButton
                key={color}
                className={clsx(
                  classes.configurator_sidenav_color,
                  classes[color],
                  {
                    [classes.active_color]: sidenavColor === color,
                  }
                )}
                onClick={() =>
                  dispatch({ type: 'SIDENAV_COLOR', value: color })
                }
              />
            ))}
          </SuiBox>
        </SuiBox>

        <SuiBox mt={3} lineHeight={1}>
          <SuiTypography variant="h6">Định dạng thanh tác vụ</SuiTypography>
          <SuiTypography variant="button" textColor="text" fontWeight="regular">
            Chọn giữa thanh tác vụ trong suốt hoặc màu trắng
          </SuiTypography>

          <SuiBox customClass={classes.configurator_sidenav_types}>
            <SuiButton
              buttonColor="info"
              variant={transparentSidenav ? 'gradient' : 'outlined'}
              onClick={handleTransparentSidenav}
              disabled={disabled}
              fullWidth
            >
              Trong suốt
            </SuiButton>
            <SuiButton
              buttonColor="info"
              variant={transparentSidenav ? 'outlined' : 'gradient'}
              onClick={handleWhiteSidenav}
              disabled={disabled}
              fullWidth
            >
              Màu trắng
            </SuiButton>
          </SuiBox>
        </SuiBox>
        <SuiBox mt={3} mb={2} lineHeight={1}>
          <SuiTypography variant="h6">Cố định thanh điều hướng</SuiTypography>

          <Switch checked={fixedNavbar} onChange={handleFixedNavbar} />
        </SuiBox>

        <Divider />

        <SuiBox mt={2} mb={3} lineHeight={1}>
          <SuiTypography variant="h6">Thu nhỏ thanh tác vụ</SuiTypography>

          <Switch checked={miniSidenav} onChange={handleMiniSidenav} />
        </SuiBox>
      </SuiBox>
    </Drawer>
  );
}

export default Configurator;
