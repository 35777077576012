import { ServerKeyboardPwdType, ServerRecordType } from 'shared/models';

import LockOpenIcon from '@mui/icons-material/LockOpen';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import LockIcon from '@mui/icons-material/Lock';
import SettingsInputHdmiIcon from '@mui/icons-material/SettingsInputHdmi';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';

export const QUERY_KEYS = {
  LOCK_LIST: 'lockManagement/lockList',
  LOCK_LIST_AVAILABLE: 'lockManagement/lockListAvailable',
  LOCK_HISTORIES: 'lockMangement/lockHistories',
} as const;

export const SERVER_RECORD_TYPE_TEXTS = {
  [ServerRecordType.UNLOCK_BY_APP]: 'Mở khóa bằng ứng dụng',
  [ServerRecordType.UNLOCK_BY_PASSCODE]: 'Mở khóa bằng mã',
  [ServerRecordType.UNLOCK_BY_IC_CARD]: 'Mở khóa bằng thẻ IC',
  [ServerRecordType.UNLOCK_BY_FINGERPRINT]: 'Mở khóa bằng vân tay',
  [ServerRecordType.UNLOCK_BY_MERCHANICAL_KEY]: 'Mở khóa bằng chìa cơ',
  [ServerRecordType.LOCK_BY_APP]: 'Khóa bằng ứng dụng',
  [ServerRecordType.UNLOCK_BY_GATEWAY]: 'Mở khóa bằng cổng điều khiển',
  [ServerRecordType.OPEN_FROM_INSIDE]: 'Mở từ bên trong',
  [ServerRecordType.LOCK_BY_FINGERPRINT]: 'Khóa bằng vân tay',
  [ServerRecordType.LOCK_BY_PASSCODE]: 'Khóa bằng mã',
  [ServerRecordType.LOCK_BY_IC_CARD]: 'Khóa bằng thẻ IC',
  [ServerRecordType.LOCK_BY_MERCHANICAL_KEY]: 'Khóa bằng chìa cơ',
  [ServerRecordType.REMOTE_CONTROL]: 'Điều khiển từ xa',
  [ServerRecordType.AUTO_LOCK]: 'Tự động khóa',
  [ServerRecordType.UNLOCK_BY_UNLOCK_KEY]: 'Mở khóa bằng khóa mở khóa',
  [ServerRecordType.LOCK_BY_LOCK_KEY]: 'Khóa bằng khóa khóa',
  [ServerRecordType.SYSTEM_LOCK]: 'Khóa hệ thống',
  [ServerRecordType.UNLOCK_BY_HOTEL_CARD]: 'Mở khóa bằng thẻ khách sạn',
  [ServerRecordType.UNLOCKED_DUE_TO_HIGH_TEMPERATURE]:
    'Mở khóa do nhiệt độ cao',
  [ServerRecordType.TRY_TO_UNLOCK_WITH_A_DELETED_CARD]:
    'Cố gắng mở khóa với thẻ đã xóa',
};

export const SERVER_RECORD_TYPE_ICONS = {
  [ServerRecordType.UNLOCK_BY_APP]: LockOpenIcon,
  [ServerRecordType.UNLOCK_BY_PASSCODE]: VpnKeyIcon,
  [ServerRecordType.UNLOCK_BY_IC_CARD]: CreditCardIcon,
  [ServerRecordType.UNLOCK_BY_FINGERPRINT]: FingerprintIcon,
  [ServerRecordType.UNLOCK_BY_MERCHANICAL_KEY]: LockIcon,
  [ServerRecordType.LOCK_BY_APP]: LockIcon,
  [ServerRecordType.UNLOCK_BY_GATEWAY]: SettingsInputHdmiIcon,
  [ServerRecordType.OPEN_FROM_INSIDE]: ArrowForwardIcon,
  [ServerRecordType.LOCK_BY_FINGERPRINT]: FingerprintIcon,
  [ServerRecordType.LOCK_BY_PASSCODE]: VpnKeyIcon,
  [ServerRecordType.LOCK_BY_IC_CARD]: CreditCardIcon,
  [ServerRecordType.LOCK_BY_MERCHANICAL_KEY]: LockIcon,
  [ServerRecordType.REMOTE_CONTROL]: DeviceHubIcon,
  [ServerRecordType.AUTO_LOCK]: QueryBuilderIcon,
  [ServerRecordType.UNLOCK_BY_UNLOCK_KEY]: VpnKeyIcon,
  [ServerRecordType.LOCK_BY_LOCK_KEY]: LockIcon,
  [ServerRecordType.SYSTEM_LOCK]: LockOutlinedIcon,
  [ServerRecordType.UNLOCK_BY_HOTEL_CARD]: CreditCardOutlinedIcon,
  [ServerRecordType.UNLOCKED_DUE_TO_HIGH_TEMPERATURE]: WhatshotIcon,
  [ServerRecordType.TRY_TO_UNLOCK_WITH_A_DELETED_CARD]: DeleteOutlineIcon,
};

export const SERVER_RECORD_TYPE_PASSCODES = {
  [ServerKeyboardPwdType.PERIOD]: 'Mật khẩu theo thời gian',
  [ServerKeyboardPwdType.PERMANENT]: 'Mật khẩu vĩnh viễn',
};
