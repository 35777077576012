import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRegisterSW } from 'virtual:pwa-register/react';

interface ServiceWorkerContextValues {
  registration: ServiceWorkerRegistration | null;
}

const ServiceWorkerContext = createContext<ServiceWorkerContextValues>({
  registration: null,
});

export const useServiceWorkerContext = () => useContext(ServiceWorkerContext);

// replaced dynamically
// const buildDate = '__DATE__' as string;
// replaced dyanmicaly
const reloadSW = '__RELOAD_SW__' as string;

function ServiceWorkerContextProvider({ children }: { children: ReactNode }) {
  const [registration, setRegistration] =
    useState<ServiceWorkerRegistration | null>(null);

  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(_, r) {
      setRegistration(r || null);
    },
    onRegisterError(error) {
      // eslint-disable-next-line no-console
      console.error('SW registration error', error);
    },
  });

  const handleClose = useCallback(() => {
    setNeedRefresh(false);
  }, [setNeedRefresh]);

  useEffect(() => {
    if (reloadSW === 'true' && registration) {
      registration.update();
    }
  }, []);

  const memoizedContextValues = useMemo(
    () => ({
      registration,
    }),
    [registration]
  );

  return (
    <ServiceWorkerContext.Provider value={memoizedContextValues}>
      {children}
      {needRefresh && (
        <Dialog open onClose={handleClose}>
          <DialogTitle>Cập nhật phiên bản mới</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Phiên bản Bookdee mới vừa được phát hành, bấm <b>Cập nhật</b> ngay
              để trải nghiệm tính năng mới nhất
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Đóng</Button>
            <Button onClick={() => updateServiceWorker(true)} autoFocus>
              Cập nhật
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </ServiceWorkerContext.Provider>
  );
}

export default ServiceWorkerContextProvider;
