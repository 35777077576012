import { QueryClient } from '@tanstack/react-query';
import { queryClientAtom } from 'jotai-tanstack-query';
import { useHydrateAtoms } from 'jotai/react/utils';
import React from 'react';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      suspense: false,
      retry: 2,
      retryDelay: (attemptIndex) => 1000 * 2 ** attemptIndex,
    },
  },
});

export const HydrateAtoms = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  useHydrateAtoms([[queryClientAtom, queryClient]]);
  return children;
};
